import { BarChart, Bars, PieChart, YAxis } from "@rsuite/charts";
import dayjs from "dayjs";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { Panel, Popover, Whisper } from "rsuite";
import { Divider } from "../../../components";
import {
  getSideNavExpandedSelector,
  getThemeSelector,
} from "../../../redux/app";
import { getStringSelector } from "../../../redux/locale";
import { MonthType } from "../../../types";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_WIDTH_EXPANDED,
  displayCurrency,
} from "../../../utils";
import CardField from "./Card/Field";

interface Props {
  data: MonthType[];
}
const Total: FC<Props> = ({ data }) => {
  const strings = useSelector(getStringSelector);
  const theme = useSelector(getThemeSelector);
  const expended = useSelector(getSideNavExpandedSelector);
  const bar_chart: [category: string, ...values: number[]][] = useMemo(
    () =>
      data.map((d, i) => [
        dayjs().set("month", i).format("MMMM"),
        parseFloat(d.income.toFixed(2)),
        parseFloat(d.expense_like.toFixed(2)),
        parseFloat(d.tax.toFixed(2)),
        parseFloat(
          (
            d.income -
            (d.tax + d.expense_like) +
            (d.expense - d.expense_wo_vat - (d.income - d.income_wo_vat))
          ).toFixed(2)
        ),
      ]),
    [data]
  );
  const total = useMemo(() => {
    let income = 0,
      income_vat = 0,
      expense = 0,
      expense_vat = 0,
      tax = 0,
      tax_basis = 0,
      net = 0;
    data.forEach((d) => {
      income += d.income;
      income_vat += d.income - d.income_wo_vat;
      expense += d.expense_like;
      expense_vat += d.expense - d.expense_wo_vat;
      tax += d.tax;
      tax_basis += d.tax_basis;
    });
    net = income - (tax + expense);
    net += expense_vat - income_vat;
    return { income, expense, tax, income_vat, expense_vat, tax_basis, net };
  }, [data]);

  const pie_chart: any = useMemo(() => {
    if (total.income === 0 && total.expense === 0)
      return [
        [strings.getString("total_earning"), 0],
        [strings.getString("total_expense"), 0],
        [strings.getString("total_tax"), 0],
      ];
    const ratio = 100 / total.income;
    const t = parseFloat((total.tax * ratio).toFixed(1));
    const n = parseFloat((total.net * ratio).toFixed(1));
    const e = (100 - (n + t)).toFixed(1);
    return [
      [strings.getString("total_earning"), n],
      [strings.getString("total_expense"), e],
      [strings.getString("total_tax"), t],
    ];
  }, [total, strings]);

  const avg = useMemo(() => {
    let income = 0,
      income_vat = 0,
      expense = 0,
      expense_vat = 0,
      tax = 0,
      net = 0,
      total_month = 0;
    data.forEach((d) => {
      if (d.income > 0 || d.expense > 0 || d.tax > 0) {
        total_month++;
        income += d.income;
        expense += d.expense_like;
        income_vat += d.income - d.income_wo_vat;
        expense_vat += d.expense - d.expense_wo_vat;
        tax += d.tax;
      }
    });
    net = income - (tax + expense);
    net += expense_vat - income_vat;
    income /= total_month;
    expense /= total_month;
    tax /= total_month;
    net /= total_month;
    return { income, expense, tax, net };
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignSelf: "stretch",
        overflowY: "scroll",
        maxHeight: "88vh",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Panel
            bordered
            className="panel-card-container"
            style={{ flexBasis: "80%" }}
            header={
              <span className="panel-header-title">
                {strings.getString("avg_title")}
              </span>
            }
          >
            <Divider />
            <CardField
              title={strings.getString("avg_income")}
              value={displayCurrency(avg.income)}
            />
            <CardField
              title={strings.getString("avg_expense")}
              value={displayCurrency(avg.expense)}
            />
            <CardField
              title={strings.getString("avg_tax")}
              value={displayCurrency(avg.tax)}
            />
            <CardField
              title={strings.getString("avg_earning")}
              value={displayCurrency(avg.net)}
            />
          </Panel>
        </div>
        <div style={{ display: "flex", flex: 1 }}>
          <PieChart
            data={pie_chart}
            label={{
              color: theme === "dark" ? "white" : "black",
              fontWeight: "bold",
            }}
            emphasis={{ scaleSize: 12 }}
            style={{ minWidth: 200, width: "100%" }}
            legend={false}
          />
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Panel
            bordered
            header={
              <span className="panel-header-title">
                {strings.getString("total_title")}
              </span>
            }
            className="panel-card-container"
            style={{ flexBasis: "80%" }}
          >
            <Divider />
            <CardField
              title={strings.getString("total_income")}
              value={displayCurrency(total.income)}
            />
            <CardField
              title={strings.getString("total_expense")}
              value={displayCurrency(total.expense)}
            />
            <CardField
              title={strings.getString(
                total.expense_vat - total.income_vat > 0
                  ? "total_vat_receive"
                  : "total_vat_pay"
              )}
              direction={
                total.expense_vat - total.income_vat > 0 ? "up" : "down"
              }
              value={displayCurrency(
                Math.abs(total.expense_vat - total.income_vat)
              )}
            />
            <Whisper
              placement="auto"
              speaker={
                <Popover title={strings.getString("total_tax_basis")}>
                  <p>{displayCurrency(total.tax_basis)}</p>
                </Popover>
              }
            >
              <div>
                <CardField
                  title={strings.getString("total_tax")}
                  value={displayCurrency(total.tax)}
                />
              </div>
            </Whisper>
            <CardField
              title={strings.getString("total_earning")}
              value={displayCurrency(total.net)}
            />
          </Panel>
        </div>
      </div>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          flex: 1,
          width: `calc(90vw - ${
            expended ? SIDE_NAV_WIDTH_EXPANDED : SIDE_NAV_WIDTH * 1.5
          }px)`,
          alignSelf: "center",
        }}
      >
        <BarChart style={{ flexGrow: 1 }} data={bar_chart}>
          <YAxis
            axisLabel={(value) => `₺${parseInt(String(value)) / 1000}k`}
            splitLine={false}
          />
          <Bars name={strings.getString("income")} barMinWidth={10} />
          <Bars name={strings.getString("expense")} barMinWidth={10} />
          <Bars name={strings.getString("tax")} barMinWidth={10} />
          <Bars name={strings.getString("earning")} barMinWidth={10} />
        </BarChart>
      </div>
    </div>
  );
};

export default Total;
