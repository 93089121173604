/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import LocalizedStrings from "react-localization";
import { createSelector } from "reselect";
import { RootState } from "../../types";

const getLocaleState = (state: RootState) => state.locale;

export const getStringSelector = createSelector(
  [getLocaleState],
  (locale) => new LocalizedStrings(locale)
);
