import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timeZone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toaster } from "rsuite";
import { Notify } from "../components";
import { configure, logout } from "../redux/actions";
import { getStringSelector } from "../redux/locale";
import { getAuthStatusSelector } from "../redux/user";
import { Auth } from "../screens";
import Panel from "../screens/panel";

dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(timeZone);
dayjs.extend(localeData);
dayjs.extend(utc);

function Navigator() {
  const isLogged = useSelector(getAuthStatusSelector);
  const strings = useSelector(getStringSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(configure());
    axios.interceptors.response.use(
      async function (response) {
        return response;
      },
      async function (error) {
        if (error.request.status === 401) {
          dispatch(logout());
          toaster.push(
            <Notify header={strings.getString("unauthorized")} type={"error"} />
          );
        }
        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{isLogged ? <Panel /> : <Auth />}</>;
}

export default Navigator;
