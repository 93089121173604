import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Panel } from "rsuite";
import { Button, Divider, InputNumber } from "../../../components";
import { getStringSelector } from "../../../redux/locale";
import { TaxBracketObject } from "../../../redux/types";
import { getSettingsSelector } from "../../../redux/user";
import { MonthType } from "../../../types";
import { displayCurrency, getTaxCalculationResult } from "../../../utils";
import { initialMonth } from "../Home";
import Card from "../Home/Card";
import CardField from "../Home/Card/Field";

interface Props {
  brackets?: TaxBracketObject[];
}
const MonthByMonth: FC<Props> = ({ brackets }) => {
  const strings = useSelector(getStringSelector);
  const settings = useSelector(getSettingsSelector);

  const [months, setMonths] = useState<MonthType[]>(
    Array.from({ length: 12 }, () => JSON.parse(JSON.stringify(initialMonth)))
  );
  const [copy, setCopy] = useState<MonthType | undefined>(undefined);
  const [tax_percent, setTaxPercent] = useState<string>("0");

  const new_avg = useMemo(() => {
    let income = 0,
      expense = 0,
      tax = 0,
      total_month = 0;
    months.forEach((d) => {
      if (d.income > 0 || d.expense > 0 || d.tax > 0) {
        total_month++;
        income += d.income;
        expense += d.expense;
        tax += d.tax;
      }
    });
    income /= total_month;
    expense /= total_month;
    tax /= total_month;
    return {
      income: Math.round(income),
      expense: Math.round(expense),
      tax: Math.round(tax),
    };
  }, [months]);

  const clearMonths = () => {
    setMonths(
      Array.from({ length: 12 }, () => JSON.parse(JSON.stringify(initialMonth)))
    );
    setCopy(undefined);
  };

  const calculateTax = (d: MonthType[], percent?: string): MonthType[] => {
    let nd: MonthType[] = JSON.parse(JSON.stringify(d));
    let komulatif_gelir = 0;
    d.forEach((it, iti) => {
      const { dahil_olunan_vergi_dilimleri, komulatif_gelir_matrahi, vergi } =
        getTaxCalculationResult(
          komulatif_gelir,
          brackets || [],
          it.income,
          it.expense,
          1 - parseInt(percent || tax_percent || "0") * 0.01
        );
      komulatif_gelir = komulatif_gelir_matrahi;
      nd[iti].tax_brackets = dahil_olunan_vergi_dilimleri;
      nd[iti].tax = vergi;
    });
    return nd;
  };

  const setMonthIncome = (index: number) => (value: string) => {
    let ms: MonthType[] = JSON.parse(JSON.stringify(months));
    if (ms?.[index]) {
      ms[index].income = parseFloat(value);
      setMonths(calculateTax(ms));
    }
  };
  const setMonthExpense = (index: number) => (value: string) => {
    let ms: MonthType[] = JSON.parse(JSON.stringify(months));
    if (ms?.[index]) {
      ms[index].expense = parseFloat(value);
      setMonths(calculateTax(ms));
    }
  };
  const onCopyPress = (index: number) => () => {
    if (months?.[index]) {
      setCopy(months?.[index]);
    }
  };
  const onClearPress = (index: number) => () => {
    let ms: MonthType[] = JSON.parse(JSON.stringify(months));
    if (ms?.[index]) {
      ms[index].income = 0;
      ms[index].expense = 0;
      setMonths(calculateTax(ms));
    }
  };
  const pasteAll = () => {
    let ms: MonthType[] = JSON.parse(JSON.stringify(months));
    if (copy) {
      for (let i = 0; i < ms.length; i++) {
        ms[i].income = copy.income;
        ms[i].expense = copy.expense;
      }
      setMonths(calculateTax(ms));
    }
  };
  const onPastePress = (index: number) => () => {
    let ms: MonthType[] = JSON.parse(JSON.stringify(months));
    if (ms?.[index] && copy) {
      ms[index].income = copy.income;
      ms[index].expense = copy.expense;
      setMonths(calculateTax(ms));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowY: "scroll",
        maxHeight: "88vh",
        paddingTop: "2rem",
        paddingBottom: "2rem",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {settings.tax_discount && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                marginRight: 10,
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              {strings.getString("tax_discount")}
            </span>
            <InputNumber
              size="lg"
              style={{ width: 70 }}
              onChange={(e) => {
                setMonths(calculateTax(months, String(e)));
                setTaxPercent(String(e));
              }}
              value={tax_percent}
              defaultValue={0}
              min={0}
              max={100}
            />
          </div>
        )}
        {copy && (
          <Button
            style={{ marginLeft: settings.tax_discount ? 20 : 0 }}
            onClick={pasteAll}
          >
            {strings.getString("paste_to_all")}
          </Button>
        )}
        <Button
          style={{ marginLeft: 20 }}
          onClick={clearMonths}
          appearance="ghost"
        >
          {strings.getString("clear_months")}
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {months.map((d, i) => (
          <Card
            key={`card-${i}`}
            item={d}
            index={i}
            onIncomeChange={setMonthIncome(i)}
            onExpenseChange={setMonthExpense(i)}
            onCopyPress={
              d.income > 0 || d.expense > 0 ? onCopyPress(i) : undefined
            }
            onClearPress={
              d.income > 0 || d.expense > 0 ? onClearPress(i) : undefined
            }
            onPastePress={copy ? onPastePress(i) : undefined}
          />
        ))}
      </div>
      <div style={{ alignSelf: "center" }}>
        <Panel
          bordered
          className="panel-card-container"
          header={
            <span className="panel-header-title">
              {strings.getString("avg_title")}
            </span>
          }
          style={{ minWidth: "30vw" }}
        >
          <Divider />
          <CardField
            title={strings.getString("avg_income")}
            value={displayCurrency(new_avg.income)}
          />
          <CardField
            title={strings.getString("avg_expense")}
            value={displayCurrency(new_avg.expense)}
          />
          <CardField
            title={strings.getString("avg_tax")}
            value={displayCurrency(new_avg.tax)}
          />
          <CardField
            title={strings.getString("avg_earning")}
            value={displayCurrency(
              new_avg.income - (new_avg.tax + new_avg.expense)
            )}
          />
        </Panel>
      </div>
    </div>
  );
};

export default MonthByMonth;
