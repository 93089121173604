import BlockRoundIcon from "@rsuite/icons/BlockRound";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import CopyIcon from "@rsuite/icons/Copy";
import EditIcon from "@rsuite/icons/Edit";
import DocIcon from "@rsuite/icons/FolderFill";
import PageIcon from "@rsuite/icons/Page";
import PlusIcon from "@rsuite/icons/Plus";
import SearchIcon from "@rsuite/icons/Search";
import TimeRoundIcon from "@rsuite/icons/TimeRound";
import VisibleIcon from "@rsuite/icons/Visible";
import dayjs from "dayjs";
import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CellProps,
  Divider,
  InputGroup,
  Pagination,
  Popover,
  Whisper,
  toaster,
} from "rsuite";
import {
  IconButton,
  Input,
  Notify,
  ScreenWrapper,
  SelectPicker,
  Table,
  View,
} from "../../../components";
import {
  getSelectedYearSelector,
  getSideNavExpandedSelector,
} from "../../../redux/app";
import { getStringSelector } from "../../../redux/locale";
import { getExpenses } from "../../../redux/transactions";
import {
  ExpenseObject,
  ExpenseStatusType,
  ExpenseType,
} from "../../../redux/transactions/types/objects";
import { GetExpensesP, SetExpenseP } from "../../../redux/types";
import { PaginationResponse } from "../../../types";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_WIDTH_EXPANDED,
  TABLE_LIMIT_OPTIONS,
  TABLE_LIMIT_OPTIONS_DEFAULT_INDEX,
  displayCurrency,
  getExpenseTypeList,
  getExpenseVatVisibility,
  useDimensions,
} from "../../../utils";
import Documents from "../Incomes/Documents";
import Add from "./Add";
const { Column, Cell, HeaderCell } = Table;

function intOrUndefined(page?: string): number | undefined {
  if (!page) return undefined;
  return parseInt(page);
}
function Expenses() {
  const strings = useSelector(getStringSelector);
  const selected_year = useSelector(getSelectedYearSelector);
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  const is_expanded = useSelector(getSideNavExpandedSelector);
  const { isDesktop, width } = useDimensions();
  const expanded = useMemo(
    () => is_expanded && isDesktop,
    [is_expanded, isDesktop]
  );
  const tableWidth = useMemo(
    () => (expanded ? width - SIDE_NAV_WIDTH_EXPANDED : width - SIDE_NAV_WIDTH),
    [width, expanded]
  );
  const [modalData, setModalData] = useState<SetExpenseP | undefined | Object>(
    undefined
  );
  const [modalDoc, setModalDoc] = useState<number | undefined>(undefined);
  const [list_data, setListData] =
    useState<PaginationResponse<ExpenseObject>>();
  const [selected_type, setSelectedType] = useState<ExpenseType>();
  const [selected_month, setSelectedMonth] = useState<number>(
    dayjs().get("month") + 1
  );
  const months = useMemo(
    () => dayjs.months().map((m, i) => ({ label: m, id: i + 1 })),
    []
  );
  const type_list = useMemo(() => getExpenseTypeList(strings), [strings]);

  const total_amount = useMemo(
    () =>
      list_data?.data
        ?.map((d) => d?.amount ?? "0")
        ?.reduce((prev, val) => prev + parseFloat(val), 0) ?? 0,
    [list_data?.data]
  );

  const handleChangeLimit = (new_limit: number) => {
    getTableData(1, new_limit);
  };
  const handleChangePage = (new_page: number) => {
    getTableData(new_page, intOrUndefined(list_data?.per_page));
  };
  const onInputChange = useCallback((e: string) => setSearch(e), []);
  const onSearchClick = () => {
    getTableData(1, intOrUndefined(list_data?.per_page), search);
  };
  const onSearchClearClick = () => {
    setSearch("");
    getTableData(1, intOrUndefined(list_data?.per_page));
  };
  const onInputKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e?.nativeEvent.key === "Enter") {
        onSearchClick();
      }
    },
    [search]
  );
  const onModalClose = (refresh?: boolean) => {
    setModalData(undefined);
    if (refresh) getTableData();
  };
  const getTableData = (page?: number, limit?: number, search?: string) => {
    let params: GetExpensesP = {
      page: page || list_data?.current_page || 1,
      per_page: limit,
    };
    if (!params.per_page)
      params.per_page = list_data?.per_page
        ? parseInt(list_data!.per_page)
        : TABLE_LIMIT_OPTIONS[TABLE_LIMIT_OPTIONS_DEFAULT_INDEX];
    if (search) params.search = search;
    if (selected_type) params.type = selected_type;
    if (selected_month) params.month = selected_month;
    if (selected_year) params.year = selected_year;
    dispatch(
      getExpenses({
        params,
        onSuccess: (data) => {
          setListData(data);
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_year, selected_month, selected_type]);

  const ActionCell = ({ rowData, dataKey, ...props }: CellProps) => {
    const onViewClick = (id?: number) => {
      setModalData({
        id,
        name: rowData["name"],
        amount: rowData["amount"],
        vat_percent: rowData["vat_percent"],
        amount_over_vat: rowData["amount_over_vat"],
        type: rowData["type"],
        due_date: rowData["due_date"],
        payed_at: rowData["payed_at"],
        notes: rowData["notes"],
        status: id ? rowData["status"] : "pending",
      });
    };
    if (!dataKey) return null;
    const speaker = (
      <Popover
        title={strings.getString("expense_notes")}
        style={{ maxWidth: 500 }}
      >
        <p>{rowData.notes}</p>
      </Popover>
    );
    return (
      <Cell {...props} className="link-group">
        {rowData.notes ? (
          <Whisper placement="topEnd" speaker={speaker}>
            <PageIcon style={{ marginRight: 10 }} />
          </Whisper>
        ) : (
          <PageIcon style={{ marginRight: 10, opacity: 0 }} />
        )}
        <IconButton
          icon={
            <Whisper
              placement="autoVertical"
              speaker={<Popover>{strings.getString("expense_copy")}</Popover>}
            >
              <CopyIcon />
            </Whisper>
          }
          style={{ marginRight: 10 }}
          onClick={() => onViewClick()}
        />
        <IconButton
          icon={
            <Whisper
              placement="autoVertical"
              speaker={
                <Popover>
                  {strings.getString(
                    rowData["status"] === "pending"
                      ? "expense_edit"
                      : "expense_view"
                  )}
                </Popover>
              }
            >
              {rowData["status"] === "pending" ? <EditIcon /> : <VisibleIcon />}
            </Whisper>
          }
          onClick={() => onViewClick(rowData[dataKey])}
        />
      </Cell>
    );
  };

  const DocumentCell = ({ rowData, dataKey, ...props }: CellProps) => {
    const onViewClick = (id: number) => {
      setModalDoc(id);
    };
    if (!dataKey) return null;
    return (
      <Cell {...props} className="link-group">
        <IconButton
          icon={<DocIcon />}
          onClick={() => onViewClick(rowData[dataKey])}
        >
          <span style={{ fontSize: "1.2rem" }}>
            {rowData["documents_count"]}
          </span>
        </IconButton>
      </Cell>
    );
  };

  const getExpenseTypeKey = (t: ExpenseType): string => {
    switch (t) {
      case "accounting":
        return "expense_type_accounting";
      case "tax":
        return "expense_type_tax";
      case "income-tax":
        return "expense_type_income_tax";
      case "external":
        return "expense_type_external";
      case "non-allowable":
        return "expense_type_non_allowable";
      case "social-security-institution":
        return "expense_type_social_security_institution";
      default:
        return "expense_type_general";
    }
  };

  const onDocumentsClose = (refresh: boolean) => {
    if (refresh) getTableData();
    setModalDoc(undefined);
  };

  const getIconFromStatus = (status: ExpenseStatusType) => {
    switch (status) {
      case "pending":
        return <TimeRoundIcon />;
      case "confirmed":
        return <CheckRoundIcon color="green" />;
      case "rejected":
        return <BlockRoundIcon color="red" />;

      default:
        return null;
    }
  };
  const getDescriptionFromStatus = (status: ExpenseStatusType) => {
    switch (status) {
      case "pending":
        return strings.getString("expense_pending_description");
      case "confirmed":
        return strings.getString("expense_confirmed_description");
      case "rejected":
        return strings.getString("expense_rejected_description");

      default:
        return "";
    }
  };
  return (
    <ScreenWrapper style={{ justifyContent: "flex-start" }}>
      <View
        row
        style={{
          justifyContent: "space-between",
          alignSelf: "stretch",
          marginRight: 10,
          marginLeft: 10,
          marginBottom: 10,
        }}
      >
        <IconButton
          icon={<PlusIcon />}
          onClick={() => setModalData({})}
          size="lg"
        >
          {strings.getString("expense_add")}
        </IconButton>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <InputGroup
            size="lg"
            inside
            style={{ width: width * 0.2, minWidth: 250, marginRight: 10 }}
          >
            <InputGroup.Button onClick={onSearchClick}>
              <SearchIcon />
            </InputGroup.Button>
            <Input
              value={search}
              style={{ paddingLeft: 50 }}
              onChange={onInputChange}
              placeholder={strings.getString("expense_search_placeholder")}
              onKeyDown={onInputKeyDown}
            />
            {search.length > 0 && (
              <InputGroup.Button onClick={onSearchClearClick}>
                <CloseOutlineIcon />
              </InputGroup.Button>
            )}
          </InputGroup>
          <SelectPicker
            size="lg"
            placement="bottomEnd"
            cleanable={true}
            value={selected_type}
            onChange={setSelectedType}
            data={type_list}
          />
        </div>
        <SelectPicker
          size="lg"
          placement="bottomEnd"
          cleanable={true}
          value={selected_month}
          labelKey="label"
          valueKey="id"
          onChange={setSelectedMonth}
          data={months}
        />
      </View>
      <div className="table-wrapper">
        <Table
          fillHeight
          data={list_data?.data || []}
          width={tableWidth}
          rowHeight={54}
        >
          <Column width={50} align="center" verticalAlign="middle" fixed>
            <HeaderCell>#</HeaderCell>
            <Cell dataKey="id" style={{ fontSize: 18 }}>
              {(d, i) => `#${i != undefined ? i + (list_data?.from ?? 1) : ""}`}
            </Cell>
          </Column>
          <Column width={50} align="center" verticalAlign="middle" fixed>
            <HeaderCell> </HeaderCell>
            <Cell dataKey="status" style={{ fontSize: 18 }}>
              {(d) =>
                d["type"] === "non-allowable" || d["type"] === "income-tax" ? (
                  <span></span>
                ) : (
                  <Whisper
                    placement="autoVerticalStart"
                    speaker={
                      <Popover>
                        <p>{getDescriptionFromStatus(d["status"])}</p>
                      </Popover>
                    }
                  >
                    <span>{getIconFromStatus(d["status"])}</span>
                  </Whisper>
                )
              }
            </Cell>
          </Column>
          <Column flexGrow={1} verticalAlign={"middle"}>
            <HeaderCell>{strings.getString("expense_name")}</HeaderCell>
            <Cell dataKey="name" />
          </Column>
          <Column width={100} verticalAlign={"middle"}>
            <HeaderCell>
              <Whisper
                placement="autoVertical"
                disabled={total_amount === 0}
                speaker={
                  <Popover>
                    <p>{displayCurrency(total_amount)}</p>
                  </Popover>
                }
              >
                <span>{`${strings.getString("expense_amount")}`}</span>
              </Whisper>
            </HeaderCell>
            <Cell dataKey="amount">
              {(d) => (
                <Whisper
                  placement="autoVertical"
                  disabled={d["vat_percent"] === 0}
                  speaker={
                    <Popover title={strings.getString("expense_with_out_vat")}>
                      <p>
                        {displayCurrency(
                          (d["amount"] - d["amount_over_vat"]) /
                            (1 + d["vat_percent"] / 100) +
                            d["amount_over_vat"]
                        )}
                      </p>
                    </Popover>
                  }
                >
                  <span>{`${displayCurrency(d["amount"])}`}</span>
                </Whisper>
              )}
            </Cell>
          </Column>
          <Column width={100} verticalAlign={"middle"} align="center">
            <HeaderCell>{strings.getString("expense_vat_percent")}</HeaderCell>
            <Cell dataKey="vat_percent">
              {(d) => (
                <Whisper
                  placement="autoVertical"
                  disabled={d["vat_percent"] === 0}
                  speaker={
                    <Popover title={strings.getString("expense_vat_amount")}>
                      <p>
                        {displayCurrency(
                          d["amount"] -
                            d["amount_over_vat"] -
                            (d["amount"] - d["amount_over_vat"]) /
                              (1 + d["vat_percent"] / 100)
                        )}
                      </p>
                    </Popover>
                  }
                >
                  <span>
                    {getExpenseVatVisibility(d["type"])
                      ? `${d["vat_percent"]}%`
                      : "-"}
                  </span>
                </Whisper>
              )}
            </Cell>
          </Column>
          <Column width={150} verticalAlign={"middle"} align="center">
            <HeaderCell>{strings.getString("expense_type")}</HeaderCell>
            <Cell dataKey="type">
              {(d) => strings.getString(getExpenseTypeKey(d["type"]))}
            </Cell>
          </Column>
          <Column width={100} verticalAlign={"middle"} align="center">
            <HeaderCell>{strings.getString("expense_due_date")}</HeaderCell>
            <Cell dataKey="due_date">
              {(d) => dayjs(d["due_date"]).format("L")}
            </Cell>
          </Column>
          <Column width={100} verticalAlign={"middle"} align="center">
            <HeaderCell>{strings.getString("expense_payed_date")}</HeaderCell>
            <Cell dataKey="payed_at">
              {(d) => dayjs(d["payed_at"]).format("L")}
            </Cell>
          </Column>
          <Column width={120} verticalAlign={"middle"}>
            <HeaderCell> </HeaderCell>
            <ActionCell dataKey="id" />
          </Column>
          <Column width={100} verticalAlign={"middle"}>
            <HeaderCell> </HeaderCell>
            <DocumentCell dataKey="id" />
          </Column>
        </Table>
        <div>
          <Divider className="table-pagination-divider" />
          <Pagination
            className="table-pagination"
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="sm"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={list_data?.total || 0}
            limitOptions={TABLE_LIMIT_OPTIONS}
            limit={
              intOrUndefined(list_data?.per_page) ??
              TABLE_LIMIT_OPTIONS[TABLE_LIMIT_OPTIONS_DEFAULT_INDEX]
            }
            activePage={list_data?.current_page || 0}
            onChangePage={handleChangePage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
      <Add
        visible={modalData !== undefined}
        data={modalData}
        close={onModalClose}
      />
      <Documents
        visible={modalDoc !== undefined}
        id={modalDoc}
        type="expense"
        close={onDocumentsClose}
      />
    </ScreenWrapper>
  );
}
export default Expenses;
