import {
  CollaspedOutline,
  Dashboard,
  DocPass,
  ExpandOutline,
  FolderFill,
  MinusRound,
  Numbers,
  OneColumn,
  PieChart,
  PlusRound,
  Setting,
} from "@rsuite/icons";
import { IconNames } from "../types";

export const getIconByName = (name: IconNames) => {
  switch (name) {
    case "home":
      return <Dashboard />;
    case "calculate":
      return <Numbers />;
    case "incomes":
      return <ExpandOutline />;
    case "expenses":
      return <CollaspedOutline />;
    case "brackets":
      return <PieChart />;
    case "ledger":
      return <FolderFill />;
    case "ledger-incomes":
      return <PlusRound />;
    case "ledger-expenses":
      return <MinusRound />;
    case "gib":
      return <DocPass />;
    case "settings":
      return <Setting />;
    default:
      return <OneColumn />;
  }
};
