import DocPassIcon from "@rsuite/icons/DocPass";
import RemindIcon from "@rsuite/icons/RemindFill";
import { Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Panel, toaster } from "rsuite";
import * as yup from "yup";
import { Button, Divider, Form, Notify } from "../../../../components";
import {
  deleteGib,
  deleteTemplate,
  getGib,
  getTemplates,
  setGib,
} from "../../../../redux/actions";
import { getThemeSelector } from "../../../../redux/app";
import { getStringSelector } from "../../../../redux/locale";
import { GibTemplateObject } from "../../../../redux/types";
import { opacityPercentage } from "../../../../utils";
import Edit from "./Edit";

const initialValues = {
  username: "",
  password: "",
};
function Gib() {
  const strings = useSelector(getStringSelector);
  const theme = useSelector(getThemeSelector);
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState<GibTemplateObject[]>([]);
  const [delete_open, setDeleteOpen] = useState<number>(0);
  const [edit_open, setEditOpen] = useState<GibTemplateObject>();
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const SCHEMA = yup.object().shape({
    username: yup.string().required(strings.getString("hint_required")),
    password: yup.string().required(strings.getString("hint_required")),
  });
  useEffect(() => {
    getCredentials();
    getTemplateList();
  }, []);
  const onGibSubmit = (v: typeof initialValues) => {
    dispatch(
      setGib({
        params: v,
        onSuccess: (payload) => {
          formikRef.current?.setValues({
            username: payload?.username ?? initialValues.username,
            password: payload?.password ?? initialValues.password,
          });
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const onGibDelete = () => {
    dispatch(
      deleteGib({
        onSuccess: (message) => {
          message && toaster.push(<Notify header={message} type={"success"} />);
          formikRef.current?.setValues(initialValues);
          formikRef.current?.resetForm();
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const getCredentials = () => {
    dispatch(
      getGib({
        onSuccess(payload) {
          formikRef.current?.setValues({
            username: payload?.username ?? initialValues.username,
            password: payload?.password ?? initialValues.password,
          });
        },
        onError(message, payload) {
          //message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const getTemplateList = () => {
    dispatch(
      getTemplates({
        onSuccess(payload) {
          setTemplates(payload || []);
        },
        onError(message, payload) {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const onDeleteTemplatePress = () => {
    dispatch(
      deleteTemplate({
        params: { id: delete_open },
        onSuccess: (message) => {
          message && toaster.push(<Notify header={message} type={"success"} />);
          handleDeleteClose();
          getTemplateList();
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };

  const handleDeleteOpen = (id: number) => () => setDeleteOpen(id);
  const handleDeleteClose = () => setDeleteOpen(0);
  const handleEditOpen = (t: GibTemplateObject) => () => setEditOpen(t);
  const handleEditClose = () => {
    getTemplateList();
    setEditOpen(undefined);
  };

  return (
    <>
      <Edit data={edit_open} close={handleEditClose} />
      <Panel
        header={
          <span className="panel-header-title">
            {strings.getString("gib_title")}
          </span>
        }
        bordered
        className="settings-panel"
      >
        <Divider />
        <Formik
          key="gib"
          innerRef={formikRef}
          validationSchema={SCHEMA}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            onGibSubmit(values);
          }}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            submitForm,
            setFieldValue,
          }) => (
            <Form fluid onSubmit={(s, e) => handleSubmit(e)}>
              <Form.Group>
                <Form.ControlLabel>
                  {strings.getString("username")}
                </Form.ControlLabel>
                <Form.Control
                  name="username"
                  type="username"
                  onChange={handleChange("username")}
                  onBlur={handleBlur("username")}
                  value={values.username}
                  errorMessage={touched.username && errors.username}
                />
              </Form.Group>
              <Form.Group style={{ marginTop: 20 }}>
                <Form.ControlLabel>
                  {strings.getString("password")}
                </Form.ControlLabel>
                <Form.Control
                  name="password"
                  type="password"
                  onChange={handleChange("password")}
                  onBlur={handleBlur("password")}
                  value={values.password}
                  errorMessage={touched.password && errors.password}
                />
              </Form.Group>
              <div
                style={{
                  display: "flex",
                  alignSelf: "stretch",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Button onClick={submitForm} appearance="primary">
                  {strings.getString("gib_update")}
                </Button>
                <Button onClick={onGibDelete} appearance="ghost" color="red">
                  {strings.getString("gib_delete")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Panel>
      <Panel
        header={
          <span className="panel-header-title">
            {strings.getString("templates")}
          </span>
        }
        bordered
        className="settings-panel"
      >
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "60vh",
            overflowY: "scroll",
          }}
        >
          {templates.map((u, i) => (
            <div
              key={u.id}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 20,
                padding: 5,
                paddingLeft: 10,
                borderRadius: 5,
                backgroundColor:
                  i % 2 === 0
                    ? "transparent"
                    : (theme === "dark" ? "#FFFFFF" : "#000000") +
                      opacityPercentage.p5,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <DocPassIcon
                  width={30}
                  height={30}
                  style={{ marginTop: 5, marginRight: 10 }}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontSize: 20 }}>{u.name}</span>
                </div>
              </div>
              <div>
                <Button
                  onClick={handleEditOpen(u)}
                  style={{ marginRight: 10 }}
                  appearance="primary"
                >
                  {strings.getString("template_edit")}
                </Button>
                <Button
                  onClick={handleDeleteOpen(u.id)}
                  appearance="primary"
                  color="red"
                >
                  {strings.getString("template_delete")}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </Panel>
      <Modal
        backdrop="static"
        role="alertdialog"
        open={delete_open !== 0}
        onClose={handleDeleteClose}
        size="xs"
      >
        <Modal.Body>
          <RemindIcon
            style={{ color: "#ffb300", fontSize: 24, marginRight: 5 }}
          />
          {strings.getString("template_delete_message")}
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              alignSelf: "stretch",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={onDeleteTemplatePress}
              appearance="primary"
              color="red"
            >
              {strings.getString("template_delete")}
            </Button>
            <Button onClick={handleDeleteClose} appearance="subtle">
              {strings.getString("cancel")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Gib;
