/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { useSelector } from "react-redux";
import { Table as TableComponent, TableProps } from "rsuite";
import { getLoadingStateSelector } from "../redux/app";

interface Props extends TableProps<any, any> {}
const Table = ({ children, ...props }: Props) => {
  const loading = useSelector(getLoadingStateSelector);
  return (
    <TableComponent loading={loading} {...props}>
      {children}
    </TableComponent>
  );
};
Table.Cell = TableComponent.Cell;
Table.Column = TableComponent.Column;
Table.ColumnGroup = TableComponent.ColumnGroup;
Table.HeaderCell = TableComponent.HeaderCell;
export default Table;
