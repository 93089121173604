/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { useSelector } from "react-redux";
import { Form as FormComponent, FormProps } from "rsuite";
import { getLoadingStateSelector } from "../redux/app";
const Form = ({ disabled, ...props }: FormProps) => {
  const loading = useSelector(getLoadingStateSelector);
  return <FormComponent {...props} disabled={loading || disabled} />;
};
Form.Control = FormComponent.Control;
Form.ControlLabel = FormComponent.ControlLabel;
Form.ErrorMessage = FormComponent.ErrorMessage;
Form.Group = FormComponent.Group;
Form.HelpText = FormComponent.HelpText;
export default Form;
