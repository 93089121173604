import EditIcon from "@rsuite/icons/Edit";
import PlusIcon from "@rsuite/icons/Plus";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CellProps, Divider, Pagination, toaster } from "rsuite";
import {
  IconButton,
  Notify,
  ScreenWrapper,
  Table,
  View,
} from "../../../components";
import {
  getSelectedYearSelector,
  getSideNavExpandedSelector,
} from "../../../redux/app";
import { getStringSelector } from "../../../redux/locale";
import { getTaxBrackets } from "../../../redux/transactions";
import { TaxBracketObject } from "../../../redux/transactions/types/objects";
import { GetTaxBracketsP, SetIncomeP } from "../../../redux/types";
import { PaginationResponse } from "../../../types";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_WIDTH_EXPANDED,
  TABLE_LIMIT_OPTIONS,
  TABLE_LIMIT_OPTIONS_DEFAULT_INDEX,
  displayCurrency,
  useDimensions,
} from "../../../utils";
import Add from "./Add";
const { Column, Cell, HeaderCell } = Table;

function intOrUndefined(page?: string): number | undefined {
  if (!page) return undefined;
  return parseInt(page);
}
function Brackets() {
  const strings = useSelector(getStringSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selected_year = useSelector(getSelectedYearSelector);
  const is_expanded = useSelector(getSideNavExpandedSelector);
  const { isDesktop, width } = useDimensions();
  const expanded = useMemo(
    () => is_expanded && isDesktop,
    [is_expanded, isDesktop]
  );
  const tableWidth = useMemo(
    () => (expanded ? width - SIDE_NAV_WIDTH_EXPANDED : width - SIDE_NAV_WIDTH),
    [width, expanded]
  );
  const [modalData, setModalData] = useState<SetIncomeP | undefined | Object>(
    undefined
  );
  const [list_data, setListData] =
    useState<PaginationResponse<TaxBracketObject>>();

  const handleChangeLimit = (new_limit: number) => {
    getTableData(1, new_limit);
  };
  const handleChangePage = (new_page: number) => {
    getTableData(new_page, intOrUndefined(list_data?.per_page));
  };
  const onModalClose = (isCancelled: boolean) => {
    setModalData(undefined);
    if (!isCancelled) getTableData();
  };
  const getTableData = (page?: number, limit?: number, search?: string) => {
    if (selected_year) {
      let params: GetTaxBracketsP = {
        year: selected_year,
        page: page || list_data?.current_page || 1,
        per_page: limit,
      };
      if (!params.per_page)
        params.per_page = list_data?.per_page
          ? parseInt(list_data!.per_page)
          : TABLE_LIMIT_OPTIONS[TABLE_LIMIT_OPTIONS_DEFAULT_INDEX];
      dispatch(
        getTaxBrackets({
          params,
          onSuccess: (data) => {
            setListData(data);
          },
          onError: (message) => {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
    }
  };
  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_year]);

  const EditCell = ({ rowData, dataKey, ...props }: CellProps) => {
    const onViewClick = (id: number) => {
      setModalData({
        id,
        tax_bracket: rowData["tax_bracket"],
        tax_percent: rowData["tax_percent"],
        year: rowData["year"],
      });
    };
    if (!dataKey) return null;
    return (
      <Cell {...props} className="link-group">
        <IconButton
          icon={<EditIcon />}
          onClick={() => onViewClick(rowData[dataKey])}
        />
      </Cell>
    );
  };

  return (
    <ScreenWrapper style={{ justifyContent: "flex-start" }}>
      <View
        row
        style={{
          justifyContent: "space-between",
          alignSelf: "stretch",
          marginRight: 10,
          marginLeft: 10,
          marginBottom: 10,
        }}
      >
        <IconButton
          icon={<PlusIcon />}
          onClick={() => setModalData({})}
          size="lg"
        >
          {strings.getString("brackets_add")}
        </IconButton>
      </View>
      <div className="table-wrapper">
        <Table
          fillHeight
          data={list_data?.data || []}
          width={tableWidth}
          rowHeight={54}
        >
          <Column width={50} align="center" verticalAlign="middle" fixed>
            <HeaderCell>#</HeaderCell>
            <Cell dataKey="id" style={{ fontSize: 18 }}>
              {(d, i) => `#${i != undefined ? i + (list_data?.from ?? 1) : ""}`}
            </Cell>
          </Column>
          <Column flexGrow={1} verticalAlign={"middle"}>
            <HeaderCell>{strings.getString("brackets_year")}</HeaderCell>
            <Cell dataKey="year" />
          </Column>
          <Column flexGrow={2} verticalAlign={"middle"}>
            <HeaderCell>{strings.getString("brackets_amount")}</HeaderCell>
            <Cell dataKey="tax_bracket">
              {(d) =>
                d["tax_bracket"]
                  ? strings.formatString(
                      strings.getString("brackets_until_amount"),
                      `${displayCurrency(d["tax_bracket"])}₺`
                    )
                  : strings.getString("brackets_after_amount")
              }
            </Cell>
          </Column>
          <Column flexGrow={1} verticalAlign={"middle"}>
            <HeaderCell>{strings.getString("brackets_percent")}</HeaderCell>
            <Cell dataKey="tax_percent">{(d) => `${d["tax_percent"]}%`}</Cell>
          </Column>
          <Column verticalAlign={"middle"} align="center">
            <HeaderCell>{strings.getString("brackets_edit")}</HeaderCell>
            <EditCell dataKey="id" />
          </Column>
        </Table>
        <div>
          <Divider className="table-pagination-divider" />
          <Pagination
            className="table-pagination"
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="sm"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={list_data?.total || 0}
            limitOptions={TABLE_LIMIT_OPTIONS}
            limit={
              intOrUndefined(list_data?.per_page) ??
              TABLE_LIMIT_OPTIONS[TABLE_LIMIT_OPTIONS_DEFAULT_INDEX]
            }
            activePage={list_data?.current_page || 0}
            onChangePage={handleChangePage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
      <Add
        visible={modalData !== undefined}
        data={modalData}
        close={onModalClose}
      />
    </ScreenWrapper>
  );
}
export default Brackets;
