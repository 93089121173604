import { CheckOutline } from "@rsuite/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Panel } from "rsuite";
import { Button } from "../../../components";
import { getTemplates } from "../../../redux/actions";
import { getStringSelector } from "../../../redux/locale";
import { GibTemplateObject } from "../../../redux/types";

interface Props {
  visible: boolean;
  onSelect: (id: number) => void;
  close: () => void;
}
function Templates({ visible, onSelect, close }: Props) {
  const strings = useSelector(getStringSelector);
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState<GibTemplateObject[]>([]);
  const [selected, setSelected] = useState<number>();
  const onClose = () => {
    setSelected(undefined);
    close();
  };
  const onOpen = () => {
    dispatch(
      getTemplates({
        onSuccess(payload) {
          setTemplates(payload || []);
        },
        onError(message, payload) {
          setTemplates([]);
        },
      })
    );
  };
  return (
    <Modal open={visible} onOpen={onOpen} onClose={onClose} size="sm">
      <Modal.Header>
        <Modal.Title style={{ fontWeight: "bolder" }}>
          {strings.getString("gib_add_invoice")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Panel bordered style={{ paddingBottom: 10, width: "100%" }}>
          {templates.map((t, i) => (
            <Panel
              bordered
              key={t.id}
              onClick={() => setSelected(t.id)}
              style={{ marginBottom: 10 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h4>{t.name}</h4>
                {t.id === selected && (
                  <CheckOutline color="green" width={30} height={30} />
                )}
              </div>
            </Panel>
          ))}
        </Panel>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: "flex",
            alignSelf: "stretch",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => {
              onSelect(0);
              onClose();
            }}
            appearance="ghost"
          >
            {strings.getString("gib_income_create")}
          </Button>
          <Button
            color={selected ? undefined : "orange"}
            onClick={() => {
              if (selected) {
                onSelect(selected);
                onClose();
              }
            }}
            appearance="primary"
          >
            {strings.getString("gib_income_import_template")}
          </Button>
          <Button onClick={onClose} appearance="subtle">
            {strings.getString("cancel")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default Templates;
