/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  row?: boolean;
}
const View = ({ children, row = false, style, ...props }: Props) => (
  <div
    style={{ display: "flex", flexDirection: row ? "row" : "column", ...style }}
    {...props}
  >
    {children}
  </div>
);

export default View;
