/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { Divider as DividerComponent, DividerProps } from "rsuite";
const Divider = ({ style = {}, ...props }: DividerProps) => {
  return (
    <DividerComponent
      {...props}
      style={{ marginTop: 0, marginBottom: 10, ...style }}
    />
  );
};

export default Divider;
