import tr from "date-fns/locale/tr";

const Calendar = {
  sunday: "Paz",
  monday: "Pzt",
  tuesday: "Sal",
  wednesday: "Çar",
  thursday: "Per",
  friday: "Cum",
  saturday: "Cmt",
  ok: "Tamam",
  today: "Bugün",
  yesterday: "Dün",
  hours: "Saat",
  minutes: "Dakika",
  seconds: "Saniye",
  /**
   * Format of the string is based on Unicode Technical Standard #35:
   * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
   **/
  formattedMonthPattern: "MMM yyyy",
  formattedDayPattern: "dd MMM yyyy",
  dateLocale: tr,
};

export const localeTR = {
  common: {
    loading: "Yükleniyor...",
    emptyMessage: "Veri bulunamadı",
  },
  Plaintext: {
    unfilled: "Doldurulmamış",
    notSelected: "Seçilmemiş",
    notUploaded: "Yüklenmemiş",
  },
  Pagination: {
    more: "Daha fazla",
    prev: "Önceki",
    next: "Sonraki",
    first: "İlk",
    last: "Son",
    limit: "{0} / sayfa",
    total: "Toplam Satır: {0}",
    skip: "{0} git",
  },
  Calendar,
  DatePicker: {
    ...Calendar,
  },
  DateRangePicker: {
    ...Calendar,
    last7Days: "Son 7 Gün",
  },
  Picker: {
    noResultsText: "Sonuç bulunamadı",
    placeholder: "Seç",
    searchPlaceholder: "Ara",
    checkAll: "Hepsi",
  },
  InputPicker: {
    newItem: "Yeni seçim",
    createOption: 'Seçenek yarat "{0}"',
  },
  Uploader: {
    inited: "Başlangıç",
    progress: "Yükleniyor",
    error: "Hata",
    complete: "Tamamlandı",
    emptyFile: "Boş",
    upload: "Yükle",
  },
  CloseButton: {
    closeLabel: "Kapat",
  },
  Breadcrumb: {
    expandText: "Yolu göster",
  },
  Toggle: {
    on: "Aç",
    off: "Kapat",
  },
};
