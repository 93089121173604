/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { takeLatest } from "redux-saga/effects";
import * as A from "../actions";
import * as S from "./sagas";

export function* getHomeWatcher() {
  yield takeLatest(A.getHome.toString(), S.getHomeSaga);
}
export function* getLedgerWatcher() {
  yield takeLatest(A.getLedger.toString(), S.getLedgerSaga);
}
export function* getLedgerIncomesWatcher() {
  yield takeLatest(A.getLedgerIncomes.toString(), S.getLedgerIncomesSaga);
}
export function* getLedgerExpensesWatcher() {
  yield takeLatest(A.getLedgerExpenses.toString(), S.getLedgerExpensesSaga);
}
export function* setLedgerIncomeConfirmationWatcher() {
  yield takeLatest(
    A.setLedgerIncomeConfirmation.toString(),
    S.setLedgerIncomeConfirmationSaga
  );
}
export function* setLedgerExpenseConfirmationWatcher() {
  yield takeLatest(
    A.setLedgerExpenseConfirmation.toString(),
    S.setLedgerExpenseConfirmationSaga
  );
}
export function* setTaxBracketWatcher() {
  yield takeLatest(A.setTaxBracket.toString(), S.setTaxBracketSaga);
}
export function* deleteTaxBracketWatcher() {
  yield takeLatest(A.deleteTaxBracket.toString(), S.deleteTaxBracketSaga);
}
export function* getTaxBracketsWatcher() {
  yield takeLatest(A.getTaxBrackets.toString(), S.getTaxBracketSaga);
}
export function* getTaxBracketYearsWatcher() {
  yield takeLatest(A.getTaxBracketYears.toString(), S.getTaxBracketYearsSaga);
}
export function* setIncomeWatcher() {
  yield takeLatest(A.setIncome.toString(), S.setIncomeSaga);
}
export function* deleteIncomeWatcher() {
  yield takeLatest(A.deleteIncome.toString(), S.deleteIncomeSaga);
}
export function* getIncomesWatcher() {
  yield takeLatest(A.getIncomes.toString(), S.getIncomesSaga);
}

export function* setExpenseWatcher() {
  yield takeLatest(A.setExpense.toString(), S.setExpenseSaga);
}
export function* deleteExpenseWatcher() {
  yield takeLatest(A.deleteExpense.toString(), S.deleteExpenseSaga);
}
export function* getExpensesWatcher() {
  yield takeLatest(A.getExpenses.toString(), S.getExpensesSaga);
}

export function* getDocumentsWatcher() {
  yield takeLatest(A.getDocuments.toString(), S.getDocumentsSaga);
}
export function* addDocumentWatcher() {
  yield takeLatest(A.addDocument.toString(), S.addDocumentSaga);
}
export function* downloadDocumentWatcher() {
  yield takeLatest(A.downloadDocument.toString(), S.downloadDocumentSaga);
}
export function* deleteDocumentWatcher() {
  yield takeLatest(A.deleteDocument.toString(), S.deleteDocumentSaga);
}
