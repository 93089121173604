/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import AdminIcon from "@rsuite/icons/Admin";
import ExitIcon from "@rsuite/icons/Exit";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "rsuite";
import {
  getActiveRouteSelector,
  getSelectedYearSelector,
  getYearsSelector,
  setYear,
} from "../redux/app";
import { getStringSelector } from "../redux/locale";
import { getUsernameSelector, isAdminSelector, logout } from "../redux/user";
import Button from "./Button";
import SelectPicker from "./SelectPicker";
import View from "./View";

const PanelHeader = () => {
  const dispatch = useDispatch();
  const strings = useSelector(getStringSelector);
  const active_route = useSelector(getActiveRouteSelector);
  const username = useSelector(getUsernameSelector);
  const years = useSelector(getYearsSelector);
  const selected_year = useSelector(getSelectedYearSelector);
  const isAdmin = useSelector(isAdminSelector);

  const isYearPickerVisible = useMemo(() => {
    switch (active_route?.id) {
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
        return true;

      case "10":
      default:
        return false;
    }
  }, [active_route]);

  const onYearChange = (year: number) => {
    dispatch(setYear(year));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            fontSize: 25,
            fontWeight: "bolder",
            marginRight: 5,
          }}
        >
          {active_route?.title ?? ""}
        </span>
        {active_route && active_route.badge > 0 && (
          <Badge content={active_route.badge} />
        )}
      </div>
      <View row>
        {isYearPickerVisible && (
          <SelectPicker
            size="lg"
            placement="bottomEnd"
            cleanable={false}
            value={selected_year}
            onChange={onYearChange}
            data={years.map((y) => ({ label: y, value: y }))}
            style={{ marginRight: 20 }}
          />
        )}
        {isAdmin && (
          <AdminIcon
            style={{
              alignSelf: "center",
              marginRight: 10,
              fontSize: "1.2rem",
            }}
          />
        )}
        <span
          style={{
            alignSelf: "center",
            fontSize: 20,
            fontWeight: "bolder",
            marginRight: 15,
          }}
        >
          {username}
        </span>
        <Button
          size="lg"
          appearance="subtle"
          style={{ fontWeight: "bold" }}
          onClick={() => dispatch(logout())}
        >
          <ExitIcon
            style={{ marginRight: 10, fontSize: "1.2rem", marginTop: -5 }}
          />
          {strings.getString("logout")}
        </Button>
      </View>
    </div>
  );
};

export default PanelHeader;
