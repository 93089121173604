import { combineReducers } from "redux";
import { PersistConfig, persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";
import * as T from "../types";
import appReducer from "./app/reducer";
import localeReducer from "./locale/reducer";
import transactionsReducer from "./transactions/reducer";
import userReducer from "./user/reducer";

const appPersistConfig: PersistConfig<T.AppReducer> = {
  key: "app",
  storage: storage,
  whitelist: ["side_nav_expanded"],
};
const userPersistConfig: PersistConfig<T.UserReducer> = {
  key: "user",
  storage: storage,
  blacklist: [],
  transforms: [
    encryptTransform({
      secretKey: "my-super-secret-key-that-is-easily-findable",
    }),
  ],
};

const rootReducer = () =>
  combineReducers({
    app: persistReducer(appPersistConfig, appReducer),
    locale: localeReducer,
    transactions: transactionsReducer,
    user: persistReducer(userPersistConfig, userReducer),
  });

export default rootReducer;
