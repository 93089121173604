import { call, put } from "redux-saga/effects";
import { ActionPayload, ApiResponse } from "../../../types";
import { downloadFile } from "../../../utils";
import {
  createRequestSaga,
  getErrorMessage,
  objectToFormData,
} from "../../Api";
import { configure, setLoaded, setLoading } from "../../actions";
import * as A from "../actions";
import * as O from "../types/objects";
import * as P from "../types/params";

export function* loginSaga({ payload }: ActionPayload<P.LoginA>) {
  const { params, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.AuthObject> = yield call(createRequestSaga, {
      url: "auth/login",
      method: "POST",
      body: objectToFormData(params),
    });
    yield put(A.setUser(response.data.user));
    yield put(A.setToken(response.data.token));
    yield put(configure());
  } catch (e: any) {
    const message: string = yield getErrorMessage(e);
    onError(message, e.response.status === 423 ? "2FA" : undefined);
  } finally {
    yield put(setLoaded());
  }
}
export function* forgotPasswordSaga({
  payload,
}: ActionPayload<P.ForgotPasswordA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.AuthObject> = yield call(createRequestSaga, {
      url: "auth/send-password",
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e: any) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* resetPasswordSaga({
  payload,
}: ActionPayload<P.ResetPasswordA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.AuthObject> = yield call(createRequestSaga, {
      url: "auth/reset-password",
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e: any) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* updateUserSaga({ payload }: ActionPayload<P.UpdateUserA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.UserObject> = yield call(createRequestSaga, {
      url: "user/password",
      method: "POST",
      body: objectToFormData(params),
    });
    //yield put(A.setUser(response.data));
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* updateUserSettingsSaga({
  payload,
}: ActionPayload<P.UpdateUserA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.UserObject> = yield call(createRequestSaga, {
      url: "user/settings",
      method: "POST",
      body: objectToFormData(params),
    });
    yield put(A.setUser(response.data));
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getSettingsSaga() {
  try {
    const response: ApiResponse<O.SettingsObject> = yield call(
      createRequestSaga,
      {
        url: "settings",
      }
    );
    if (response.data) yield put(A.setSettings(response.data));
  } catch (e) {
    const message: string = yield getErrorMessage(e);
  }
}
export function* updateSettingsSaga({
  payload,
}: ActionPayload<P.UpdateSettingsA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.SettingsObject> = yield call(
      createRequestSaga,
      {
        url: "settings/update",
        method: "POST",
        body: objectToFormData(params),
      }
    );
    yield put(A.setSettings(response.data));
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* get2FASecretSaga({ payload }: ActionPayload<P.Secret2faA>) {
  const { onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<string> = yield call(createRequestSaga, {
      method: "POST",
      url: "user/2fa-secret",
    });
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* verify2FACodeSaga({ payload }: ActionPayload<P.Code2faA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.UserObject> = yield call(createRequestSaga, {
      method: "POST",
      url: "user/2fa-verify",
      body: objectToFormData(params),
    });
    yield put(A.setUser(response.data));
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getExchangeRatesSaga({
  payload,
}: ActionPayload<P.ExchangeRatesA>) {
  const { onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.TcmbCurrencyResponse> = yield call(
      createRequestSaga,
      {
        url: "user/exchange",
        method: "GET",
      }
    );
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getCurrenciesSaga() {
  try {
    const response: ApiResponse<O.CurrencyObject[]> = yield call(
      createRequestSaga,
      {
        url: "user/currency",
        method: "GET",
      }
    );
    yield put(A.setCurrencies(response.data));
  } catch (e) {
    const message: string = yield getErrorMessage(e);
  }
}
export function* updateCurrenciesSaga({
  payload,
}: ActionPayload<P.UpdateCurrenciesA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.CurrencyObject[]> = yield call(
      createRequestSaga,
      {
        url: "user/currency",
        method: "POST",
        body: objectToFormData(params),
      }
    );
    yield put(A.setCurrencies(response.data));
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getUsersSaga({ payload }: ActionPayload<P.GetUsersA>) {
  const { onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.UserObject[]> = yield call(
      createRequestSaga,
      {
        url: "user/admin",
        method: "GET",
      }
    );
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* addUserSaga({ payload }: ActionPayload<P.AddUserA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      url: "user/admin/invite",
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* deleteUserSaga({ payload }: ActionPayload<P.DeleteUserA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      url: "user/admin/delete",
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}

export function* getGibSaga({ payload }: ActionPayload<P.CredentialsA>) {
  try {
    const response: ApiResponse<O.GibCredentialsObject> = yield call(
      createRequestSaga,
      {
        url: "gib",
        method: "GET",
      }
    );
    yield put(A.setGibStatus(true));
    payload?.onSuccess?.(response.data);
  } catch (e) {
    yield put(A.setGibStatus(false));
    const message: string = yield getErrorMessage(e);
    payload?.onError?.(message);
  }
}
export function* setGibSaga({ payload }: ActionPayload<P.SetCredentialsA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.GibCredentialsObject> = yield call(
      createRequestSaga,
      {
        url: "gib/update",
        method: "POST",
        body: objectToFormData(params),
      }
    );
    yield put(A.setGibStatus(true));
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* deleteGibSaga({
  payload,
}: ActionPayload<P.DeleteCredentialsA>) {
  const { onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.GibCredentialsObject> = yield call(
      createRequestSaga,
      {
        url: "gib/delete",
        method: "POST",
      }
    );
    yield put(A.setGibStatus(false));
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}

export function* getGibInvoicesSaga({
  payload,
}: ActionPayload<P.GibInvoicesA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.GibInvoiceObject[]> = yield call(
      createRequestSaga,
      {
        url: "gib/invoices",
        method: "GET",
        body: params,
      }
    );
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getGibInvoiceSaga({ payload }: ActionPayload<P.GibInvoiceA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.GibInvoiceDetailResponse> = yield call(
      createRequestSaga,
      {
        url: "gib/invoice",
        method: "GET",
        body: params,
      }
    );
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getGibInvoiceForIncomeSaga({
  payload,
}: ActionPayload<P.GibInvoiceForIncomeA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.GibInvoiceIncomeResponse> = yield call(
      createRequestSaga,
      {
        url: "gib/invoice/income",
        method: "GET",
        body: params,
      }
    );
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* setGibInvoiceSaga({
  payload,
}: ActionPayload<P.SetGibInvoiceA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      url: "gib/invoice/create",
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* downloadGibInvoiceSaga({
  payload,
}: ActionPayload<P.DownloadGibInvoiceA>) {
  const { params, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<File> = yield call(createRequestSaga, {
      url: "gib/invoice/download",
      method: "GET",
      body: params,
      responseType: "blob",
    });
    downloadFile(response.data, params.uuid + ".zip");
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* deleteGibInvoiceSaga({
  payload,
}: ActionPayload<P.DeleteGibInvoiceA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      url: "gib/invoice/delete",
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* sendGibSmsSaga({ payload }: ActionPayload<P.SendGibSmsA>) {
  const { onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<string> = yield call(createRequestSaga, {
      url: "gib/sms",
      method: "POST",
    });
    onSuccess({ message: response.message, id: response.data });
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* signGibInvoiceSaga({
  payload,
}: ActionPayload<P.SignGibInvoiceA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      url: "gib/invoice/sign",
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getGibInvoiceExchangeRateSaga({
  payload,
}: ActionPayload<P.GibInvoiceExchangeRateA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<number> = yield call(createRequestSaga, {
      url: "gib/invoice/exchange",
      method: "GET",
      body: params,
    });
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}

export function* getTemplatesSaga({ payload }: ActionPayload<P.TemplatesA>) {
  const { onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.GibTemplateObject[]> = yield call(
      createRequestSaga,
      {
        url: "gib/templates",
        method: "GET",
      }
    );
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getTemplateSaga({ payload }: ActionPayload<P.TemplateA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<O.GibTemplateObject> = yield call(
      createRequestSaga,
      {
        url: "gib/template",
        method: "GET",
        body: params,
      }
    );
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* createTemplateSaga({
  payload,
}: ActionPayload<P.SetTemplateA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      url: "gib/template/create",
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* setTemplateSaga({ payload }: ActionPayload<P.SetTemplateA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      url: "gib/template/update",
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* deleteTemplateSaga({
  payload,
}: ActionPayload<P.DeleteTemplateA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      url: "gib/template/delete",
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* logoutSaga() {
  try {
    yield put(setLoading());
    yield call(createRequestSaga, {
      url: "user/logout",
      method: "DELETE",
    });
    yield put(A.setLogout());
  } catch (e) {
    yield put(A.setLogout());
  } finally {
    yield put(setLoaded());
  }
}
