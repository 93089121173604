import { Formik, FormikProps } from "formik";
import { FC, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Panel, toaster } from "rsuite";
import * as yup from "yup";
import {
  Button,
  Form,
  InputNumber,
  Notify,
  Toggle,
  View,
} from "../../../components";
import { getStringSelector } from "../../../redux/locale";
import { deleteTaxBracket, setTaxBracket } from "../../../redux/transactions";
import { SetTaxBracketP } from "../../../redux/types";

interface Props {
  data?: {
    id?: number;
    tax_bracket?: string;
    tax_percent?: string;
    year?: string;
  };
  visible: boolean;
  close: (isCancelled: boolean) => void;
}
const initialValues = {
  toggle: false,
  tax_bracket: "",
  tax_percent: "",
  year: "",
};
const Add: FC<Props> = ({ data, visible, close }) => {
  const strings = useSelector(getStringSelector);
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const SCHEMA = yup.object().shape({
    toggle: yup.boolean().default(false),
    tax_bracket: yup.string(),
    tax_percent: yup.string().required(strings.getString("hint_required")),
    year: yup.string().required(strings.getString("hint_required")),
  });
  const is_edit = useMemo(() => Boolean(data?.id), [data]);
  const onOpen = () => {
    formikRef.current?.setValues({
      toggle: data?.tax_bracket === null,
      tax_bracket: data?.tax_bracket ?? initialValues.tax_bracket,
      tax_percent: data?.tax_percent ?? initialValues.tax_percent,
      year: data?.year ?? initialValues.year,
    });
  };

  const onCancel = () => {
    onClose(true)
  }
  const onClose = (isCancelled?: boolean) => {
    close(isCancelled ?? false);
    formikRef.current?.setValues(initialValues);
    formikRef.current?.resetForm();
  };
  const onSubmitClick = () => formikRef.current?.handleSubmit();
  const onSubmit = (v: typeof initialValues) => {
    let params: SetTaxBracketP = {
      tax_bracket: v.toggle ? null : v.tax_bracket,
      tax_percent: v.tax_percent,
      year: v.year,
    };
    if (data?.id) params.id = data.id;
    dispatch(
      setTaxBracket({
        params,
        onSuccess: (message) => {
          onClose();
          message && toaster.push(<Notify header={message} type={"success"} />);
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const onDeleteClick = () => {
    if (data?.id) {
      dispatch(
        deleteTaxBracket({
          params: { id: data.id },
          onSuccess: (message) => {
            onClose();
            message &&
              toaster.push(<Notify header={message} type={"success"} />);
          },
          onError: (message) => {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
    }
  };
  return (
    <Modal open={visible} onClose={onCancel} onOpen={onOpen}>
      <Modal.Header>
        <Modal.Title style={{ fontWeight: "bolder" }}>
          {strings.getString("brackets_set")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Panel bordered>
          <Formik
            innerRef={formikRef}
            validationSchema={SCHEMA}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              onSubmit(values);
            }}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form fluid onSubmit={(s, e) => handleSubmit(e)}>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("brackets_year")}
                  </Form.ControlLabel>
                  <InputNumber
                    name="year"
                    maxLength={4}
                    min={2000}
                    max={2099}
                    onChange={(t) => handleChange("year")(String(t))}
                    onBlur={handleBlur("year")}
                    value={values.year}
                  />
                </Form.Group>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Form.Group style={{ marginRight: "1rem" }}>
                    <Form.ControlLabel>
                      {strings.getString("brackets_amount")}
                    </Form.ControlLabel>
                    <InputNumber
                      name="tax_bracket"
                      disabled={values.toggle}
                      onChange={(t) => handleChange("tax_bracket")(String(t))}
                      onBlur={handleBlur("tax_bracket")}
                      value={values.tax_bracket}
                      postfix={"₺"}
                      min={1}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.ControlLabel>
                      {strings.getString("brackets_percent")}
                    </Form.ControlLabel>
                    <InputNumber
                      name="tax_percent"
                      onChange={(t) => handleChange("tax_percent")(String(t))}
                      onBlur={handleBlur("tax_percent")}
                      value={values.tax_percent}
                      postfix={"%"}
                      defaultValue={0}
                      min={0}
                      max={100}
                    />
                  </Form.Group>
                </div>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("brackets_last")}
                  </Form.ControlLabel>
                  <Toggle
                    checked={values.toggle}
                    onChange={(c) => setFieldValue("toggle", c)}
                  />
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Panel>
      </Modal.Body>
      <Modal.Footer
        as={View}
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        {is_edit && (
          <Button onClick={onDeleteClick} appearance="ghost">
            {strings.getString("brackets_delete")}
          </Button>
        )}
        <View
          style={{
            flexDirection: "row",
            alignSelf: "flex-end",
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={onSubmitClick} appearance="primary">
            {strings.getString("brackets_save")}
          </Button>
          <Button onClick={onCancel} appearance="subtle">
            {strings.getString("cancel")}
          </Button>
        </View>
      </Modal.Footer>
    </Modal>
  );
};

export default Add;
