/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import DefaultIcon from "@rsuite/icons/DraftRound";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Badge, Nav } from "rsuite";
import { getIconByName } from "../assets/icons";
import {
  getActiveRouteKeySelector,
  getSideNavExpandedSelector,
  setActiveRouteKey,
} from "../redux/app";
import { MenuItemObject } from "../types";
import { useDimensions } from "../utils";

const SidenavItem = ({ title, icon, id, url, badge }: MenuItemObject) => {
  const dispatch = useDispatch();
  const activeID = useSelector(getActiveRouteKeySelector);
  const is_expanded = useSelector(getSideNavExpandedSelector);
  const { isDesktop } = useDimensions();
  const expanded = useMemo(
    () => is_expanded && isDesktop,
    [is_expanded, isDesktop]
  );
  const isActive = useMemo(() => activeID === id, [activeID]);
  return (
    <Nav.Item
      as={Link}
      to={url}
      active={isActive}
      style={{
        ...(isActive && {
          fontWeight: "bold",
        }),
      }}
      onSelect={() => dispatch(setActiveRouteKey(id))}
      icon={icon ? getIconByName(icon) : expanded ? undefined : <DefaultIcon />}
    >
      {title} {badge > 0 && <Badge content={badge} />}
    </Nav.Item>
  );
};

export default SidenavItem;
