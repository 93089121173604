/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */

import { createAction } from "redux-smart-actions";
import * as O from "./types/objects";
import * as P from "./types/params";

/**
 * STATE
 */
export const setToken = createAction("SET_TOKEN", (payload: string) => ({
  payload,
}));
export const setUser = createAction("SET_USER", (payload: O.UserObject) => ({
  payload,
}));
export const setSettings = createAction(
  "SET_SETTINGS",
  (payload: O.SettingsObject) => ({
    payload,
  })
);
export const setUserSettingsTheme = createAction(
  "SET_USER_SETTINGS_THEME",
  (payload: O.ThemeType) => ({
    payload,
  })
);
export const setCurrencies = createAction(
  "SET_CURRENCIES",
  (payload: O.CurrencyObject[]) => ({
    payload,
  })
);
export const setLogout = createAction("SET_LOGOUT");

/**
 * SAGA
 */
export const login = createAction("LOGIN", (payload: P.LoginA) => ({
  payload,
}));
export const forgotPassword = createAction(
  "FORGOT_PASSWORD",
  (payload: P.ForgotPasswordA) => ({
    payload,
  })
);
export const resetPassword = createAction(
  "RESET_PASSWORD",
  (payload: P.ResetPasswordA) => ({
    payload,
  })
);
export const updateUserSettings = createAction(
  "UPDATE_USER_SETTINGS",
  (payload: P.UpdateUserSettingsA) => ({
    payload,
  })
);
export const updateUser = createAction(
  "UPDATE_USER",
  (payload: P.UpdateUserA) => ({
    payload,
  })
);
export const getSettings = createAction("GET_SETTINGS");
export const updateSettings = createAction(
  "UPDATE_SETTINGS",
  (payload: P.UpdateSettingsA) => ({ payload })
);
export const getCurrencies = createAction("GET_CURRENCIES");
export const updateCurrencies = createAction(
  "UPDATE_CURRENCIES",
  (payload: P.UpdateCurrenciesA) => ({
    payload,
  })
);
export const getExchangeRates = createAction(
  "GET_EXCHANGE_RATES",
  (payload: P.ExchangeRatesA) => ({
    payload,
  })
);
export const get2FASecret = createAction(
  "GET_2FA_SECRET",
  (payload: P.Secret2faA) => ({
    payload,
  })
);
export const verify2FACode = createAction(
  "VERIFY_2FA_CODE",
  (payload: P.Code2faA) => ({
    payload,
  })
);
export const getUsers = createAction("GET_USERS", (payload: P.GetUsersA) => ({
  payload,
}));
export const addUser = createAction("ADD_USER", (payload: P.AddUserA) => ({
  payload,
}));
export const deleteUser = createAction(
  "DELETE_USER",
  (payload: P.DeleteUserA) => ({
    payload,
  })
);

export const getGib = createAction("GET_GIB", (payload?: P.CredentialsA) => ({
  payload,
}));
export const setGib = createAction("SET_GIB", (payload: P.SetCredentialsA) => ({
  payload,
}));
export const setGibStatus = createAction(
  "SET_GIB_STATUS",
  (payload: boolean) => ({
    payload,
  })
);
export const deleteGib = createAction(
  "DELETE_GIB",
  (payload: P.DeleteCredentialsA) => ({
    payload,
  })
);

export const getGibInvoices = createAction(
  "GET_GIB_INVOICES",
  (payload: P.GibInvoicesA) => ({
    payload,
  })
);
export const getGibInvoice = createAction(
  "GET_GIB_INVOICE",
  (payload: P.GibInvoiceA) => ({
    payload,
  })
);
export const getGibInvoiceForIncome = createAction(
  "GET_GIB_INVOICE_FOR_INCOME",
  (payload: P.GibInvoiceForIncomeA) => ({
    payload,
  })
);
export const getGibInvoiceExchangeRate = createAction(
  "GET_GIB_INVOICE_EXCHANGE_RATE",
  (payload: P.GibInvoiceExchangeRateA) => ({
    payload,
  })
);
export const setGibInvoice = createAction(
  "SET_GIB_INVOICE",
  (payload: P.SetGibInvoiceA) => ({
    payload,
  })
);
export const downloadGibInvoice = createAction(
  "DOWNLOAD_GIB_INVOICE",
  (payload: P.DownloadGibInvoiceA) => ({
    payload,
  })
);
export const deleteGibInvoice = createAction(
  "DELETE_GIB_INVOICE",
  (payload: P.DeleteGibInvoiceA) => ({
    payload,
  })
);
export const sendGibSms = createAction(
  "SEND_GIB_SMS",
  (payload: P.SendGibSmsA) => ({
    payload,
  })
);
export const signGibInvoice = createAction(
  "SIGN_GIB_INVOICE",
  (payload: P.SignGibInvoiceA) => ({
    payload,
  })
);

export const getTemplates = createAction(
  "GET_TEMPLATES",
  (payload: P.TemplatesA) => ({
    payload,
  })
);
export const getTemplate = createAction(
  "GET_TEMPLATE",
  (payload: P.TemplateA) => ({
    payload,
  })
);
export const createTemplate = createAction(
  "CREATE_TEMPLATE",
  (payload: P.CreateTemplateA) => ({
    payload,
  })
);
export const setTemplate = createAction(
  "SET_TEMPLATE",
  (payload: P.SetTemplateA) => ({
    payload,
  })
);
export const deleteTemplate = createAction(
  "DELETE_TEMPLATE",
  (payload: P.DeleteTemplateA) => ({
    payload,
  })
);
export const logout = createAction("LOGOUT");
