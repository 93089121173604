/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { DetailedHTMLProps } from "react";

interface Props
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}
const ScreenWrapper = ({ children, style, ...rest }: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default ScreenWrapper;
