/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */

import { produce } from "immer";
import { ActionPayload, TransactionsReducer } from "../../types";
import { setLogout } from "../actions";

const initialState: TransactionsReducer = {};
type Payload = undefined;
const transactionsReducer = produce(
  (draft: TransactionsReducer, action: ActionPayload<Payload>) => {
    switch (action.type) {
      case setLogout.toString():
        break;
    }
  },
  initialState
);

export default transactionsReducer;
