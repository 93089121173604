/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import * as W from "./watchers";

export default [
  W.loginWatcher(),
  W.forgotPasswordWatcher(),
  W.resetPasswordWatcher(),
  W.logoutWatcher(),
  W.getSettingsWatcher(),
  W.updateSettingsWatcher(),
  W.updateUserWatcher(),
  W.updateUserSettingsWatcher(),
  W.getExchangeRatesWatcher(),
  W.getCurrenciesWatcher(),
  W.get2FASecretWatcher(),
  W.verify2FACodeWatcher(),
  W.getUsersWatcher(),
  W.addUserWatcher(),
  W.deleteUserWatcher(),
  W.updateCurrenciesWatcher(),
  W.getGibWatcher(),
  W.setGibWatcher(),
  W.deleteGibWatcher(),
  W.getGibInvoicesWatcher(),
  W.getGibInvoiceWatcher(),
  W.getGibInvoiceForIncomeWatcher(),
  W.setGibInvoiceWatcher(),
  W.downloadGibInvoiceWatcher(),
  W.deleteGibInvoiceWatcher(),
  W.sendGibSmsWatcher(),
  W.signGibInvoiceWatcher(),
  W.getGibInvoiceExchangeRateWatcher(),
  W.getTemplatesWatcher(),
  W.createTemplateWatcher(),
  W.setTemplateWatcher(),
  W.getTemplateWatcher(),
  W.deleteTemplateWatcher(),
];
