/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */

import { LocalizedStrings } from "react-localization";
import { call, put, select } from "redux-saga/effects";
import { ApiResponse, MenuItemObject } from "../../../types";
import { SUB_PATH } from "../../../utils";
import { createRequestSaga, getErrorMessage } from "../../Api";
import { getStringSelector } from "../../locale";
import {
  getAuthStatusSelector,
  getCurrencies,
  getGib,
  getSettings,
  isAdminSelector,
} from "../../user";
import * as A from "../actions";
import { getSelectedYearSelector } from "../selectors";

export function* configureSaga() {
  try {
    yield put(A.setLoading());
    const strings: LocalizedStrings<string> = yield select(getStringSelector);
    const is_logged: boolean = yield select(getAuthStatusSelector);
    const is_admin: boolean = yield select(isAdminSelector);
    let menu: MenuItemObject[] = [];
    if (is_admin) {
      menu.push(
        {
          id: "1",
          url: `/${SUB_PATH}`,
          title: strings.getString("dashboard"),
          icon: "home",
          badge: 0,
        },
        {
          id: "2",
          url: `/${SUB_PATH}calculate`,
          title: strings.getString("calculate"),
          icon: "calculate",
          badge: 0,
        },
        {
          id: "3",
          url: `/${SUB_PATH}incomes`,
          title: strings.getString("incomes"),
          icon: "incomes",
          badge: 0,
        },
        {
          id: "4",
          url: `/${SUB_PATH}expenses`,
          title: strings.getString("expenses"),
          icon: "expenses",
          badge: 0,
        }
      );
    }
    menu.push({
      id: "5",
      url: `/${SUB_PATH}ledger`,
      title: strings.getString("ledger"),
      icon: "ledger",
      badge: 0,
    });
    if (!is_admin) {
      menu.push(
        {
          id: "6",
          url: `/${SUB_PATH}ledger-incomes`,
          title: strings.getString("ledger-incomes"),
          icon: "ledger-incomes",
          badge: 0,
        },
        {
          id: "7",
          url: `/${SUB_PATH}ledger-expenses`,
          title: strings.getString("ledger-expenses"),
          icon: "ledger-expenses",
          badge: 0,
        }
      );
    }
    menu.push({
      id: "8",
      url: `/${SUB_PATH}brackets`,
      title: strings.getString("brackets"),
      icon: "brackets",
      badge: 0,
    });
    if (is_admin) {
      menu.push({
        id: "9",
        url: `/${SUB_PATH}gib`,
        title: strings.getString("gib"),
        icon: "gib",
        badge: 0,
      });
    }
    menu.push({
      id: "10",
      url: `/${SUB_PATH}settings`,
      title: strings.getString("settings"),
      icon: "settings",
      badge: 0,
    });
    if (is_logged) {
      yield call(getYearsSaga);
      if (is_admin) {
        yield put(getSettings());
        yield put(getCurrencies());
        yield put(getGib());
      }
    }
    yield put(A.setMenu(menu));
    yield put(A.setConfigured());
  } catch (e) {
    const message: string = yield getErrorMessage(e);
  } finally {
    yield put(A.setLoaded());
  }
}

export function* getYearsSaga() {
  try {
    const year: number | undefined = yield select(getSelectedYearSelector);
    const response: ApiResponse<number[]> = yield call(createRequestSaga, {
      url: "bracket/years",
      method: "GET",
    });
    if (!year) yield put(A.setYear(response.data?.[0]));
    yield put(A.setYears(response.data || []));
  } catch (e) {
    const message: string = yield getErrorMessage(e);
  }
}

export function* setSystemThemeSaga(event: MediaQueryListEvent) {
  yield put(A.setSystemTheme(event.matches ? "dark" : "light"));
}
