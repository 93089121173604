import { InfoOutline } from "@rsuite/icons";
import { Formik, FormikProps } from "formik";
import { FC, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DatePicker,
  InputGroup,
  Modal,
  Panel,
  Popover,
  Whisper,
  toaster,
} from "rsuite";
import * as yup from "yup";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Notify,
  SelectPicker,
  View,
} from "../../../components";
import { getStringSelector } from "../../../redux/locale";
import { deleteExpense, setExpense } from "../../../redux/transactions";
import {
  ExpenseStatusType,
  ExpenseType,
  SetExpenseP,
} from "../../../redux/types";
import { VAT_PERCENTAGES, getExpenseTypeList } from "../../../utils";

interface Props {
  data?: {
    id?: number;
    name?: string;
    amount?: string;
    vat_percent?: string;
    amount_over_vat?: string;
    type?: ExpenseType;
    due_date?: string;
    payed_at?: string;
    notes?: string;
    status?: ExpenseStatusType;
  };
  visible: boolean;
  close: (refresh?: boolean) => void;
}
const VATABLES: ExpenseType[] = ["general", "accounting", "external"];
const initialValues = {
  name: "",
  amount: "",
  vat_percent: "0",
  amount_over_vat: "0",
  type: "general",
  due_date: new Date().toISOString().split("T")[0],
  payed_at: new Date().toISOString().split("T")[0],
  notes: "",
};
const Add: FC<Props> = ({ data, visible, close }) => {
  const strings = useSelector(getStringSelector);
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const SCHEMA = yup.object().shape({
    name: yup.string().required(strings.getString("hint_required")),
    amount: yup.string().required(strings.getString("hint_required")),
    vat_percent: yup.string().required(strings.getString("hint_required")),
    amount_over_vat: yup.string().required(strings.getString("hint_required")),
    type: yup
      .mixed()
      .oneOf([
        "general",
        "tax",
        "accounting",
        "income-tax",
        "external",
        "non-allowable",
        "social-security-institution",
      ])
      .required(strings.getString("hint_required")),
    due_date: yup.string().required(strings.getString("hint_required")),
    payed_at: yup.string().required(strings.getString("hint_required")),
    notes: yup.string(),
  });
  const is_edit = useMemo(() => Boolean(data?.id), [data]);
  const is_read_only = useMemo(
    () => is_edit && data?.status !== "pending",
    [is_edit, data?.status]
  );
  const type_list = useMemo(() => getExpenseTypeList(strings), [strings]);
  const onOpen = () => {
    formikRef.current?.setValues({
      name: data?.name ?? initialValues.name,
      amount: data?.amount ?? initialValues.amount,
      vat_percent: data?.vat_percent ?? initialValues.vat_percent,
      amount_over_vat: data?.amount_over_vat ?? initialValues.amount_over_vat,
      type: data?.type ?? initialValues.type,
      due_date: data?.due_date ?? initialValues.due_date,
      payed_at: data?.payed_at ?? initialValues.payed_at,
      notes: data?.notes ?? initialValues.notes,
    });
  };
  const onCancel = () => {
    close(false);
    formikRef.current?.setValues(initialValues);
    formikRef.current?.resetForm();
  };
  const onClose = () => {
    close(true);
    formikRef.current?.setValues(initialValues);
    formikRef.current?.resetForm();
  };
  const onSubmitClick = () => formikRef.current?.handleSubmit();
  const onSubmit = (v: typeof initialValues) => {
    let params: SetExpenseP = {
      name: v.name,
      amount: v.amount,
      vat_percent: v.vat_percent,
      amount_over_vat: v.amount_over_vat,
      type: v.type as ExpenseType,
      payed_at: v.payed_at,
      due_date: v.due_date,
      notes: v.notes,
    };
    if (data?.id) params.id = data.id;
    if (!VATABLES.includes(v.type as ExpenseType)) params.vat_percent = "0";
    dispatch(
      setExpense({
        params,
        onSuccess: (message) => {
          onClose();
          message && toaster.push(<Notify header={message} type={"success"} />);
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const onDeleteClick = () => {
    if (data?.id) {
      dispatch(
        deleteExpense({
          params: { id: data.id },
          onSuccess: (message) => {
            onClose();
            message &&
              toaster.push(<Notify header={message} type={"success"} />);
          },
          onError: (message) => {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
    }
  };
  const getTypeWhisperText = (type: ExpenseType) => {
    switch (type) {
      case "external":
        return strings.getString("expense_external_description");
      case "non-allowable":
        return strings.getString("expense_non_allowable_description");

      default:
        return "";
    }
  };
  return (
    <Modal open={visible} onClose={onCancel} onOpen={onOpen}>
      <Modal.Header>
        <Modal.Title style={{ fontWeight: "bolder" }}>
          {strings.getString("expense_set")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Panel bordered>
          <Formik
            innerRef={formikRef}
            validationSchema={SCHEMA}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              onSubmit(values);
            }}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form fluid onSubmit={(s, e) => handleSubmit(e)}>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("expense_name")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="name"
                    readOnly={is_read_only}
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    value={values.name}
                    errorMessage={touched.name && errors.name}
                  />
                </Form.Group>
                <Form.Group
                  style={{ marginRight: "1rem", marginBottom: "1rem" }}
                >
                  <Form.ControlLabel>
                    {strings.getString("expense_amount")}
                    <Whisper
                      speaker={
                        <Popover>
                          {strings.getString("expense_vat_included")}
                        </Popover>
                      }
                    >
                      <InfoOutline style={{ marginLeft: 10 }} />
                    </Whisper>
                  </Form.ControlLabel>
                  <InputNumber
                    name="amount"
                    readOnly={is_read_only}
                    onChange={(t) => handleChange("amount")(String(t))}
                    onBlur={handleBlur("amount")}
                    value={values.amount}
                    postfix={"₺"}
                    min={1}
                  />
                </Form.Group>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Form.Group style={{ marginRight: "1rem" }}>
                    <Form.ControlLabel>
                      {strings.getString("expense_due_date")}
                    </Form.ControlLabel>
                    <DatePicker
                      readOnly={is_read_only}
                      onChange={(date) => {
                        setFieldValue(
                          "due_date",
                          (date ?? new Date()).toISOString().split("T")[0]
                        );
                      }}
                      value={new Date(values.due_date)}
                    />
                  </Form.Group>
                  <Form.Group style={{ marginRight: "1rem" }}>
                    <Form.ControlLabel>
                      {strings.getString("expense_payed_date")}
                    </Form.ControlLabel>
                    <DatePicker
                      readOnly={is_read_only}
                      onChange={(date) => {
                        setFieldValue(
                          "payed_at",
                          (date ?? new Date()).toISOString().split("T")[0]
                        );
                      }}
                      value={new Date(values.payed_at)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.ControlLabel>
                      {strings.getString("expense_type")}
                    </Form.ControlLabel>
                    <SelectPicker
                      readOnly={is_read_only}
                      placement="bottomStart"
                      cleanable={false}
                      value={values.type}
                      onChange={handleChange("type")}
                      data={type_list}
                    />
                    {(values.type === "non-allowable" ||
                      values.type === "external") && (
                      <Whisper
                        placement="bottom"
                        speaker={
                          <Popover style={{ maxWidth: "20vw" }}>
                            {getTypeWhisperText(values.type as ExpenseType)}
                          </Popover>
                        }
                      >
                        <InfoOutline style={{ marginLeft: 10 }} />
                      </Whisper>
                    )}
                  </Form.Group>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {VATABLES.includes(values.type as ExpenseType) && (
                    <>
                      <Form.Group style={{ marginRight: "1rem" }}>
                        <Form.ControlLabel>
                          {strings.getString("expense_vat_percent")}
                        </Form.ControlLabel>
                        <InputGroup>
                          {VAT_PERCENTAGES.map((v) => (
                            <InputGroup.Button
                              key={v}
                              disabled={is_read_only}
                              onClick={() =>
                                handleChange("vat_percent")(String(v))
                              }
                            >
                              {v}
                            </InputGroup.Button>
                          ))}
                          <InputNumber
                            name="vat_percent"
                            readOnly={is_read_only}
                            onChange={(t) =>
                              handleChange("vat_percent")(String(t))
                            }
                            onBlur={handleBlur("vat_percent")}
                            value={values.vat_percent}
                            postfix={"%"}
                            min={0}
                            max={100}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        style={{ marginRight: "1rem", marginBottom: "1rem" }}
                      >
                        <Form.ControlLabel>
                          {strings.getString("expense_amount_over_vat")}
                          <Whisper
                            speaker={
                              <Popover>
                                {strings.getString(
                                  "expense_amount_over_vat_desc"
                                )}
                              </Popover>
                            }
                          >
                            <InfoOutline style={{ marginLeft: 10 }} />
                          </Whisper>
                        </Form.ControlLabel>
                        <InputNumber
                          name="amount_over_vat"
                          readOnly={is_read_only}
                          onChange={(t) =>
                            handleChange("amount_over_vat")(String(t))
                          }
                          onBlur={handleBlur("amount_over_vat")}
                          value={values.amount_over_vat}
                          postfix={"₺"}
                          min={0}
                        />
                      </Form.Group>
                    </>
                  )}
                </div>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("expense_notes")}
                  </Form.ControlLabel>
                  <Input
                    name="notes"
                    readOnly={is_read_only}
                    onChange={handleChange("notes")}
                    onBlur={handleBlur("notes")}
                    value={values.notes}
                    as={"textarea"}
                    style={{ minHeight: "5rem" }}
                  />
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Panel>
      </Modal.Body>
      <Modal.Footer
        as={View}
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        {!is_read_only && is_edit && (
          <Button onClick={onDeleteClick} appearance="ghost">
            {strings.getString("expense_delete")}
          </Button>
        )}
        <View
          style={{
            flexDirection: "row",
            alignSelf: "flex-end",
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {!is_read_only && (
            <Button onClick={onSubmitClick} appearance="primary">
              {strings.getString("expense_save")}
            </Button>
          )}
          <Button onClick={onCancel} appearance="subtle">
            {strings.getString("cancel")}
          </Button>
        </View>
      </Modal.Footer>
    </Modal>
  );
};

export default Add;
