import { FC } from "react";
import { MonthType } from "../../../types";
import Card from "./Card";

interface Props {
  data: MonthType[];
}
const Year: FC<Props> = ({ data }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        overflowY: "scroll",
        maxHeight: "88vh",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      {data.map((d, i) => (
        <Card key={`card-${i}`} item={d} index={i} withVat />
      ))}
    </div>
  );
};

export default Year;
