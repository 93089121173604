import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, ScreenWrapper } from "../../../components";
import { getGibStatusSelector } from "../../../redux/app";
import { getStringSelector } from "../../../redux/locale";
import GibInvoices from "./Invoices";

function Gib() {
  const strings = useSelector(getStringSelector);
  const gib_connected = useSelector(getGibStatusSelector);
  const navigation = useNavigate();

  if (gib_connected) return <GibInvoices />;

  return (
    <ScreenWrapper style={{ justifyContent: "center" }}>
      <h4>{strings.getString("gib_not_connected")}</h4>
      <Button
        onClick={() => navigation("/settings#gib")}
        style={{ marginTop: "2rem" }}
      >
        {strings.getString("gib_open_settings")}
      </Button>
    </ScreenWrapper>
  );
}
export default Gib;
