import { SettingHorizontal } from "@rsuite/icons";
import CombinationIcon from "@rsuite/icons/Combination";
import GlobalIcon from "@rsuite/icons/Global";
import PeoplesIcon from "@rsuite/icons/Peoples";
import ShieldIcon from "@rsuite/icons/Shield";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Nav, ScreenWrapper } from "../../../components";
import { getStringSelector } from "../../../redux/locale";
import { getSettingsSelector, isAdminSelector } from "../../../redux/user";
import Currency from "./Currency";
import Gib from "./Gib/index";
import Invite from "./Invite";
import Options from "./Options";
import Security from "./Security";

type TabType = "options" | "currency" | "security" | "invite" | "gib";
function Settings() {
  const strings = useSelector(getStringSelector);
  const is_admin = useSelector(isAdminSelector);
  const settings = useSelector(getSettingsSelector);
  const { hash } = useLocation();

  const [tab, setTab] = useState<TabType>(hash === "#gib" ? "gib" : "options");
  return (
    <ScreenWrapper style={{ justifyContent: "flex-start" }}>
      <Nav
        activeKey={tab}
        appearance="tabs"
        onSelect={setTab}
        style={{ display: "flex", alignSelf: "stretch" }}
      >
        <Nav.Item eventKey="options" icon={<SettingHorizontal />}>
          {strings.getString("settings_tab_options")}
        </Nav.Item>
        {is_admin && settings.currencies && (
          <Nav.Item eventKey="currency" icon={<GlobalIcon />}>
            {strings.getString("settings_tab_currency")}
          </Nav.Item>
        )}
        <Nav.Item eventKey="security" icon={<ShieldIcon />}>
          {strings.getString("settings_tab_security")}
        </Nav.Item>
        {is_admin && (
          <Nav.Item eventKey="invite" icon={<PeoplesIcon />}>
            {strings.getString("settings_tab_invite")}
          </Nav.Item>
        )}
        {is_admin && (
          <Nav.Item eventKey="gib" icon={<CombinationIcon />}>
            {strings.getString("settings_tab_gib")}
          </Nav.Item>
        )}
      </Nav>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
          alignSelf: "stretch",
        }}
      >
        {tab === "options" && <Options />}
        {tab === "currency" && <Currency />}
        {tab === "security" && <Security />}
        {tab === "invite" && <Invite />}
        {tab === "gib" && <Gib />}
      </div>
    </ScreenWrapper>
  );
}
export default Settings;
