import { PieChart } from "@rsuite/charts";
import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Panel, Popover, Whisper } from "rsuite";
import { Divider, Nav } from "../../../components";
import { getThemeSelector } from "../../../redux/app";
import { getStringSelector } from "../../../redux/locale";
import { MonthType } from "../../../types";
import { displayCurrency } from "../../../utils";
import CardField from "../Home/Card/Field";

interface Props {
  data: MonthType[];
}
type PeriodType = "one" | "two" | "three" | "four";
const Period: FC<Props> = ({ data }) => {
  const strings = useSelector(getStringSelector);
  const theme = useSelector(getThemeSelector);
  const [period, setPeriod] = useState<PeriodType>("one");
  const total = useMemo(() => {
    let income = 0,
      income_vat = 0,
      expense = 0,
      expense_vat = 0,
      tax = 0,
      tax_basis = 0;
    data.forEach((d, i) => {
      let use = false;
      switch (period) {
        case "one":
          if (i >= 0 && i < 3) use = true;
          break;
        case "two":
          if (i >= 3 && i < 6) use = true;
          break;
        case "three":
          if (i >= 6 && i < 9) use = true;
          break;
        case "four":
          if (i >= 9 && i < 12) use = true;
          break;

        default:
          break;
      }
      if (use) {
        income += d.income;
        income_vat += d.income - d.income_wo_vat;
        expense += d.expense;
        expense_vat += d.expense - d.expense_wo_vat;
        tax += d.tax;
        tax_basis += d.tax_basis;
      }
    });
    return { income, expense, tax, income_vat, expense_vat, tax_basis };
  }, [data, period]);

  const pie_chart: any = useMemo(() => {
    if (total.income === 0 && total.expense === 0)
      return [
        [strings.getString("total_earning"), 0],
        [strings.getString("total_expense"), 0],
        [strings.getString("total_tax"), 0],
      ];
    let net = total.income - (total.tax + total.expense);
    net += total.expense_vat - total.income_vat;
    const ratio = 100 / total.income;
    const t = parseFloat((total.tax * ratio).toFixed(1));
    const n = parseFloat((net * ratio).toFixed(1));
    const e = (100 - (n + t)).toFixed(1);
    return [
      [strings.getString("total_earning"), n],
      [strings.getString("total_expense"), e],
      [strings.getString("total_tax"), t],
    ];
  }, [total, strings]);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignSelf: "stretch",
        overflowY: "scroll",
        maxHeight: "88vh",
      }}
    >
      <Nav appearance="tabs" vertical={true}>
        <Nav.Item active={period === "one"} onClick={() => setPeriod("one")}>
          {strings.getString("ledger_tab_period_1")}
        </Nav.Item>
        <Nav.Item active={period === "two"} onClick={() => setPeriod("two")}>
          {strings.getString("ledger_tab_period_2")}
        </Nav.Item>
        <Nav.Item
          active={period === "three"}
          onClick={() => setPeriod("three")}
        >
          {strings.getString("ledger_tab_period_3")}
        </Nav.Item>
        <Nav.Item active={period === "four"} onClick={() => setPeriod("four")}>
          {strings.getString("ledger_tab_period_4")}
        </Nav.Item>
      </Nav>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flex: 1,
          flexDirection: "row",
        }}
      >
        <div style={{ display: "flex", flex: 1, alignSelf: "center" }}>
          <PieChart
            data={pie_chart}
            label={{
              color: theme === "dark" ? "white" : "black",
              fontWeight: "bold",
            }}
            emphasis={{ scaleSize: 12 }}
            style={{ minWidth: 200, width: "100%" }}
            legend={false}
          />
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Panel
            bordered
            className="panel-card-container"
            style={{ flexBasis: "80%" }}
            header={
              <span className="panel-header-title">
                {strings.getString("ledger_period_total")}
              </span>
            }
          >
            <Divider />
            <Whisper
              placement="auto"
              speaker={
                <Popover title={strings.getString("total_income_vat")}>
                  <p>{displayCurrency(total.income_vat)}</p>
                </Popover>
              }
            >
              <div>
                <CardField
                  title={strings.getString("total_income")}
                  value={displayCurrency(total.income)}
                />
              </div>
            </Whisper>
            <Whisper
              placement="auto"
              speaker={
                <Popover title={strings.getString("total_expense_detail")}>
                  <p>
                    {strings.formatString(
                      strings.getString("total_expense_wo_vat"),
                      displayCurrency(total.expense - total.expense_vat)
                    )}
                  </p>
                  <p>
                    {strings.formatString(
                      strings.getString("total_expense_vat"),
                      displayCurrency(total.expense_vat)
                    )}
                  </p>
                </Popover>
              }
            >
              <div>
                <CardField
                  title={strings.getString("total_expense")}
                  value={displayCurrency(total.expense)}
                />
              </div>
            </Whisper>
            <CardField
              title={strings.getString("total_tax_basis")}
              value={displayCurrency(total.tax_basis)}
            />
            <CardField
              title={strings.getString("total_tax")}
              value={displayCurrency(total.tax)}
            />
            <CardField
              title={strings.getString(
                total.expense_vat - total.income_vat > 0
                  ? "total_vat_receive"
                  : "total_vat_pay"
              )}
              direction={
                total.expense_vat - total.income_vat > 0 ? "up" : "down"
              }
              value={displayCurrency(
                Math.abs(total.expense_vat - total.income_vat)
              )}
            />
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default Period;
