/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { useSelector } from "react-redux";
import { Input as InputComponent, InputProps } from "rsuite";
import { getLoadingStateSelector } from "../redux/app";
const Input = ({ disabled, ...props }: InputProps) => {
  const loading = useSelector(getLoadingStateSelector);
  return <InputComponent {...props} disabled={loading || disabled} />;
};

export default Input;
