/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */

import { createAction } from "redux-smart-actions";

/**
 * STATE
 */
export const setLocalization = createAction("SET_LOCALIZATION");

/**
 * SAGA
 */
