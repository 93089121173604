/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import * as W from "./watchers";

export default [
  W.getHomeWatcher(),
  W.getLedgerWatcher(),
  W.getLedgerIncomesWatcher(),
  W.getLedgerExpensesWatcher(),
  W.setLedgerIncomeConfirmationWatcher(),
  W.setLedgerExpenseConfirmationWatcher(),
  W.setTaxBracketWatcher(),
  W.deleteTaxBracketWatcher(),
  W.getTaxBracketYearsWatcher(),
  W.getTaxBracketsWatcher(),
  W.setIncomeWatcher(),
  W.deleteIncomeWatcher(),
  W.getIncomesWatcher(),
  W.setExpenseWatcher(),
  W.deleteExpenseWatcher(),
  W.getExpensesWatcher(),
  W.getDocumentsWatcher(),
  W.addDocumentWatcher(),
  W.downloadDocumentWatcher(),
  W.deleteDocumentWatcher(),
];
