import { Check, Import, Plus, Reload } from "@rsuite/icons";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import SearchIcon from "@rsuite/icons/Search";
import VisibleIcon from "@rsuite/icons/Visible";
import dayjs from "dayjs";
import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CellProps, InputGroup, toaster } from "rsuite";
import {
  IconButton,
  Input,
  Notify,
  ScreenWrapper,
  SelectPicker,
  Table,
  View,
} from "../../../components";
import {
  getSelectedYearSelector,
  getSideNavExpandedSelector,
} from "../../../redux/app";
import { getStringSelector } from "../../../redux/locale";
import {
  GibInvoiceObject,
  GibInvoiceStatusType,
  GibInvoicesP,
} from "../../../redux/types";
import { getGibInvoices } from "../../../redux/user";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_WIDTH_EXPANDED,
  getGibInvoiceStatusList,
  useDimensions,
} from "../../../utils";
import Detail from "./Detail";
import Sign from "./Sign";
import Templates from "./Templates";
const { Column, Cell, HeaderCell } = Table;

function GibInvoices() {
  const strings = useSelector(getStringSelector);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const selected_year = useSelector(getSelectedYearSelector);
  const is_expanded = useSelector(getSideNavExpandedSelector);
  const { isDesktop, width } = useDimensions();
  const expanded = useMemo(
    () => is_expanded && isDesktop,
    [is_expanded, isDesktop]
  );
  const tableWidth = useMemo(
    () => (expanded ? width - SIDE_NAV_WIDTH_EXPANDED : width - SIDE_NAV_WIDTH),
    [width, expanded]
  );
  const [search, setSearch] = useState<string>("");
  const [template_modal, setTemplateModal] = useState<boolean>(false);
  const [data, setData] = useState<{ uuid?: string; id?: number }>();
  const [sign, setSign] = useState<string>();
  const [list_data, setListData] = useState<GibInvoiceObject[]>([]);
  const [selected_month, setSelectedMonth] = useState<number>(
    dayjs().get("month") + 1
  );
  const [selected_status, setSelectedStatus] = useState<
    GibInvoiceStatusType | undefined
  >();
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const months = useMemo(
    () => dayjs.months().map((m, i) => ({ label: m, id: i + 1 })),
    []
  );

  const status_list = useMemo(
    () => getGibInvoiceStatusList(strings),
    [strings]
  );
  const onInputChange = useCallback((e: string) => setSearch(e), []);
  const onSearchClick = () => {
    getTableData(search);
  };
  const onSearchClearClick = () => {
    setSearch("");
    getTableData();
  };
  const onInputKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e?.nativeEvent.key === "Enter") {
        onSearchClick();
      }
    },
    [search]
  );
  const getTableData = (search?: string) => {
    if (selected_year) {
      let params: GibInvoicesP = { date_start: "", date_end: "" };
      if (selected_month) {
        params = {
          date_start: `${selected_year}-${selected_month}-01`,
          date_end: `${selected_year}-${selected_month}-31`,
        };
      } else {
        params = {
          date_start: `${selected_year}-01-01`,
          date_end: `${selected_year}-12-31`,
        };
      }
      if (selected_status) params.status = selected_status;
      if (search) params.name = search;
      dispatch(
        getGibInvoices({
          params,
          onSuccess: (data) => {
            setListData(data || []);
          },
          onError: (message) => {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
    }
  };

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_year, selected_month, selected_status]);

  const EditCell = ({ rowData, dataKey, ...props }: CellProps) => {
    const onDetailClick = (uuid: string) => {
      setData({ uuid });
      setReadOnly(rowData["onayDurumu"] !== "Onaylanmadı");
    };
    const onImportClick = (uuid: string) => {
      navigation("/incomes#" + uuid);
    };
    const onConfirmationClick = (uuid: string) => {
      setSign(uuid);
    };
    if (!dataKey) return null;
    return (
      <Cell {...props} className="link-group">
        {rowData["onayDurumu"] === "Onaylandı" && (
          <IconButton
            color="orange"
            icon={<Import />}
            style={{ marginRight: 10 }}
            onClick={() => onImportClick(rowData[dataKey])}
          />
        )}
        {rowData["onayDurumu"] === "Onaylanmadı" && (
          <IconButton
            color="green"
            icon={<Check />}
            style={{ marginRight: 10 }}
            onClick={() => onConfirmationClick(rowData[dataKey])}
          />
        )}
        <IconButton
          icon={<VisibleIcon />}
          onClick={() => onDetailClick(rowData[dataKey])}
        />
      </Cell>
    );
  };

  const closeDetail = useCallback(() => {
    setData(undefined);
  }, []);

  const closeSign = useCallback(() => {
    setSign(undefined);
  }, []);

  return (
    <>
      <ScreenWrapper style={{ justifyContent: "flex-start" }}>
        <View
          row
          style={{
            justifyContent: "space-between",
            alignSelf: "stretch",
            marginRight: 10,
            marginLeft: 10,
            marginBottom: 10,
          }}
        >
          <IconButton
            icon={<Plus />}
            onClick={() => setTemplateModal(true)}
            size="lg"
          >
            {strings.getString("gib_add_invoice")}
          </IconButton>
          <InputGroup
            size="lg"
            inside
            style={{ width: width * 0.2, minWidth: 250 }}
          >
            <InputGroup.Button onClick={onSearchClick}>
              <SearchIcon />
            </InputGroup.Button>
            <Input
              value={search}
              style={{ paddingLeft: 50 }}
              onChange={onInputChange}
              placeholder={strings.getString("gib_invoice_search_placeholder")}
              onKeyDown={onInputKeyDown}
            />
            {search.length > 0 && (
              <InputGroup.Button onClick={onSearchClearClick}>
                <CloseOutlineIcon />
              </InputGroup.Button>
            )}
          </InputGroup>
          <div>
            <IconButton
              icon={<Reload />}
              onClick={() => getTableData(search)}
              size="lg"
            />
            <SelectPicker
              size="lg"
              placement="bottomEnd"
              style={{ marginRight: 10, marginLeft: 10 }}
              cleanable={true}
              value={selected_status}
              onChange={setSelectedStatus}
              data={status_list}
            />
            <SelectPicker
              size="lg"
              placement="bottomEnd"
              cleanable={true}
              value={selected_month}
              labelKey="label"
              valueKey="id"
              onChange={setSelectedMonth}
              data={months}
            />
          </div>
        </View>
        <div className="table-wrapper" style={{ height: "90%" }}>
          <Table
            fillHeight
            data={list_data || []}
            width={tableWidth}
            rowHeight={54}
          >
            <Column width={60} align="center" verticalAlign="middle" fixed>
              <HeaderCell>#</HeaderCell>
              <Cell dataKey="id" style={{ fontSize: 18 }}>
                {(d, i) => `#${(i ?? 0) + 1}`}
              </Cell>
            </Column>
            <Column width={180} verticalAlign={"middle"}>
              <HeaderCell>
                {strings.getString("gib_document_number")}
              </HeaderCell>
              <Cell dataKey="belgeNumarasi" />
            </Column>
            <Column width={120} verticalAlign={"middle"}>
              <HeaderCell>{strings.getString("gib_buyer_id")}</HeaderCell>
              <Cell dataKey="aliciVknTckn" />
            </Column>
            <Column flexGrow={1} verticalAlign={"middle"}>
              <HeaderCell>{strings.getString("gib_buyer_name")}</HeaderCell>
              <Cell dataKey="aliciUnvanAdSoyad" />
            </Column>
            <Column width={130} verticalAlign={"middle"}>
              <HeaderCell>{strings.getString("gib_document_date")}</HeaderCell>
              <Cell dataKey="belgeTarihi">
                {(d) => dayjs(d["belgeTarihi"], "DD-MM-YYYY").format("L")}
              </Cell>
            </Column>
            <Column width={150} verticalAlign={"middle"}>
              <HeaderCell>{strings.getString("gib_document_type")}</HeaderCell>
              <Cell dataKey="belgeTuru" />
            </Column>
            <Column width={150} verticalAlign={"middle"}>
              <HeaderCell>
                {strings.getString("gib_document_status")}
              </HeaderCell>
              <Cell dataKey="onayDurumu" />
            </Column>
            <Column width={110} verticalAlign={"middle"} align="center">
              <HeaderCell> </HeaderCell>
              <EditCell dataKey="ettn" />
            </Column>
          </Table>
        </div>
      </ScreenWrapper>
      <Detail data={data} readOnly={readOnly} close={closeDetail} />
      <Sign uuid={sign} close={closeSign} />
      <Templates
        visible={template_modal}
        onSelect={(id) => {
          if (id === 0) setData({});
          else setData({ id });
          setReadOnly(false);
        }}
        close={() => setTemplateModal(false)}
      />
    </>
  );
}
export default GibInvoices;
