import { Plus, RemindOutline, Trash } from "@rsuite/icons";
import dayjs from "dayjs";
import { Formik, FormikProps } from "formik";
import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, InputGroup, Modal, Panel, toaster } from "rsuite";
import * as yup from "yup";
import {
  Button,
  Form,
  IconButton,
  Input,
  InputNumber,
  Notify,
  SelectPicker,
} from "../../../components";
import {
  createTemplate,
  deleteGibInvoice,
  downloadGibInvoice,
  getGibInvoice,
  getGibInvoiceExchangeRate,
  getTemplate,
  setGibInvoice,
} from "../../../redux/actions";
import { getThemeSelector } from "../../../redux/app";
import { getStringSelector } from "../../../redux/locale";
import {
  CreateTemplateP,
  GibInvoiceDetailObject,
  SetGibInvoiceP,
} from "../../../redux/types";
import {
  GibCurrencies,
  GibInvoiceTypes,
  GibUnitTypes,
  getCurrencySymbol,
} from "../../../utils";

const initialValues: GibInvoiceDetailObject = {
  faturaUuid: "",
  faturaTarihi: "",
  faturaTipi: "",
  paraBirimi: "",
  dovzTLkur: 0,
  vknTckn: "",
  vergiDairesi: "",
  aliciUnvan: "",
  aliciAdi: "",
  aliciSoyadi: "",
  bulvarcaddesokak: "",
  binaAdi: "",
  binaNo: "",
  kapiNo: "",
  kasabaKoy: "",
  mahalleSemtIlce: "",
  postaKodu: "",
  ulke: "",
  tel: "",
  fax: "",
  eposta: "",
  websitesi: "",
  sehir: "",
  belgeNumarasi: "",
  komisyonOrani: 0,
  navlunOrani: 0,
  hammaliyeOrani: 0,
  nakliyeOrani: 0,
  komisyonTutari: 0,
  navlunTutari: 0,
  hammaliyeTutari: 0,
  nakliyeTutari: 0,
  komisyonKDVOrani: 0,
  navlunKDVOrani: 0,
  hammaliyeKDVOrani: 0,
  nakliyeKDVOrani: 0,
  komisyonKDVTutari: 0,
  navlunKDVTutari: 0,
  hammaliyeKDVTutari: 0,
  nakliyeKDVTutari: 0,
  gelirVergisiOrani: 0,
  bagkurTevkifatiOrani: 0,
  gelirVergisiTevkifatiTutari: 0,
  bagkurTevkifatiTutari: 0,
  halRusumuOrani: 0,
  ticaretBorsasiOrani: 0,
  milliSavunmaFonuOrani: 0,
  digerOrani: 0,
  halRusumuTutari: 0,
  ticaretBorsasiTutari: 0,
  milliSavunmaFonuTutari: 0,
  digerTutari: 0,
  halRusumuKDVOrani: 0,
  ticaretBorsasiKDVOrani: 0,
  milliSavunmaFonuKDVOrani: 0,
  digerKDVOrani: 0,
  halRusumuKDVTutari: 0,
  ticaretBorsasiKDVTutari: 0,
  milliSavunmaFonuKDVTutari: 0,
  digerKDVTutari: 0,
  malHizmetTable: [
    {
      malHizmet: "",
      miktar: 1,
      ozelMatrahTutari: 0,
      birim: "C62",
      birimFiyat: 0,
      iskontoOrani: 0,
      iskontoNedeni: "",
      iskontoArttm: "İskonto",
      iskontoTutari: 0,
      malHizmetTutari: 0,
      kdvOrani: 0,
      kdvTutari: 0,
      fiyat: 0,
      V0021Orani: 0,
      V0061Orani: 0,
      V0071Orani: 0,
      V0073Orani: 0,
      V0074Orani: 0,
      V0075Orani: 0,
      V0076Orani: 0,
      V0077Orani: 0,
      V1047Orani: 0,
      V1048Orani: 0,
      V4080Orani: 0,
      V4081Orani: 0,
      V9015Orani: 0,
      V9021Orani: 0,
      V9077Orani: 0,
      V8001Orani: 0,
      V8002Orani: 0,
      V4071Orani: 0,
      V8004Orani: 0,
      V8005Orani: 0,
      V8006Orani: 0,
      V8007Orani: 0,
      V8008Orani: 0,
      V0003Orani: 0,
      V0011Orani: 0,
      V9040Orani: 0,
      V4171Orani: 0,
      V9944Orani: 0,
      V0059Orani: 0,
      V0021Tutari: 0,
      V0061Tutari: 0,
      V0061KdvTutari: 0,
      V0071Tutari: 0,
      V0071KdvTutari: 0,
      V0073Tutari: 0,
      V0073KdvTutari: 0,
      V0074Tutari: 0,
      V0074KdvTutari: 0,
      V0075Tutari: 0,
      V0075KdvTutari: 0,
      V0076Tutari: 0,
      V0076KdvTutari: 0,
      V0077Tutari: 0,
      V0077KdvTutari: 0,
      V1047Tutari: 0,
      V1047KdvTutari: 0,
      V1048Tutari: 0,
      V4080Tutari: 0,
      V4081Tutari: 0,
      V9015Tutari: 0,
      V9021Tutari: 0,
      V9077Tutari: 0,
      V9077KdvTutari: 0,
      V8001Tutari: 0,
      V8002Tutari: 0,
      V8002KdvTutari: 0,
      V4071Tutari: 0,
      V4071KdvTutari: 0,
      V8004Tutari: 0,
      V8004KdvTutari: 0,
      V8005Tutari: 0,
      V8005KdvTutari: 0,
      V8006Tutari: 0,
      V8007Tutari: 0,
      V8008Tutari: 0,
      V0003Tutari: 0,
      V0011Tutari: 0,
      V9040Tutari: 0,
      V4171Tutari: 0,
      V9944Tutari: 0,
      V9944KdvTutari: 0,
      V0059Tutari: 0,
      hesaplananotvtevkifatakatkisi2: 0,
      tevkifatIadeKonuIslemBedeli: 0,
      tevkifatIadeEdilenMalOrani: 0,
      tevkifatIadeKdvTutari: 0,
      tevkifatIadeTevkifatsizKdvTutari: 0,
    },
  ],
  malhizmetToplamTutari: 0,
  matrah: 0,
  toplamIskonto: 0,
  hesaplanankdv: 0,
  vergilerDahilToplamTutar: 0,
  toplamMasraflar: 0,
  odenecekTutar: 0,
  vergilerToplami: 0,
  tip: "",
  not: "",
  saat: "",
  ozelMatrahTutari: 0,
  ozelMatrahOrani: 0,
  ozelMatrahVergiTutari: 0,
  hata: "",
};

const initialTemplateValues = {
  name: "",
};

interface Props {
  data?: {
    uuid?: string;
    id?: number;
  };
  readOnly: boolean;
  close: () => void;
}
function Detail({ data, readOnly, close }: Props) {
  const strings = useSelector(getStringSelector);
  const theme = useSelector(getThemeSelector);
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const [html, setHtml] = useState<{ __html: string }>({ __html: "" });
  const [preview, setPreview] = useState<boolean>(false);
  const SCHEMA = yup.object().shape({
    //name: yup.string().required(strings.getString("hint_required")),
    faturaUuid: yup.string(),
    faturaTarihi: yup.string(),
    faturaTipi: yup.string(),
    paraBirimi: yup.string(),
    dovzTLkur: yup.string(),
    vknTckn: yup.string(),
    vergiDairesi: yup.string(),
    aliciUnvan: yup.string(),
    aliciAdi: yup.string(),
    aliciSoyadi: yup.string(),
    bulvarcaddesokak: yup.string(),
    binaAdi: yup.string(),
    binaNo: yup.string(),
    kapiNo: yup.string(),
    kasabaKoy: yup.string(),
    mahalleSemtIlce: yup.string(),
    postaKodu: yup.string(),
    ulke: yup.string(),
    tel: yup.string(),
    fax: yup.string(),
    eposta: yup.string(),
    websitesi: yup.string(),
    sehir: yup.string(),
    belgeNumarasi: yup.string(),
    komisyonOrani: yup.string(),
    navlunOrani: yup.string(),
    hammaliyeOrani: yup.string(),
    nakliyeOrani: yup.string(),
    komisyonTutari: yup.string(),
    navlunTutari: yup.string(),
    hammaliyeTutari: yup.string(),
    nakliyeTutari: yup.string(),
    komisyonKDVOrani: yup.string(),
    navlunKDVOrani: yup.string(),
    hammaliyeKDVOrani: yup.string(),
    nakliyeKDVOrani: yup.string(),
    komisyonKDVTutari: yup.string(),
    navlunKDVTutari: yup.string(),
    hammaliyeKDVTutari: yup.string(),
    nakliyeKDVTutari: yup.string(),
    gelirVergisiOrani: yup.string(),
    bagkurTevkifatiOrani: yup.string(),
    gelirVergisiTevkifatiTutari: yup.string(),
    bagkurTevkifatiTutari: yup.string(),
    halRusumuOrani: yup.string(),
    ticaretBorsasiOrani: yup.string(),
    milliSavunmaFonuOrani: yup.string(),
    digerOrani: yup.string(),
    halRusumuTutari: yup.string(),
    ticaretBorsasiTutari: yup.string(),
    milliSavunmaFonuTutari: yup.string(),
    digerTutari: yup.string(),
    halRusumuKDVOrani: yup.string(),
    ticaretBorsasiKDVOrani: yup.string(),
    milliSavunmaFonuKDVOrani: yup.string(),
    digerKDVOrani: yup.string(),
    halRusumuKDVTutari: yup.string(),
    ticaretBorsasiKDVTutari: yup.string(),
    milliSavunmaFonuKDVTutari: yup.string(),
    digerKDVTutari: yup.string(),
    malHizmetTable: yup.array(),
    malhizmetToplamTutari: yup.string(),
    matrah: yup.string(),
    toplamIskonto: yup.string(),
    hesaplanankdv: yup.string(),
    vergilerDahilToplamTutar: yup.string(),
    toplamMasraflar: yup.string(),
    odenecekTutar: yup.string(),
    vergilerToplami: yup.string(),
    tip: yup.string(),
    not: yup.string(),
    saat: yup.string(),
    ozelMatrahTutari: yup.string(),
    ozelMatrahOrani: yup.string(),
    ozelMatrahVergiTutari: yup.string(),
    hata: yup.string(),
  });
  const [delete_modal, setDeleteModal] = useState<boolean>(false);
  const [template_modal, setTemplateModal] = useState<boolean>(false);
  const formikTemplateRef =
    useRef<FormikProps<typeof initialTemplateValues>>(null);
  const SCHEMA_TEMPLATE = yup.object().shape({
    name: yup.string().required(strings.getString("hint_required")),
  });
  const onOpen = () => {
    if (data?.uuid) {
      dispatch(
        getGibInvoice({
          params: { uuid: data.uuid },
          onSuccess(payload) {
            if (payload) {
              setHtml({ __html: payload.html });
              const p = payload.invoice;
              formikRef.current?.setValues({
                ...initialValues,
                paraBirimi: p.paraBirimi ?? initialValues.paraBirimi,
                faturaTarihi: p.faturaTarihi ?? initialValues.faturaTarihi,
                faturaTipi: p.faturaTipi ?? initialValues.faturaTipi,
                saat: p.saat ?? initialValues.saat,
                dovzTLkur: p.dovzTLkur ?? initialValues.dovzTLkur,
                vknTckn: p.vknTckn ?? initialValues.vknTckn,
                vergiDairesi: p.vergiDairesi ?? initialValues.vergiDairesi,
                aliciUnvan: p.aliciUnvan ?? initialValues.aliciUnvan,
                aliciAdi: p.aliciAdi ?? initialValues.aliciAdi,
                aliciSoyadi: p.aliciSoyadi ?? initialValues.aliciSoyadi,
                mahalleSemtIlce:
                  p.mahalleSemtIlce ?? initialValues.mahalleSemtIlce,
                sehir: p.sehir ?? initialValues.sehir,
                ulke: p.ulke ?? initialValues.ulke,
                bulvarcaddesokak:
                  p.bulvarcaddesokak ?? initialValues.bulvarcaddesokak,
                malHizmetTable:
                  p.malHizmetTable ?? initialValues.malHizmetTable,
                not: p.not ?? initialValues.not,
                belgeNumarasi: p.belgeNumarasi ?? initialValues.belgeNumarasi,
              });
            }
          },
          onError(message, payload) {
            message && toaster.push(<Notify header={message} type={"error"} />);
            onClose();
          },
        })
      );
    } else if (data?.id) {
      dispatch(
        getTemplate({
          params: { id: data.id },
          onSuccess(payload) {
            if (payload) {
              formikRef.current?.setValues({
                ...initialValues,
                paraBirimi: payload.currency ?? initialValues.paraBirimi,
                faturaTarihi:
                  dayjs().format("DD/MM/YYYY") ?? initialValues.faturaTarihi,
                faturaTipi: payload.invoice_type ?? initialValues.faturaTipi,
                saat: dayjs().format("HH:mm:ss") ?? initialValues.saat,
                vknTckn: payload.tax_number ?? initialValues.vknTckn,
                vergiDairesi: payload.tax_office ?? initialValues.vergiDairesi,
                aliciUnvan: payload.buyer_title ?? initialValues.aliciUnvan,
                aliciAdi: payload.buyer_name ?? initialValues.aliciAdi,
                aliciSoyadi: payload.buyer_surname ?? initialValues.aliciSoyadi,
                sehir: payload.buyer_city ?? initialValues.sehir,
                ulke: payload.buyer_country ?? initialValues.ulke,
                bulvarcaddesokak:
                  payload.buyer_address ?? initialValues.bulvarcaddesokak,
                malHizmetTable: [
                  {
                    ...initialValues.malHizmetTable[0],
                    malHizmet: payload.item_service_name,
                    birim: payload.item_service_unit,
                    kdvOrani: parseFloat(payload.item_service_vat ?? 0),
                    iskontoArttm:
                      payload.item_service_discount_type ??
                      initialValues.malHizmetTable[0].iskontoArttm,
                    iskontoOrani: parseFloat(
                      payload.item_service_discount ?? 0
                    ),
                    iskontoNedeni: payload.item_service_discount_reason,
                  },
                ],
                not: payload.note ?? initialValues.not,
              });
            }
          },
          onError(message, payload) {
            message && toaster.push(<Notify header={message} type={"error"} />);
            onClose();
          },
        })
      );
    } else {
      formikRef.current?.setValues({
        ...initialValues,
        faturaTarihi: dayjs().format("DD/MM/YYYY"),
        saat: dayjs().format("HH:mm:ss"),
      });
    }
  };
  const onClose = () => {
    close();
    setHtml({ __html: "" });
    formikRef.current?.setValues(initialValues);
    formikRef.current?.resetForm();
  };
  const onSubmit = (v: typeof initialValues) => {
    let params: SetGibInvoiceP = {
      date: dayjs(v.faturaTarihi, "DD/MM/YYYY").format("YYYY-MM-DD"),
      time: v.saat,
      currency: v.paraBirimi,
      currency_rate: String(v.dovzTLkur),
      invoice_type: v.faturaTipi,
      tax_number: v.vknTckn,
      tax_office: v.vergiDairesi ?? "",
      buyer_title: v.aliciUnvan ?? "",
      buyer_name: v.aliciAdi,
      buyer_surname: v.aliciSoyadi,
      buyer_district: v.mahalleSemtIlce,
      buyer_city: v.sehir,
      buyer_country: v.ulke,
      buyer_address: v.bulvarcaddesokak,
      note: v.not,
      services:
        v.malHizmetTable.map((t) => ({
          name: t.malHizmet,
          amount: String(t.miktar),
          unit: t.birim,
          unit_price: String(t.birimFiyat),
          vat_percent: String(t.kdvOrani),
          discount_type: t.iskontoArttm,
          discount_percent: String(t.iskontoOrani),
          discount_reason: t.iskontoNedeni,
        })) || [],
    };
    if (data?.uuid) {
      params.uuid = data?.uuid;
      params.document_number = v.belgeNumarasi;
    }
    dispatch(
      setGibInvoice({
        params,
        onSuccess: (message) => {
          message && toaster.push(<Notify header={message} type={"success"} />);
          onClose();
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };

  const onPreviewClose = useCallback(() => {
    setPreview(false);
  }, []);
  const handleDeleteClose = useCallback(() => {
    setDeleteModal(false);
  }, []);

  const onDeletePress = () => {
    if (data?.uuid) {
      dispatch(
        deleteGibInvoice({
          params: { uuid: data.uuid },
          onSuccess(message) {
            message &&
              toaster.push(<Notify header={message} type={"success"} />);
            handleDeleteClose();
            onClose();
          },
          onError(message, payload) {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
    }
  };

  const downloadInvoice = () => {
    if (data?.uuid)
      dispatch(
        downloadGibInvoice({
          params: { uuid: data.uuid },
          onError(message, payload) {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
  };
  const getExchangeRate = (currency: string, date: string) => () => {
    dispatch(
      getGibInvoiceExchangeRate({
        params: { currency, date },
        onSuccess(payload) {
          formikRef.current?.setFieldValue("dovzTLkur", payload ?? 0);
        },
        onError(message, payload) {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const onTemplateClose = () => setTemplateModal(false);
  const onSubmitTemplate = (v: typeof initialTemplateValues) => {
    if (data?.uuid) {
      let params: CreateTemplateP = {
        name: v.name,
        uuid: data?.uuid,
      };
      dispatch(
        createTemplate({
          params,
          onSuccess: (message) => {
            message &&
              toaster.push(<Notify header={message} type={"success"} />);
            onTemplateClose();
          },
          onError: (message) => {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
    }
  };

  return (
    <>
      <Modal
        backdrop="static"
        open={data !== undefined}
        onOpen={onOpen}
        onClose={onClose}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title style={{ fontWeight: "bolder" }}>
            {strings.getString("gib_invoice_title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Panel bordered>
            <Formik
              innerRef={formikRef}
              validationSchema={SCHEMA}
              initialValues={initialValues}
              onSubmit={(values, actions) => {
                onSubmit(values);
              }}
            >
              {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form fluid onSubmit={(s, e) => handleSubmit(e)}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Group style={{ flex: 1 }}>
                      <Form.ControlLabel>
                        {strings.getString("gib_invoice_type")}
                      </Form.ControlLabel>
                      <SelectPicker
                        block
                        readOnly={readOnly}
                        placement="bottomStart"
                        value={values.faturaTipi}
                        labelKey="label"
                        valueKey="key"
                        onChange={(v) =>
                          v
                            ? handleChange("faturaTipi")(v)
                            : handleChange("faturaTipi")("")
                        }
                        data={GibInvoiceTypes}
                      />
                    </Form.Group>
                    <Form.Group style={{ marginLeft: 10 }}>
                      <Form.ControlLabel>
                        {strings.getString("gib_invoice_date")}
                      </Form.ControlLabel>
                      <DatePicker
                        readOnly={readOnly}
                        size="md"
                        placeholder="Large"
                        onChange={(date) => {
                          setFieldValue(
                            "faturaTarihi",
                            dayjs(
                              (date ?? new Date()).toISOString().split("T")[0]
                            ).format("DD/MM/YYYY")
                          );
                        }}
                        value={
                          values.faturaTarihi
                            ? dayjs(values.faturaTarihi, "DD/MM/YYYY").toDate()
                            : new Date()
                        }
                      />
                    </Form.Group>
                    <Form.Group style={{ marginLeft: 10 }}>
                      <Form.ControlLabel>
                        {strings.getString("gib_invoice_time")}
                      </Form.ControlLabel>
                      <DatePicker
                        readOnly={readOnly}
                        size="md"
                        placeholder="Large"
                        format="HH:mm:ss"
                        onChange={(date) => {
                          setFieldValue(
                            "saat",
                            dayjs(date ?? new Date()).format("HH:mm:ss")
                          );
                        }}
                        value={
                          values.saat
                            ? dayjs(values.saat, "HH:mm:ss").toDate()
                            : new Date()
                        }
                      />
                    </Form.Group>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Group style={{ flex: 1, marginRight: 10 }}>
                      <Form.ControlLabel>
                        {strings.getString("gib_invoice_currency")}
                      </Form.ControlLabel>
                      <SelectPicker
                        block
                        readOnly={readOnly}
                        placement="bottomStart"
                        value={values.paraBirimi}
                        labelKey="label"
                        valueKey="key"
                        onChange={(v) =>
                          v
                            ? handleChange("paraBirimi")(v)
                            : handleChange("paraBirimi")("")
                        }
                        data={GibCurrencies}
                      />
                    </Form.Group>
                    <Form.Group style={{ flex: 1 }}>
                      <Form.ControlLabel>
                        {strings.getString("gib_invoice_currency_rate")}
                      </Form.ControlLabel>
                      <InputGroup>
                        <InputNumber
                          readOnly={readOnly}
                          name="dovzTLkur"
                          onChange={(t) => handleChange("dovzTLkur")(String(t))}
                          onBlur={handleBlur("dovzTLkur")}
                          value={values.dovzTLkur}
                          prefix={"₺"}
                          defaultValue={0}
                          min={0}
                        />
                        {values.paraBirimi !== "" && (
                          <Button
                            onClick={getExchangeRate(
                              values.paraBirimi,
                              dayjs(values.faturaTarihi, "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                              )
                            )}
                          >
                            ₺
                          </Button>
                        )}
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <Form.Group>
                    <Form.ControlLabel>
                      {strings.getString("gib_invoice_tax_number")}
                    </Form.ControlLabel>
                    <Form.Control
                      readOnly={readOnly}
                      name="vknTckn"
                      onChange={handleChange("vknTckn")}
                      onBlur={handleBlur("vknTckn")}
                      value={values.vknTckn}
                      errorMessage={touched.vknTckn && errors.vknTckn}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.ControlLabel>
                      {strings.getString("gib_invoice_tax_office")}
                    </Form.ControlLabel>
                    <Form.Control
                      readOnly={readOnly}
                      name="vergiDairesi"
                      onChange={handleChange("vergiDairesi")}
                      onBlur={handleBlur("vergiDairesi")}
                      value={values.vergiDairesi}
                      errorMessage={touched.vergiDairesi && errors.vergiDairesi}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.ControlLabel>
                      {strings.getString("gib_invoice_buyer_title")}
                    </Form.ControlLabel>
                    <Form.Control
                      readOnly={readOnly}
                      name="aliciUnvan"
                      onChange={handleChange("aliciUnvan")}
                      onBlur={handleBlur("aliciUnvan")}
                      value={values.aliciUnvan}
                      errorMessage={touched.aliciUnvan && errors.aliciUnvan}
                    />
                  </Form.Group>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Group style={{ flex: 1, marginRight: 10 }}>
                      <Form.ControlLabel>
                        {strings.getString("gib_invoice_buyer_name")}
                      </Form.ControlLabel>
                      <Form.Control
                        readOnly={readOnly}
                        name="aliciAdi"
                        onChange={handleChange("aliciAdi")}
                        onBlur={handleBlur("aliciAdi")}
                        value={values.aliciAdi}
                        errorMessage={touched.aliciAdi && errors.aliciAdi}
                      />
                    </Form.Group>
                    <Form.Group style={{ flex: 1 }}>
                      <Form.ControlLabel>
                        {strings.getString("gib_invoice_buyer_surname")}
                      </Form.ControlLabel>
                      <Form.Control
                        readOnly={readOnly}
                        name="aliciSoyadi"
                        onChange={handleChange("aliciSoyadi")}
                        onBlur={handleBlur("aliciSoyadi")}
                        value={values.aliciSoyadi}
                        errorMessage={touched.aliciSoyadi && errors.aliciSoyadi}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Group style={{ flex: 1, marginRight: 10 }}>
                      <Form.ControlLabel>
                        {strings.getString("gib_invoice_buyer_district")}
                      </Form.ControlLabel>
                      <Form.Control
                        readOnly={readOnly}
                        name="mahalleSemtIlce"
                        onChange={handleChange("mahalleSemtIlce")}
                        onBlur={handleBlur("mahalleSemtIlce")}
                        value={values.mahalleSemtIlce}
                        errorMessage={
                          touched.mahalleSemtIlce && errors.mahalleSemtIlce
                        }
                      />
                    </Form.Group>
                    <Form.Group style={{ flex: 1, marginRight: 10 }}>
                      <Form.ControlLabel>
                        {strings.getString("gib_invoice_buyer_city")}
                      </Form.ControlLabel>
                      <Form.Control
                        readOnly={readOnly}
                        name="sehir"
                        onChange={handleChange("sehir")}
                        onBlur={handleBlur("sehir")}
                        value={values.sehir}
                        errorMessage={touched.sehir && errors.sehir}
                      />
                    </Form.Group>
                    <Form.Group style={{ flex: 1 }}>
                      <Form.ControlLabel>
                        {strings.getString("gib_invoice_buyer_country")}
                      </Form.ControlLabel>
                      <Form.Control
                        readOnly={readOnly}
                        name="ulke"
                        onChange={handleChange("ulke")}
                        onBlur={handleBlur("ulke")}
                        value={values.ulke}
                        errorMessage={touched.ulke && errors.ulke}
                      />
                    </Form.Group>
                  </div>
                  <Form.Group>
                    <Form.ControlLabel>
                      {strings.getString("gib_invoice_buyer_address")}
                    </Form.ControlLabel>
                    <Form.Control
                      readOnly={readOnly}
                      name="bulvarcaddesokak"
                      onChange={handleChange("bulvarcaddesokak")}
                      onBlur={handleBlur("bulvarcaddesokak")}
                      value={values.bulvarcaddesokak}
                      errorMessage={
                        touched.bulvarcaddesokak && errors.bulvarcaddesokak
                      }
                    />
                  </Form.Group>
                  <Panel bordered>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5>{strings.getString("gib_invoice_service_title")}</h5>
                      <IconButton
                        icon={<Plus />}
                        style={{ marginBottom: "1rem" }}
                        onClick={() => {
                          setFieldValue("malHizmetTable", [
                            ...values.malHizmetTable,
                            initialValues.malHizmetTable[0],
                          ]);
                        }}
                      />
                    </div>
                    {values.malHizmetTable.map((t, i) => (
                      <Panel bordered key={i} style={{ marginBottom: "1rem" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="panel-header-title">{`#${
                              i + 1
                            }`}</span>
                            {i !== 0 && (
                              <IconButton
                                color="red"
                                icon={<Trash />}
                                style={{ marginBottom: "1rem" }}
                                onClick={() => {
                                  setFieldValue("malHizmetTable", [
                                    values.malHizmetTable.filter(
                                      (t, j) => i !== j
                                    ),
                                  ]);
                                }}
                              />
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                              marginLeft: "1rem",
                            }}
                          >
                            <Form.Group>
                              <Form.ControlLabel>
                                {strings.getString("gib_invoice_service_name")}
                              </Form.ControlLabel>
                              <Form.Control
                                readOnly={readOnly}
                                name={`malHizmetTable[${i}]malHizmet`}
                                onChange={handleChange(
                                  `malHizmetTable[${i}]malHizmet`
                                )}
                                onBlur={handleBlur(
                                  `malHizmetTable[${i}]malHizmet`
                                )}
                                value={values.malHizmetTable[i].malHizmet}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.ControlLabel>
                                {strings.getString(
                                  "gib_invoice_service_amount"
                                )}
                              </Form.ControlLabel>
                              <InputNumber
                                readOnly={readOnly}
                                name={`malHizmetTable[${i}]miktar`}
                                onChange={(t) =>
                                  handleChange(`malHizmetTable[${i}]miktar`)(
                                    String(t)
                                  )
                                }
                                onBlur={handleBlur(
                                  `malHizmetTable[${i}]miktar`
                                )}
                                value={values.malHizmetTable[i].miktar}
                                defaultValue={1}
                                min={1}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.ControlLabel>
                                {strings.getString("gib_invoice_service_unit")}
                              </Form.ControlLabel>
                              <SelectPicker
                                block
                                readOnly={readOnly}
                                placement="bottomStart"
                                value={values.malHizmetTable[i].birim}
                                labelKey="label"
                                valueKey="key"
                                onChange={(v) =>
                                  v
                                    ? handleChange(`malHizmetTable[${i}]birim`)(
                                        v
                                      )
                                    : handleChange(`malHizmetTable[${i}]birim`)(
                                        ""
                                      )
                                }
                                data={GibUnitTypes}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.ControlLabel>
                                {strings.getString(
                                  "gib_invoice_service_unit_price"
                                )}
                              </Form.ControlLabel>
                              <InputNumber
                                readOnly={readOnly}
                                name={`malHizmetTable[${i}]birimFiyat`}
                                onChange={(t) =>
                                  handleChange(
                                    `malHizmetTable[${i}]birimFiyat`
                                  )(String(t))
                                }
                                onBlur={handleBlur(
                                  `malHizmetTable[${i}]birimFiyat`
                                )}
                                value={values.malHizmetTable[i].birimFiyat}
                                prefix={getCurrencySymbol(values.paraBirimi)}
                                defaultValue={0}
                                min={0}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.ControlLabel>
                                {strings.getString(
                                  "gib_invoice_service_vat_percent"
                                )}
                              </Form.ControlLabel>
                              <InputNumber
                                readOnly={readOnly}
                                name={`malHizmetTable[${i}]kdvOrani`}
                                onChange={(t) =>
                                  handleChange(`malHizmetTable[${i}]kdvOrani`)(
                                    String(t)
                                  )
                                }
                                onBlur={handleBlur(
                                  `malHizmetTable[${i}]kdvOrani`
                                )}
                                value={values.malHizmetTable[i].kdvOrani}
                                prefix={"%"}
                                defaultValue={0}
                                min={0}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.ControlLabel>
                                {strings.getString(
                                  "gib_invoice_service_discount_percent"
                                )}
                              </Form.ControlLabel>
                              <SelectPicker
                                block
                                placement="bottomStart"
                                value={values.malHizmetTable[i].iskontoArttm}
                                labelKey="label"
                                valueKey="key"
                                onChange={(v) =>
                                  v
                                    ? handleChange(
                                        `malHizmetTable[${i}]iskontoArttm`
                                      )(v)
                                    : handleChange(
                                        `malHizmetTable[${i}]iskontoArttm`
                                      )("")
                                }
                                data={[
                                  { key: "İskonto", label: "İskonto" },
                                  { key: "Arttırım", label: "Arttırım" },
                                ]}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.ControlLabel>
                                {strings.getString(
                                  "gib_invoice_service_discount_percent"
                                )}
                              </Form.ControlLabel>
                              <InputNumber
                                readOnly={readOnly}
                                name={`malHizmetTable[${i}]iskontoOrani`}
                                onChange={(t) =>
                                  handleChange(
                                    `malHizmetTable[${i}]iskontoOrani`
                                  )(String(t))
                                }
                                onBlur={handleBlur(
                                  `malHizmetTable[${i}]iskontoOrani`
                                )}
                                value={values.malHizmetTable[i].iskontoOrani}
                                prefix={"%"}
                                defaultValue={0}
                                min={0}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.ControlLabel>
                                {strings.getString(
                                  "gib_invoice_service_discount_reason"
                                )}
                              </Form.ControlLabel>
                              <Form.Control
                                readOnly={readOnly}
                                name={`malHizmetTable[${i}]iskontoNedeni`}
                                onChange={handleChange(
                                  `malHizmetTable[${i}]iskontoNedeni`
                                )}
                                onBlur={handleBlur(
                                  `malHizmetTable[${i}]iskontoNedeni`
                                )}
                                value={values.malHizmetTable[i].iskontoNedeni}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </Panel>
                    ))}
                  </Panel>
                  <Form.Group style={{ marginTop: "1rem" }}>
                    <Form.ControlLabel>
                      {strings.getString("gib_invoice_note")}
                    </Form.ControlLabel>
                    <Input
                      name="not"
                      readOnly={readOnly}
                      onChange={handleChange("not")}
                      onBlur={handleBlur("not")}
                      value={values.not}
                      as={"textarea"}
                      style={{ minHeight: "5rem" }}
                    />
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Panel>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              alignSelf: "stretch",
              justifyContent: "space-between",
            }}
          >
            <div>
              {html.__html !== "" && (
                <Button onClick={() => setPreview(true)} appearance="primary">
                  {strings.getString("gib_invoice_preview")}
                </Button>
              )}
              {data?.uuid && readOnly && (
                <Button onClick={downloadInvoice} appearance="default">
                  {strings.getString("gib_invoice_download")}
                </Button>
              )}
              {!readOnly && data?.uuid && (
                <Button
                  onClick={() => setDeleteModal(true)}
                  color="red"
                  appearance="subtle"
                >
                  {strings.getString("gib_invoice_delete")}
                </Button>
              )}
            </div>
            {readOnly && (
              <Button
                onClick={() => setTemplateModal(true)}
                appearance="subtle"
              >
                {strings.getString("gib_invoice_template_create")}
              </Button>
            )}
            <div>
              {!readOnly && (
                <Button
                  onClick={() => formikRef.current?.submitForm()}
                  appearance="primary"
                >
                  {strings.getString("gib_invoice_save")}
                </Button>
              )}
              <Button onClick={onClose} appearance="subtle">
                {strings.getString("cancel")}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal open={preview} onClose={onPreviewClose} size="lg">
        <Modal.Header>
          <Modal.Title style={{ fontWeight: "bolder" }}>
            {strings.getString("gib_invoice_title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          <div dangerouslySetInnerHTML={html} />
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              alignSelf: "stretch",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={onPreviewClose} appearance="subtle">
              {strings.getString("cancel")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal open={template_modal} onClose={onTemplateClose} size="xs">
        <Modal.Header>
          <Modal.Title style={{ fontWeight: "bolder" }}>
            {strings.getString("gib_invoice_template")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Panel bordered style={{ paddingBottom: 10, width: "100%" }}>
            <Formik
              innerRef={formikTemplateRef}
              validationSchema={SCHEMA_TEMPLATE}
              initialValues={initialTemplateValues}
              onSubmit={(values, actions) => {
                onSubmitTemplate(values);
              }}
            >
              {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form fluid onSubmit={(s, e) => handleSubmit(e)}>
                  <Form.Group>
                    <Form.ControlLabel>
                      {strings.getString("gib_invoice_template_name")}
                    </Form.ControlLabel>
                    <Form.Control
                      name="name"
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                      value={values.name}
                      errorMessage={touched.name && errors.name}
                    />
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Panel>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              alignSelf: "stretch",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => formikTemplateRef.current?.submitForm()}
              appearance="primary"
            >
              {strings.getString("gib_invoice_template_save")}
            </Button>
            <Button onClick={onTemplateClose} appearance="subtle">
              {strings.getString("cancel")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        backdrop="static"
        role="alertdialog"
        open={delete_modal}
        onClose={handleDeleteClose}
        size="xs"
      >
        <Modal.Body>
          <RemindOutline
            style={{ color: "#ffb300", fontSize: 24, marginRight: 5 }}
          />
          {strings.getString("gib_invoice_delete_message")}
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              alignSelf: "stretch",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={onDeletePress} appearance="primary" color="red">
              {strings.getString("gib_invoice_delete_confirm")}
            </Button>
            <Button onClick={handleDeleteClose} appearance="subtle">
              {strings.getString("cancel")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Detail;
