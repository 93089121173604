import { FC, useState } from "react";
import { isChrome, isFirefox, isIOS, isOpera } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Panel, toaster } from "rsuite";
import { Button, Notify, View } from "../../../../components";
import { downloadDocument } from "../../../../redux/actions";
import { getStringSelector } from "../../../../redux/locale";
import { DocumentObject } from "../../../../redux/types";
import { downloadFile } from "../../../../utils";

interface Props {
  doc?: DocumentObject;
  type: "income" | "expense";
  close: () => void;
}
const Preview: FC<Props> = ({ doc, type, close }) => {
  const strings = useSelector(getStringSelector);

  const dispatch = useDispatch();
  const [display_in, setDisplayIn] = useState<"iframe" | "img">();
  const [prev_blob, setPrevBlob] = useState<Blob>();
  const [prev_href, setPrevHref] = useState<string>();

  const onClose = () => {
    setPrevBlob(undefined);
    if (prev_href) URL.revokeObjectURL(prev_href);
    setPrevHref(undefined);
    close();
  };

  const onOpen = () => {
    if (doc)
      dispatch(
        downloadDocument({
          params: {
            id: doc?.id,
            type,
            name: doc.url.split("/").pop() ?? "file.pdf",
            download: false,
          },
          onSuccess: (payload) => {
            if (payload?.file) {
              setPrevBlob(payload?.file);
              setPrevHref(URL.createObjectURL(payload?.file));
              setDisplayIn(
                payload.message.split(".").pop() === "pdf" ? "iframe" : "img"
              );
            }
          },
          onError: (message) => {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
  };

  const onDownloadClick = () => {
    if (doc && prev_blob)
      downloadFile(prev_blob, doc.url.split("/").pop() ?? "file.pdf");
  };

  return (
    <Modal open={doc !== undefined} size="lg" onClose={onClose} onOpen={onOpen}>
      <Modal.Header>
        <Modal.Title style={{ fontWeight: "bolder" }}>
          {strings.getString("documents_preview")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Panel
          bordered
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {display_in === "iframe" &&
            prev_href &&
            (isChrome || isOpera || isFirefox ? (
              <object
                data={doc?.url + "#toolbar=0&navpanes=0&scrollbar=0"}
                type="application/pdf"
                style={{
                  width: "45vw",
                  height: "70vh",
                }}
              >
                PDF
              </object>
            ) : (
              <embed
                onClick={() => {
                  if (isIOS && doc?.url) window.open(doc.url, "_blank");
                }}
                src={isIOS && doc ? doc.url : prev_href}
                type="application/pdf"
                style={{
                  width: "45vw",
                  height: "70vh",
                }}
              />
            ))}
          {display_in === "img" && prev_href && (
            <img
              src={prev_href}
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </Panel>
      </Modal.Body>
      <Modal.Footer
        as={View}
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <View
          style={{
            marginTop: 10,
            flexDirection: "row",
            alignSelf: "flex-end",
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={onDownloadClick} appearance="primary">
            {strings.getString("documents_download")}
          </Button>
          <Button onClick={onClose} appearance="subtle">
            {strings.getString("cancel")}
          </Button>
        </View>
      </Modal.Footer>
    </Modal>
  );
};

export default Preview;
