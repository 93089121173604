import { useEffect, useState } from "react";

function getDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  const isDesktop = width >= 1200;
  const isTablet = width >= 768 && !isDesktop;
  const isPhone = width < 768;
  const isLandscape = width > height;
  return {
    width,
    height,
    isPhone,
    isTablet,
    isDesktop,
    isLandscape,
  };
}

export function useDimensions() {
  const [dimensions, setDimensions] = useState(getDimensions());

  useEffect(() => {
    function handleResize() {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return dimensions;
}
