import { Formik, FormikProps } from "formik";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel, toaster } from "rsuite";
import * as yup from "yup";
import { Button, Divider, Form, Notify } from "../../../components";
import { getExchangeRates, updateCurrencies } from "../../../redux/actions";
import { getStringSelector } from "../../../redux/locale";
import { TcmbCurrencyObject } from "../../../redux/types";
import { getCurrenciesSelector, getUserSelector } from "../../../redux/user";

const initialCurrencyValues = {
  eur: "",
  usd: "",
  gbp: "",
};
function Currency() {
  const strings = useSelector(getStringSelector);
  const user = useSelector(getUserSelector);
  const currencies = useSelector(getCurrenciesSelector);
  const dispatch = useDispatch();
  const formikCurrencyRef =
    useRef<FormikProps<typeof initialCurrencyValues>>(null);
  const SCHEMA_CURRENCY = yup.object().shape({
    eur: yup.string().required(strings.getString("hint_required")),
    usd: yup.string().required(strings.getString("hint_required")),
    gbp: yup.string().required(strings.getString("hint_required")),
  });
  useEffect(() => {
    let cEUR = "";
    let cUSD = "";
    let cGBP = "";
    currencies.forEach((c) => {
      if (c.code === "eur") cEUR = String(c.value);
      if (c.code === "usd") cUSD = String(c.value);
      if (c.code === "gbp") cGBP = String(c.value);
    });
    formikCurrencyRef.current?.setValues({ eur: cEUR, usd: cUSD, gbp: cGBP });
  }, []);
  const getMidMarketPrice = (e?: TcmbCurrencyObject): string => {
    try {
      if (!e) return "";
      return String(Number(((e.buy + e.sell) * 0.5).toFixed(2)));
    } catch (e) {
      //console.log("ERROR getMidMarketPrice", e);
      return "";
    }
  };
  const getRates = () => {
    dispatch(
      getExchangeRates({
        onSuccess(payload) {
          if (payload)
            formikCurrencyRef.current?.setValues({
              eur: getMidMarketPrice(payload["EUR"]),
              usd: getMidMarketPrice(payload["USD"]),
              gbp: getMidMarketPrice(payload["GBP"]),
            });
        },
        onError(message, payload) {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const onCurrencySubmit = (v: typeof initialCurrencyValues) => {
    dispatch(
      updateCurrencies({
        params: { eur: String(v.eur), usd: String(v.usd), gbp: String(v.gbp) },
        onSuccess: (message) => {
          message && toaster.push(<Notify header={message} type={"success"} />);
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  return (
    <Panel
      header={
        <span className="panel-header-title">
          {strings.getString("currency_title")}
        </span>
      }
      bordered
      className="settings-panel"
    >
      <Divider />
      <Formik
        key="currency"
        innerRef={formikCurrencyRef}
        validationSchema={SCHEMA_CURRENCY}
        initialValues={initialCurrencyValues}
        onSubmit={(values, actions) => {
          onCurrencySubmit(values);
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm,
        }) => (
          <Form fluid onSubmit={(s, e) => handleSubmit(e)}>
            <input type="email" hidden value={user?.username ?? ""} />
            <Form.Group>
              <Form.ControlLabel>{strings.getString("eur")}</Form.ControlLabel>
              <Form.Control
                name="eur"
                type="number"
                onChange={handleChange("eur")}
                onBlur={handleBlur("eur")}
                value={values.eur}
                errorMessage={touched.eur && errors.eur}
              />
            </Form.Group>
            <Form.Group style={{ marginTop: 20 }}>
              <Form.ControlLabel>{strings.getString("usd")}</Form.ControlLabel>
              <Form.Control
                name="usd"
                type="number"
                onChange={handleChange("usd")}
                onBlur={handleBlur("usd")}
                value={values.usd}
                errorMessage={touched.usd && errors.usd}
              />
            </Form.Group>
            <Form.Group style={{ marginTop: 20 }}>
              <Form.ControlLabel>{strings.getString("gbp")}</Form.ControlLabel>
              <Form.Control
                name="gbp"
                type="number"
                onChange={handleChange("gbp")}
                onBlur={handleBlur("gbp")}
                value={values.gbp}
                errorMessage={touched.gbp && errors.gbp}
              />
            </Form.Group>
            <Button
              onClick={getRates}
              appearance="ghost"
              style={{ marginRight: 20 }}
            >
              {strings.getString("fetch_exchange_rates")}
            </Button>
            <Button onClick={submitForm} appearance="primary">
              {strings.getString("save_changes")}
            </Button>
          </Form>
        )}
      </Formik>
    </Panel>
  );
}
export default Currency;
