import { Formik, FormikProps } from "formik";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Panel, toaster } from "rsuite";
import * as yup from "yup";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Notify,
  SelectPicker,
} from "../../../../components";
import { setTemplate } from "../../../../redux/actions";
import { getThemeSelector } from "../../../../redux/app";
import { getStringSelector } from "../../../../redux/locale";
import { GibTemplateObject, SetTemplateP } from "../../../../redux/types";
import {
  GibCurrencies,
  GibInvoiceTypes,
  GibUnitTypes,
} from "../../../../utils";

const initialValues: SetTemplateP = {
  name: "",
  currency: "",
  invoice_type: "",
  tax_number: "",
  tax_office: "",
  buyer_title: "",
  buyer_name: "",
  buyer_surname: "",
  buyer_city: "",
  buyer_country: "",
  note: "",
  item_service_name: "",
  item_service_unit: "",
  item_service_vat: "",
  item_service_discount: "",
  item_service_discount_type: "",
  item_service_discount_reason: "",
};
interface Props {
  data?: GibTemplateObject;
  close: () => void;
}
function Edit({ data, close }: Props) {
  const strings = useSelector(getStringSelector);
  const theme = useSelector(getThemeSelector);
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const SCHEMA = yup.object().shape({
    name: yup.string().required(strings.getString("hint_required")),
    currency: yup.string(),
    invoice_type: yup.string(),
    tax_number: yup.string(),
    tax_office: yup.string(),
    buyer_title: yup.string(),
    buyer_name: yup.string(),
    buyer_surname: yup.string(),
    buyer_city: yup.string(),
    buyer_country: yup.string(),
    note: yup.string(),
    item_service_name: yup.string(),
    item_service_unit: yup.string(),
    item_service_vat: yup.string(),
    item_service_discount: yup.string(),
    item_service_discount_type: yup.string(),
    item_service_discount_reason: yup.string(),
  });
  const onOpen = () => {
    formikRef.current?.setValues({
      name: data?.name ?? initialValues.name,
      currency: data?.currency ?? initialValues.currency,
      invoice_type: data?.invoice_type ?? initialValues.invoice_type,
      tax_number: data?.tax_number ?? initialValues.tax_number,
      tax_office: data?.tax_office ?? initialValues.tax_office,
      buyer_title: data?.buyer_title ?? initialValues.buyer_title,
      buyer_name: data?.buyer_name ?? initialValues.buyer_name,
      buyer_surname: data?.buyer_surname ?? initialValues.buyer_surname,
      buyer_city: data?.buyer_city ?? initialValues.buyer_city,
      buyer_country: data?.buyer_country ?? initialValues.buyer_country,
      note: data?.note ?? initialValues.note,
      item_service_name:
        data?.item_service_name ?? initialValues.item_service_name,
      item_service_unit:
        data?.item_service_unit ?? initialValues.item_service_unit,
      item_service_vat:
        data?.item_service_vat ?? initialValues.item_service_vat,
      item_service_discount:
        data?.item_service_discount ?? initialValues.item_service_discount,
      item_service_discount_type:
        data?.item_service_discount_type ??
        initialValues.item_service_discount_type,
      item_service_discount_reason:
        data?.item_service_discount_reason ??
        initialValues.item_service_discount_reason,
    });
  };
  const onClose = () => {
    close();
    formikRef.current?.setValues(initialValues);
    formikRef.current?.resetForm();
  };
  const onSubmit = (v: typeof initialValues) => {
    let params: SetTemplateP = v;
    if (data?.id) {
      params.id = data.id;
    }
    dispatch(
      setTemplate({
        params,
        onSuccess: (message) => {
          message && toaster.push(<Notify header={message} type={"success"} />);
          onClose();
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  return (
    <Modal
      backdrop="static"
      open={data !== undefined}
      onOpen={onOpen}
      onClose={onClose}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title style={{ fontWeight: "bolder" }}>
          {strings.getString("template_edit")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Panel bordered>
          <Formik
            innerRef={formikRef}
            validationSchema={SCHEMA}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              onSubmit(values);
            }}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form fluid onSubmit={(s, e) => handleSubmit(e)}>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_name")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="name"
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    value={values.name}
                    errorMessage={touched.name && errors.name}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_currency")}
                  </Form.ControlLabel>
                  <SelectPicker
                    block
                    placement="bottomStart"
                    value={values.currency}
                    labelKey="label"
                    valueKey="key"
                    onChange={(v) =>
                      v
                        ? handleChange("currency")(v)
                        : handleChange("currency")("")
                    }
                    data={GibCurrencies}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_invoice_type")}
                  </Form.ControlLabel>
                  <SelectPicker
                    block
                    placement="bottomStart"
                    value={values.invoice_type}
                    labelKey="label"
                    valueKey="key"
                    onChange={(v) =>
                      v
                        ? handleChange("invoice_type")(v)
                        : handleChange("invoice_type")("")
                    }
                    data={GibInvoiceTypes}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_tax_number")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="tax_number"
                    onChange={handleChange("tax_number")}
                    onBlur={handleBlur("tax_number")}
                    value={values.tax_number}
                    errorMessage={touched.tax_number && errors.tax_number}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_tax_office")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="tax_office"
                    onChange={handleChange("tax_office")}
                    onBlur={handleBlur("tax_office")}
                    value={values.tax_office}
                    errorMessage={touched.tax_office && errors.tax_office}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_buyer_title")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="buyer_title"
                    onChange={handleChange("buyer_title")}
                    onBlur={handleBlur("buyer_title")}
                    value={values.buyer_title}
                    errorMessage={touched.buyer_title && errors.buyer_title}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_buyer_name")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="buyer_name"
                    onChange={handleChange("buyer_name")}
                    onBlur={handleBlur("buyer_name")}
                    value={values.buyer_name}
                    errorMessage={touched.buyer_name && errors.buyer_name}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_buyer_surname")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="buyer_surname"
                    onChange={handleChange("buyer_surname")}
                    onBlur={handleBlur("buyer_surname")}
                    value={values.buyer_surname}
                    errorMessage={touched.buyer_surname && errors.buyer_surname}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_buyer_city")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="buyer_city"
                    onChange={handleChange("buyer_city")}
                    onBlur={handleBlur("buyer_city")}
                    value={values.buyer_city}
                    errorMessage={touched.buyer_city && errors.buyer_city}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_buyer_country")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="buyer_country"
                    onChange={handleChange("buyer_country")}
                    onBlur={handleBlur("buyer_country")}
                    value={values.buyer_country}
                    errorMessage={touched.buyer_country && errors.buyer_country}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_note")}
                  </Form.ControlLabel>
                  <Input
                    name="note"
                    onChange={handleChange("note")}
                    onBlur={handleBlur("note")}
                    value={values.note}
                    as={"textarea"}
                    style={{ minHeight: "5rem" }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_item_service_name")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="item_service_name"
                    onChange={handleChange("item_service_name")}
                    onBlur={handleBlur("item_service_name")}
                    value={values.item_service_name}
                    errorMessage={
                      touched.item_service_name && errors.item_service_name
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_item_service_unit")}
                  </Form.ControlLabel>
                  <SelectPicker
                    block
                    placement="bottomStart"
                    value={values.item_service_unit}
                    labelKey="label"
                    valueKey="key"
                    onChange={(v) =>
                      v
                        ? handleChange("item_service_unit")(v)
                        : handleChange("item_service_unit")("")
                    }
                    data={GibUnitTypes}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_item_service_vat")}
                  </Form.ControlLabel>
                  <InputNumber
                    name="item_service_vat"
                    onChange={(t) =>
                      handleChange("item_service_vat")(String(t))
                    }
                    onBlur={handleBlur("item_service_vat")}
                    value={values.item_service_vat}
                    prefix={"%"}
                    defaultValue={0}
                    min={0}
                    max={100}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_item_service_discount")}
                  </Form.ControlLabel>
                  <InputNumber
                    name="item_service_discount"
                    onChange={(t) =>
                      handleChange("item_service_discount")(String(t))
                    }
                    onBlur={handleBlur("item_service_discount")}
                    value={values.item_service_discount}
                    prefix={"%"}
                    defaultValue={0}
                    min={0}
                    max={100}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_item_service_discount_type")}
                  </Form.ControlLabel>
                  <SelectPicker
                    block
                    placement="bottomStart"
                    value={values.item_service_discount_type}
                    labelKey="label"
                    valueKey="key"
                    onChange={(v) =>
                      v
                        ? handleChange("item_service_discount_type")(v)
                        : handleChange("item_service_discount_type")("")
                    }
                    data={[
                      { key: "İskonto", label: "İskonto" },
                      { key: "Arttırım", label: "Arttırım" },
                    ]}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("template_item_service_discount_reason")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="item_service_discount_reason"
                    onChange={handleChange("item_service_discount_reason")}
                    onBlur={handleBlur("item_service_discount_reason")}
                    value={values.item_service_discount_reason}
                    errorMessage={
                      touched.item_service_discount_reason &&
                      errors.item_service_discount_reason
                    }
                  />
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Panel>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: "flex",
            alignSelf: "stretch",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => formikRef.current?.submitForm()}
            appearance="primary"
          >
            {strings.getString("template_save")}
          </Button>
          <Button onClick={onClose} appearance="subtle">
            {strings.getString("cancel")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default Edit;
