import { ArrowDown, ArrowUp } from "@rsuite/icons";
import { FC } from "react";
import { InputNumber } from "../../../../components";

interface Props {
  title: string;
  value: number | string;
  onChange?: (value: string) => void;
  formatter?: (value: number) => string;
  direction?: "up" | "down";
}
const CardField: FC<Props> = ({
  title,
  value,
  direction,
  onChange,
  formatter,
}) => {
  return (
    <div className="card-field">
      <span className="card-field-title">{title}:</span>
      {direction === "up" && (
        <ArrowUp color="green" style={{ fontSize: "2rem" }} />
      )}
      {direction === "down" && (
        <ArrowDown color="red" style={{ fontSize: "2rem" }} />
      )}
      {typeof onChange === "function" ? (
        <InputNumber
          onChange={(e) => onChange(String(e))}
          value={value}
          defaultValue={0}
          min={0}
        />
      ) : (
        <span className="card-field-text">
          {typeof value === "number" ? formatter?.(value) ?? value : value}
        </span>
      )}
    </div>
  );
};

export default CardField;
