import { call, put, select } from "redux-saga/effects";
import { ActionPayload, ApiResponse, PaginationResponse } from "../../../types";
import { downloadFile } from "../../../utils";
import {
  createRequestSaga,
  getErrorMessage,
  objectToFormData,
} from "../../Api";
import { setLoaded, setLoading, setYears } from "../../actions";
import { isAdminSelector } from "../../user";
import * as O from "../types/objects";
import * as P from "../types/params";

export function* getHomeSaga({ payload }: ActionPayload<P.GetHomeA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<P.ResponseHome> = yield call(
      createRequestSaga,
      {
        url: "home",
        method: "GET",
        body: params,
      }
    );
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getLedgerSaga({ payload }: ActionPayload<P.GetLedgerA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<P.ResponseLedger> = yield call(
      createRequestSaga,
      {
        url: "ledger",
        method: "GET",
        body: params,
      }
    );
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getLedgerIncomesSaga({
  payload,
}: ActionPayload<P.GetLedgerIncomesA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<PaginationResponse<O.IncomeObject>> =
      yield call(createRequestSaga, {
        url: "ledger/incomes",
        method: "GET",
        body: params,
      });
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getLedgerExpensesSaga({
  payload,
}: ActionPayload<P.GetLedgerExpensesA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<PaginationResponse<O.ExpenseObject>> =
      yield call(createRequestSaga, {
        url: "ledger/expenses",
        method: "GET",
        body: params,
      });
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* setLedgerIncomeConfirmationSaga({
  payload,
}: ActionPayload<P.LedgerIncomeConfirmationA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      url: "ledger/income-confirmation",
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* setLedgerExpenseConfirmationSaga({
  payload,
}: ActionPayload<P.LedgerExpenseStatusA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      url: "ledger/expense-confirmation",
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}

export function* setTaxBracketSaga({
  payload,
}: ActionPayload<P.SetTaxBracketA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "POST",
      url: `bracket/${params.id ? "edit" : "add"}`,
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* deleteTaxBracketSaga({
  payload,
}: ActionPayload<P.DeleteTaxBracketA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "POST",
      url: "bracket/delete",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getTaxBracketYearsSaga({
  payload,
}: ActionPayload<P.GetTaxBracketYearsA>) {
  const { onSuccess, onError } = payload;
  try {
    const response: ApiResponse<number[]> = yield call(createRequestSaga, {
      url: "bracket/years",
      method: "GET",
    });
    yield put(setYears(response.data || []));
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  }
}
export function* getTaxBracketSaga({
  payload,
}: ActionPayload<P.GetTaxBracketsA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<PaginationResponse<O.TaxBracketObject>> =
      yield call(createRequestSaga, {
        url: "bracket",
        method: "GET",
        body: params,
      });
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}

export function* setIncomeSaga({ payload }: ActionPayload<P.SetIncomeA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "POST",
      url: `income/${params.id ? "edit" : "add"}`,
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* deleteIncomeSaga({ payload }: ActionPayload<P.DeleteIncomeA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "POST",
      url: "income/delete",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getIncomesSaga({ payload }: ActionPayload<P.GetIncomesA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<PaginationResponse<O.IncomeObject>> =
      yield call(createRequestSaga, {
        url: "income",
        method: "GET",
        body: params,
      });
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}

export function* setExpenseSaga({ payload }: ActionPayload<P.SetExpenseA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "POST",
      url: `expense/${params.id ? "edit" : "add"}`,
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* deleteExpenseSaga({
  payload,
}: ActionPayload<P.DeleteExpenseA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "POST",
      url: "expense/delete",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getExpensesSaga({ payload }: ActionPayload<P.GetExpensesA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse<PaginationResponse<O.ExpenseObject>> =
      yield call(createRequestSaga, {
        url: "expense",
        method: "GET",
        body: params,
      });
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* getDocumentsSaga({ payload }: ActionPayload<P.GetDocumentsA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const is_admin: boolean = yield select(isAdminSelector);
    let url = `${params.type}/documents`;
    if (!is_admin) url = `ledger/${url}`;
    const response: ApiResponse<O.DocumentObject[]> = yield call(
      createRequestSaga,
      {
        url,
        method: "GET",
        body: params,
      }
    );
    onSuccess(response.data);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* addDocumentSaga({ payload }: ActionPayload<P.AddDocumentA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const is_admin: boolean = yield select(isAdminSelector);
    let url = `${params.type}/document-add`;
    if (!is_admin) url = `ledger/${url}`;
    const response: ApiResponse = yield call(createRequestSaga, {
      url,
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* downloadDocumentSaga({
  payload,
}: ActionPayload<P.DownloadDocumentA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const is_admin: boolean = yield select(isAdminSelector);
    let url = `${params.type}/document-download`;
    if (!is_admin) url = `ledger/${url}`;
    const response: ApiResponse<File> = yield call(createRequestSaga, {
      url,
      method: "POST",
      body: objectToFormData(params),
      responseType: "blob",
    });
    if (params.download) downloadFile(response.data, params.name);
    onSuccess({ file: response.data, message: params.name });
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
export function* deleteDocumentSaga({
  payload,
}: ActionPayload<P.DeleteDocumentA>) {
  const { params, onSuccess, onError } = payload;
  try {
    yield put(setLoading());
    const response: ApiResponse = yield call(createRequestSaga, {
      url: `${params.type}/document-delete`,
      method: "POST",
      body: objectToFormData(params),
    });
    onSuccess(response.message);
  } catch (e) {
    const message: string = yield getErrorMessage(e);
    onError(message);
  } finally {
    yield put(setLoaded());
  }
}
