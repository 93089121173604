import _ from "lodash";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Modal, Panel, toaster } from "rsuite";
import { FileType } from "rsuite/esm/Uploader";
import { Button, Notify, Uploader, View } from "../../../../components";
import { getThemeSelector } from "../../../../redux/app";
import { getStringSelector } from "../../../../redux/locale";
import {
  addDocument,
  deleteDocument,
  downloadDocument,
  getDocuments,
} from "../../../../redux/transactions";
import { DocumentObject } from "../../../../redux/types";
import useDropzone from "../../../../utils/useDropzone";
import Item from "./Item";
import Preview from "./Preview";

interface Props {
  id?: number;
  readOnly?: boolean;
  visible: boolean;
  type: "income" | "expense";
  close: (refetch: boolean) => void;
}
const Documents: FC<Props> = ({
  id,
  visible,
  type,
  readOnly = false,
  close,
}) => {
  const strings = useSelector(getStringSelector);
  const theme = useSelector(getThemeSelector);
  const dispatch = useDispatch();
  const [docs, setDocs] = useState<DocumentObject[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [doc, setDoc] = useState<FileType>();
  const [prev, setPreview] = useState<DocumentObject>();
  const isDragging = useDropzone();
  const onOpen = () => {
    if (id)
      dispatch(
        getDocuments({
          params: { id, type },
          onSuccess(payload) {
            setDocs(payload || []);
          },
          onError(message, payload) {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
  };

  const onClose = () => {
    close(refresh);
    setDocs([]);
    setDoc(undefined);
    setRefresh(false);
  };
  const onSubmit = () => {
    if (id && doc?.blobFile)
      dispatch(
        addDocument({
          params: { id, type, doc: doc?.blobFile },
          onSuccess: (message) => {
            setDoc(undefined);
            setRefresh(true);
            onOpen();
            message &&
              toaster.push(<Notify header={message} type={"success"} />);
          },
          onError: (message) => {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
  };
  const onPreviewClick = (doc: DocumentObject) => () => {
    setPreview(doc);
  };
  const onDownloadClick = (doc_id: number, url: string) => () => {
    if (id)
      dispatch(
        downloadDocument({
          params: {
            id: doc_id,
            type,
            name: url.split("/").pop() ?? "file.pdf",
            download: true,
          },
          onSuccess: (payload) => {
            payload &&
              toaster.push(
                <Notify
                  header={strings.formatString(
                    strings.getString("documents_file_downloaded"),
                    payload.message
                  )}
                  type={"success"}
                />
              );
          },
          onError: (message) => {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
  };
  const onDeleteClick = (doc_id: number) => () => {
    if (id)
      dispatch(
        deleteDocument({
          params: { id: doc_id, parent_id: id, type },
          onSuccess: (message) => {
            setRefresh(true);
            onOpen();
            message &&
              toaster.push(<Notify header={message} type={"success"} />);
          },
          onError: (message) => {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
  };
  return (
    <Modal open={visible} onClose={onClose} onOpen={onOpen}>
      <Modal.Header>
        <Modal.Title style={{ fontWeight: "bolder" }}>
          {strings.getString("documents_title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Panel bordered>
          {docs.map((d, i) => (
            <Item
              key={`d-${d.id}`}
              item={d}
              index={i}
              readOnly={readOnly}
              onDownload={onDownloadClick(d.id, d.url)}
              onDelete={onDeleteClick(d.id)}
              onPreview={onPreviewClick(d)}
            />
          ))}
          {!readOnly && (
            <div>
              <Divider />
              <Uploader
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                action=""
                accept={"image/png, image/jpeg, image/jpg, application/pdf"}
                autoUpload={false}
                fileList={doc === undefined ? [] : [doc]}
                multiple={false}
                draggable
                listType={"picture-text"}
                onChange={(fileList) => setDoc(_.last(fileList))}
              >
                <span
                  style={{
                    display: "flex",
                    alignSelf: "stretch",
                    textAlign: "center",
                    padding: 20,
                    borderStyle: "dashed",
                    borderRadius: 5,
                    ...(isDragging && {
                      position: "sticky",
                      zIndex: 10,
                      borderStyle: "dotted",
                      fontStyle: "italic",
                    }),
                  }}
                >
                  {strings.getString(
                    isDragging ? "documents_drag_here" : "documents_drag"
                  )}
                </span>
              </Uploader>
            </div>
          )}
        </Panel>
      </Modal.Body>
      <Modal.Footer
        as={View}
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignSelf: "flex-end",
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {doc !== undefined && (
            <Button onClick={onSubmit} appearance="primary">
              {strings.getString("documents_add")}
            </Button>
          )}
          <Button onClick={onClose} appearance="subtle">
            {strings.getString("cancel")}
          </Button>
        </View>
      </Modal.Footer>
      <Preview doc={prev} type={type} close={() => setPreview(undefined)} />
      {isDragging && (
        <div
          style={{
            borderRadius: 5,
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "black",
            opacity: theme === "dark" ? 0.5 : 0.2,
            zIndex: 1,
          }}
        />
      )}
    </Modal>
  );
};

export default Documents;
