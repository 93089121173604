import AdminIcon from "@rsuite/icons/Admin";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import InfoOutlineIcon from "@rsuite/icons/InfoOutline";
import RemindIcon from "@rsuite/icons/RemindFill";
import UserInfoIcon from "@rsuite/icons/UserInfo";
import { Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Panel, Popover, Whisper, toaster } from "rsuite";
import * as yup from "yup";
import { Button, Divider, Form, Notify, Toggle } from "../../../components";
import { addUser, deleteUser, getUsers } from "../../../redux/actions";
import { getThemeSelector } from "../../../redux/app";
import { getStringSelector } from "../../../redux/locale";
import { UserObject } from "../../../redux/types";
import { opacityPercentage } from "../../../utils";

const initialValues = {
  username: "",
  email: "",
  is_admin: false,
};
function Invite() {
  const strings = useSelector(getStringSelector);
  const theme = useSelector(getThemeSelector);
  const dispatch = useDispatch();
  const [users, setUsers] = useState<UserObject[]>([]);
  const [open, setOpen] = useState<string>("");
  const handleOpen = (email: string) => () => setOpen(email);
  const handleClose = () => setOpen("");
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const SCHEMA = yup.object().shape({
    username: yup.string().required(strings.getString("hint_required")),
    email: yup.string().required(strings.getString("hint_required")),
    is_admin: yup
      .boolean()
      .default(false)
      .required(strings.getString("hint_required")),
  });
  useEffect(() => {
    getUserList();
  }, []);
  const onUserSubmit = (v: typeof initialValues) => {
    dispatch(
      addUser({
        params: v,
        onSuccess: (message) => {
          message && toaster.push(<Notify header={message} type={"success"} />);
          formikRef.current?.setValues(initialValues);
          formikRef.current?.resetForm();
          getUserList();
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const getUserList = () => {
    dispatch(
      getUsers({
        onSuccess(payload) {
          setUsers(payload || []);
        },
        onError(message, payload) {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const onDeleteUserPress = () => {
    dispatch(
      deleteUser({
        params: { email: open },
        onSuccess: (message) => {
          message && toaster.push(<Notify header={message} type={"success"} />);
          handleClose();
          getUserList();
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  return (
    <>
      <Panel
        header={
          <span className="panel-header-title">
            {strings.getString("invite_title")}
          </span>
        }
        bordered
        className="settings-panel"
      >
        <Divider />
        <Formik
          key="invite"
          innerRef={formikRef}
          validationSchema={SCHEMA}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            onUserSubmit(values);
          }}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            submitForm,
            setFieldValue,
          }) => (
            <Form fluid onSubmit={(s, e) => handleSubmit(e)}>
              <Form.Group>
                <Form.ControlLabel>
                  {strings.getString("username")}
                </Form.ControlLabel>
                <Form.Control
                  name="username"
                  type="username"
                  onChange={handleChange("username")}
                  onBlur={handleBlur("username")}
                  value={values.username}
                  errorMessage={touched.username && errors.username}
                />
              </Form.Group>
              <Form.Group style={{ marginTop: 20 }}>
                <Form.ControlLabel>
                  {strings.getString("email")}
                </Form.ControlLabel>
                <Form.Control
                  name="email"
                  type="email"
                  onChange={handleChange("email")}
                  onBlur={handleBlur("email")}
                  value={values.email}
                  errorMessage={touched.email && errors.email}
                />
              </Form.Group>
              <Form.Group style={{ marginTop: 20 }}>
                <Form.ControlLabel>
                  {strings.getString("admin_accountant_user")}
                </Form.ControlLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Whisper
                    placement="bottomStart"
                    speaker={
                      <Popover>
                        {strings.getString("accountant_description")}
                      </Popover>
                    }
                  >
                    <InfoOutlineIcon style={{ marginRight: 10 }} />
                  </Whisper>
                  <Toggle
                    size={"lg"}
                    style={{ display: "flex", alignSelf: "center" }}
                    unCheckedChildren={strings.getString("accountant_user")}
                    checkedChildren={strings.getString("admin_user")}
                    checked={values.is_admin}
                    onChange={(c) => {
                      setFieldValue("is_admin", c);
                    }}
                  />
                  <Whisper
                    speaker={
                      <Popover>
                        {strings.getString("admin_description")}
                      </Popover>
                    }
                  >
                    <InfoOutlineIcon style={{ marginLeft: 10 }} />
                  </Whisper>
                </div>
              </Form.Group>
              <Button onClick={submitForm} appearance="primary">
                {strings.getString("add_user")}
              </Button>
            </Form>
          )}
        </Formik>
      </Panel>
      <Panel
        header={
          <span className="panel-header-title">
            {strings.getString("users")}
          </span>
        }
        bordered
        className="settings-panel"
      >
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "60vh",
            overflowY: "scroll",
          }}
        >
          {users.map((u, i) => (
            <div
              key={u.email}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 20,
                padding: 5,
                paddingLeft: 10,
                borderRadius: 5,
                backgroundColor:
                  i % 2 === 0
                    ? "transparent"
                    : (theme === "dark" ? "#FFFFFF" : "#000000") +
                      opacityPercentage.p5,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Whisper
                  placement="left"
                  speaker={
                    <Popover>
                      {strings.getString(
                        u.is_admin ? "admin_user" : "accountant_user"
                      )}
                    </Popover>
                  }
                >
                  {u.is_admin ? (
                    <AdminIcon
                      width={30}
                      height={30}
                      style={{ marginTop: 5, marginRight: 10 }}
                    />
                  ) : (
                    <UserInfoIcon
                      width={30}
                      height={30}
                      style={{ marginTop: 5, marginRight: 10 }}
                    />
                  )}
                </Whisper>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontSize: 20 }}>{u.username}</span>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ fontSize: 14, marginRight: 5 }}>
                      {u.email}
                    </span>
                    {u.email_verified_at !== null && (
                      <Whisper
                        placement="right"
                        speaker={
                          <Popover>{strings.getString("invite_used")}</Popover>
                        }
                      >
                        <CheckRoundIcon color="green" />
                      </Whisper>
                    )}
                  </div>
                </div>
              </div>
              <Button
                onClick={handleOpen(u.email)}
                appearance="primary"
                color="red"
              >
                {strings.getString("delete_user")}
              </Button>
            </div>
          ))}
        </div>
      </Panel>
      <Modal
        backdrop="static"
        role="alertdialog"
        open={open !== ""}
        onClose={handleClose}
        size="xs"
      >
        <Modal.Body>
          <RemindIcon
            style={{ color: "#ffb300", fontSize: 24, marginRight: 5 }}
          />
          {strings.getString("delete_user_message")}
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              alignSelf: "stretch",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={onDeleteUserPress}
              appearance="primary"
              color="red"
            >
              {strings.getString("delete_user")}
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              {strings.getString("cancel")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Invite;
