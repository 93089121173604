import { InfoOutline } from "@rsuite/icons";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel, Popover, Whisper, toaster } from "rsuite";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Notify,
  Toggle,
} from "../../../components";
import { getSystemThemeSelector } from "../../../redux/app";
import { getStringSelector } from "../../../redux/locale";
import { ThemeType } from "../../../redux/types";
import {
  getSettings,
  getSettingsSelector,
  getUserThemeSelector,
  isAdminSelector,
  setUserSettingsTheme,
  updateSettings,
  updateUserSettings,
} from "../../../redux/user";

function Options() {
  const strings = useSelector(getStringSelector);
  const is_admin = useSelector(isAdminSelector);
  const settings = useSelector(getSettingsSelector);
  const user_theme = useSelector(getUserThemeSelector);
  const system_theme = useSelector(getSystemThemeSelector);
  const dispatch = useDispatch();
  const themeBackupRef = useRef<ThemeType>(user_theme);
  const [tax_discount, setTaxDiscount] = useState<boolean>(false);
  const [other_currencies, setOtherCurrencies] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(setUserSettingsTheme(themeBackupRef.current));
    };
  }, []);
  useEffect(() => {
    if (is_admin) dispatch(getSettings());
  }, [is_admin]);

  useEffect(() => {
    setTaxDiscount(settings.tax_discount);
    setOtherCurrencies(settings.currencies);
  }, [settings]);

  const onUserSave = () => {
    dispatch(
      updateUserSettings({
        params: {
          theme: user_theme,
        },
        onSuccess: (message) => {
          themeBackupRef.current = user_theme;
          message && toaster.push(<Notify header={message} type={"success"} />);
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const onSave = () => {
    dispatch(
      updateSettings({
        params: {
          tax_discount,
          currencies: other_currencies,
        },
        onSuccess: (message) => {
          message && toaster.push(<Notify header={message} type={"success"} />);
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const onThemeDetectChange = (value: any, checked: boolean) => {
    dispatch(setUserSettingsTheme(checked ? "default" : system_theme));
  };
  const onThemeToggle = (checked: boolean) => {
    dispatch(setUserSettingsTheme(checked ? "dark" : "light"));
  };
  return (
    <>
      <Panel
        header={
          <span className="panel-header-title">
            {strings.getString("options_user_title")}
          </span>
        }
        bordered
        className="settings-panel"
      >
        <Divider />
        <Form fluid>
          <Form.Group>
            <Form.ControlLabel style={{ marginBottom: 10 }}>
              {strings.getString("options_theme")}
              <Whisper
                speaker={
                  <Popover>
                    {strings.getString("options_theme_description")}
                  </Popover>
                }
              >
                <InfoOutline style={{ marginLeft: 10 }} />
              </Whisper>
            </Form.ControlLabel>
            <Checkbox
              checked={user_theme === "default"}
              onChange={onThemeDetectChange}
              style={{ marginRight: 20 }}
            >
              {strings.getString("options_theme_detect")}
            </Checkbox>
            <Toggle
              disabled={user_theme === "default"}
              unCheckedChildren={strings.getString("options_theme_light")}
              checkedChildren={strings.getString("options_theme_dark")}
              checked={
                user_theme === "default"
                  ? system_theme === "dark"
                  : user_theme === "dark"
              }
              onChange={onThemeToggle}
            />
          </Form.Group>
          <Button onClick={onUserSave} appearance="primary">
            {strings.getString("save_changes")}
          </Button>
        </Form>
      </Panel>
      {is_admin && (
        <Panel
          header={
            <span className="panel-header-title">
              {strings.getString("options_settings_title")}
            </span>
          }
          bordered
          className="settings-panel"
        >
          <Divider />
          <Form fluid>
            <Form.Group>
              <Form.ControlLabel style={{ marginBottom: 10 }}>
                {strings.getString("options_tax_discount")}
                <Whisper
                  speaker={
                    <Popover>
                      {strings.getString("options_tax_discount_description")}
                    </Popover>
                  }
                >
                  <InfoOutline style={{ marginLeft: 10 }} />
                </Whisper>
              </Form.ControlLabel>
              <Toggle checked={tax_discount} onChange={setTaxDiscount} />
            </Form.Group>
            <Form.Group style={{ marginTop: 20 }}>
              <Form.ControlLabel style={{ marginBottom: 10 }}>
                {strings.getString("options_other_currencies")}
                <Whisper
                  speaker={
                    <Popover>
                      {strings.getString(
                        "options_other_currencies_description"
                      )}
                    </Popover>
                  }
                >
                  <InfoOutline style={{ marginLeft: 10 }} />
                </Whisper>
              </Form.ControlLabel>
              <Toggle
                checked={other_currencies}
                onChange={setOtherCurrencies}
              />
            </Form.Group>
            <Button onClick={onSave} appearance="primary">
              {strings.getString("save_changes")}
            </Button>
          </Form>
        </Panel>
      )}
    </>
  );
}
export default Options;
