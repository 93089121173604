/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */

import { produce } from "immer";
import { ActionPayload, UserReducer } from "../../types";
import * as A from "./actions";
import * as O from "./types/objects";

const initialState: UserReducer = {
  token: undefined,
  data: undefined,
  gib_status: false,
  settings: {
    id: 0,
    currencies: false,
    tax_discount: false,
    created_at: "",
    updated_at: "",
  },
};
type Payload =
  | string
  | boolean
  | O.UserObject
  | O.SettingsObject
  | O.CurrencyObject[];
const userReducer = produce(
  (draft: UserReducer, action: ActionPayload<Payload>) => {
    switch (action.type) {
      case A.setToken.toString():
        draft.token = action.payload as string;
        break;
      case A.setUser.toString():
        draft.data = action.payload as O.UserObject;
        break;
      case A.setSettings.toString():
        draft.settings = action.payload as O.SettingsObject;
        break;
      case A.setGibStatus.toString():
        draft.gib_status = action.payload as boolean;
        break;
      case A.setUserSettingsTheme.toString():
        if (draft.data) draft.data.theme = action.payload as O.ThemeType;
        break;
      case A.setCurrencies.toString():
        draft.currencies = action.payload as O.CurrencyObject[];
        break;
      case A.setLogout.toString():
        draft.data = initialState.data;
        draft.token = initialState.token;
        draft.gib_status = initialState.gib_status;
        draft.settings = initialState.settings;
        draft.currencies = initialState.currencies;
        break;
    }
  },
  initialState
);

export default userReducer;
