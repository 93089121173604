import { FC } from "react";
import { Notification, NotificationProps } from "rsuite";

interface NotifyProps extends NotificationProps {}
const Notify: FC<NotifyProps> = ({ ...rest }) => {
  //@ts-expect-error
  return <Notification {...rest} />;
};

export default Notify;
