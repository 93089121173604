/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */

import { produce } from "immer";
import { ActionPayload, LanguageReducer } from "../../types";

const initialState: LanguageReducer = require("../../utils/localization.json");
type Payload = { data: LanguageReducer };
const languageReducer = produce(
  (draft: LanguageReducer, action: ActionPayload<Payload>) => {},
  initialState
);

export default languageReducer;
