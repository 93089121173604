import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { Panel } from "rsuite";
import { Button } from "../../../../components";
import { getStringSelector } from "../../../../redux/locale";
import { DocumentObject } from "../../../../redux/types";
import { isAdminSelector } from "../../../../redux/user";

interface Props {
  item: DocumentObject;
  index: number;
  readOnly: boolean;
  onDownload: () => void;
  onPreview: () => void;
  onDelete: () => void;
}
const Item: FC<Props> = ({
  item,
  index,
  readOnly,
  onPreview,
  onDelete,
  onDownload,
}) => {
  const strings = useSelector(getStringSelector);
  const is_admin = useSelector(isAdminSelector);
  const allow_preview = useMemo(() => {
    const ext = item.url.split(".").pop();
    switch (ext) {
      case "png":
      case "jpg":
      case "jpeg":
      case "pdf":
        return true;

      case "zip":
      default:
        return false;
    }
  }, [item.url]);

  return (
    <Panel
      bordered
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignSelf: "stretch",
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 10,
          alignItems: "center",
        }}
      >
        <span style={{ fontSize: "1.2rem" }}>#{index + 1}</span>
        <span
          style={{
            display: "flex",
            flex: 1,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          {item.url.split("/").pop()}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "stretch",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button onClick={onDownload} target="_blank">
            {strings.getString("documents_download")}
          </Button>
          {allow_preview && (
            <Button
              onClick={onPreview}
              appearance="ghost"
              style={{ marginLeft: 20 }}
            >
              {strings.getString("documents_preview")}
            </Button>
          )}
        </div>
        {!readOnly && is_admin && (
          <Button onClick={onDelete} color="red" style={{ marginLeft: 20 }}>
            {strings.getString("documents_delete")}
          </Button>
        )}
      </div>
    </Panel>
  );
};

export default Item;
