/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Nav, Sidenav } from "rsuite";
import { logoRound } from "../assets";
import SidenavItem from "../items/SidenavItem";
import { toggleSideNav } from "../redux/actions";
import {
  getActiveRouteKeySelector,
  getMenuSelector,
  getSideNavExpandedSelector,
} from "../redux/app";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_WIDTH_EXPANDED,
  useDimensions,
} from "../utils";

const PanelSideNav = () => {
  const sidemenu = useSelector(getMenuSelector);
  const activeKey = useSelector(getActiveRouteKeySelector);
  const is_expanded = useSelector(getSideNavExpandedSelector);
  const { isDesktop } = useDimensions();
  const expanded = useMemo(
    () => is_expanded && isDesktop,
    [is_expanded, isDesktop]
  );
  const dispatch = useDispatch();
  const onToggleChange = () => dispatch(toggleSideNav());

  return (
    <div
      style={{
        width: expanded ? SIDE_NAV_WIDTH_EXPANDED : SIDE_NAV_WIDTH,
        minWidth: SIDE_NAV_WIDTH,
        display: "inline-table",
        overflow: "hidden",
      }}
    >
      <Sidenav expanded={expanded} style={{ height: "100vh" }}>
        <Sidenav.Header>
          {expanded ? (
            <div
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                marginTop: 20,
                marginBottom: 20,
                fontSize: 26,
              }}
            >
              <img
                src={logoRound}
                alt=""
                style={{
                  width: 50,
                  height: 50,
                }}
              />
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                marginTop: 20,
                marginBottom: 20,
                fontSize: 26,
              }}
            >
              <img
                src={logoRound}
                alt=""
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            </div>
          )}
        </Sidenav.Header>
        <Sidenav.Body>
          <Nav activeKey={activeKey}>
            {sidemenu.map((mi) => (
              <SidenavItem key={mi.id} {...mi} />
            ))}
          </Nav>
        </Sidenav.Body>
        {isDesktop && (
          <>
            <div style={{ display: "flex", flex: 1 }} />
            <hr />
            <Sidenav.Toggle
              onToggle={onToggleChange}
              style={{ alignSelf: "stretch" }}
            />
          </>
        )}
      </Sidenav>
    </div>
  );
};

export default PanelSideNav;
