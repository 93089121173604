import dayjs from "dayjs";
import _ from "lodash";
import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { InputGroup } from "rsuite";
import { InputNumber, SelectPicker } from "../../../components";
import { getStringSelector } from "../../../redux/locale";
import { TaxBracketObject } from "../../../redux/types";
import {
  getCurrenciesSelector,
  getSettingsSelector,
} from "../../../redux/user";
import { IncomeType } from "../../../types";
import { displayCurrency, getTaxCalculationResult } from "../../../utils";

interface Props {
  brackets: TaxBracketObject[];
}
const Avg: FC<Props> = ({ brackets }) => {
  const strings = useSelector(getStringSelector);
  const settings = useSelector(getSettingsSelector);
  const currencies = useSelector(getCurrenciesSelector);
  const months = useMemo(
    () =>
      Array.from({ length: 12 }, () => null).map((s, i) => ({
        label: dayjs().set("month", i).format("MMMM"),
        value: i,
      })),
    []
  );
  const [month, setMonth] = useState<number>(0);
  const [income, setIncome] = useState<string>("0");
  const [income_exchange, setIncomeExchange] = useState<string>("");
  const [external_income, setExternalIncome] = useState<string>("0");
  const [discount, setDiscount] = useState<string>("0");
  const [expense, setExpense] = useState<string>("0");
  const [exchange, setExchange] = useState<string>("");
  const [currency, setCurrency] = useState<{ code: string; symbol: string }>({
    code: "EUR",
    symbol: "€",
  });
  const currency_value = useMemo(() => {
    let eur = "1";
    let usd = "1";
    let gbp = "1";
    currencies.forEach((c) => {
      if (c.code === "eur") eur = String(c.value);
      if (c.code === "usd") usd = String(c.value);
      if (c.code === "gbp") gbp = String(c.value);
    });
    return { eur, usd, gbp };
  }, [currencies]);

  const calculation = useMemo(() => {
    const gider = parseInt(expense || "0");
    let income_tax: IncomeType[] = Array.from(
      { length: 12 - month },
      (v, k) =>
        ({
          amount: parseInt(income),
          percent: 1 - parseInt(discount) / 100,
          month: k,
          tax_brackets: [] as number[],
          tax: 0,
          tax_ignore: false,
        } as IncomeType)
    );
    let toplam_komulatif_gelir_matrahi = 0;

    income_tax.forEach((it, iti) => {
      if (!it.tax_ignore) {
        const { dahil_olunan_vergi_dilimleri, komulatif_gelir_matrahi, vergi } =
          getTaxCalculationResult(
            toplam_komulatif_gelir_matrahi,
            brackets,
            it.amount,
            gider,
            it.percent
          );
        toplam_komulatif_gelir_matrahi = komulatif_gelir_matrahi;
        income_tax[iti].tax_brackets = dahil_olunan_vergi_dilimleri;
        income_tax[iti].tax = vergi;
      }
    });
    let amount = 0;
    let net = 0;
    let tax = 0;
    let avg_tax_percent = 0;
    let avg_tax_expense_percent = 0;
    let tax_brackets: number[] = [];
    income_tax.forEach((it) => {
      amount += it.amount;
      tax += it.tax;
      tax_brackets = _.uniq([...tax_brackets, ...it.tax_brackets]);
    });

    amount = amount / (12 - month);
    tax = tax / (12 - month);
    amount = isNaN(amount) ? 0 : amount;
    tax = isNaN(tax) ? 0 : tax;
    avg_tax_percent = (tax * 100) / (amount + parseInt(external_income || "0"));
    avg_tax_expense_percent =
      ((tax + gider) * 100) / (amount + parseInt(external_income || "0"));
    net = amount + parseInt(external_income || "0") - (tax + gider);
    avg_tax_percent = Number(avg_tax_percent.toFixed(1));
    avg_tax_expense_percent = Number(avg_tax_expense_percent.toFixed(1));
    if (isNaN(avg_tax_percent)) avg_tax_percent = 0;
    if (isNaN(avg_tax_expense_percent)) avg_tax_expense_percent = 0;
    return {
      net,
      tax,
      brackets: tax_brackets.map((tb) => `%${tb}`).join(", "),
      avg_tax_percent,
      avg_tax_expense_percent,
    };
  }, [brackets, discount, income, month, expense, external_income]);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowY: "scroll",
        maxHeight: "88vh",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        justifyContent: "flex-start",
        alignSelf: "stretch",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={settings.tax_discount ? "calculate-row" : ""}>
          <div className="calculate-row-item">
            <span className="calculate-avg-title">
              {strings.getString("calculate_avg_start_month")}
            </span>
            <SelectPicker
              className="calculate-avg-input"
              size="lg"
              placement="autoVertical"
              cleanable={false}
              value={month}
              onChange={setMonth}
              data={months}
            />
          </div>
          {settings.tax_discount && (
            <div className="calculate-row-item">
              <span className="calculate-avg-title">
                {strings.getString("calculate_avg_tax_discount")}
              </span>
              <InputNumber
                size="lg"
                className="calculate-avg-input"
                prefix={"%"}
                onChange={(e) => setDiscount(String(e))}
                value={discount}
                defaultValue={0}
                step={10}
                min={0}
                max={100}
              />
            </div>
          )}
        </div>
        <span className="calculate-avg-title">
          {strings.getString("calculate_avg_income")}
        </span>
        <InputGroup className="calculate-avg-input">
          <InputNumber
            size="lg"
            onChange={(e) => {
              setIncome(String(e));
              setIncomeExchange("");
            }}
            prefix={"₺"}
            value={income}
            step={500}
            defaultValue={0}
            min={0}
          />
          {exchange !== "" && (
            <InputNumber
              size="lg"
              onChange={(e) => {
                setIncomeExchange(String(e));
                setIncome(
                  String(
                    Math.ceil(
                      parseInt(String(e)) / (1 / parseFloat(exchange || "0"))
                    )
                  )
                );
              }}
              prefix={currency.symbol}
              value={income_exchange}
              step={100}
              defaultValue={0}
              min={0}
            />
          )}
        </InputGroup>
        <div className={settings.currencies ? "calculate-row" : ""}>
          <div className="calculate-row-item">
            <span className="calculate-avg-title">
              {strings.getString("calculate_avg_external_income")}
            </span>
            <InputNumber
              size="lg"
              className="calculate-avg-input"
              prefix={"₺"}
              onChange={(e) => setExternalIncome(String(e))}
              value={external_income}
              step={1000}
              defaultValue={0}
              min={0}
            />
          </div>
          {settings.currencies && (
            <div className="calculate-row-item">
              <span className="calculate-avg-title">
                {strings.getString("calculate_avg_exchange")}
              </span>
              <InputGroup className="calculate-avg-input">
                <InputNumber
                  size="lg"
                  prefix={currency.symbol}
                  onChange={(e) => setExchange(String(e))}
                  value={exchange}
                  defaultValue={1}
                  min={0}
                />
                <InputGroup.Button
                  onClick={() => {
                    setCurrency({ code: "EUR", symbol: "€" });
                    setExchange(currency_value.eur);
                  }}
                >
                  €
                </InputGroup.Button>
                <InputGroup.Button
                  onClick={() => {
                    setCurrency({ code: "USD", symbol: "$" });
                    setExchange(currency_value.usd);
                  }}
                >
                  $
                </InputGroup.Button>
                <InputGroup.Button
                  onClick={() => {
                    setCurrency({ code: "GBP", symbol: "£" });
                    setExchange(currency_value.gbp);
                  }}
                >
                  £
                </InputGroup.Button>
              </InputGroup>
            </div>
          )}
        </div>
        <span className="calculate-avg-title">
          {strings.getString("calculate_avg_expense")}
        </span>
        <InputNumber
          size="lg"
          className="calculate-avg-input"
          prefix={"₺"}
          onChange={(e) => setExpense(String(e))}
          value={expense}
          step={500}
          defaultValue={0}
          min={0}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            marginTop: 30,
          }}
        >
          <Field
            large
            label={strings.getString("calculate_avg_total_income")}
            value={displayCurrency(
              parseInt(income || "0") + parseInt(external_income || "0")
            )}
            sub_value={
              exchange && settings.currencies
                ? displayCurrency(
                    (parseInt(income || "0") +
                      parseInt(external_income || "0")) *
                      (1 / parseFloat(exchange)),
                    currency.code
                  )
                : undefined
            }
          />
          <div>
            <Field
              label={strings.getString("calculate_avg_tax")}
              value={`${displayCurrency(calculation.tax)} ~ %${
                calculation.avg_tax_percent
              }`}
            />
            <Field
              label={strings.getString("calculate_avg_tax_expense")}
              value={`${displayCurrency(
                calculation.tax + parseInt(expense || "0")
              )} ~ %${calculation.avg_tax_expense_percent}`}
            />
            <Field
              label={strings.getString("calculate_avg_tax_brackets")}
              value={calculation.brackets}
            />
          </div>
          <Field
            large
            label={strings.getString("calculate_avg_net_income")}
            value={displayCurrency(calculation.net)}
            sub_value={
              exchange && settings.currencies
                ? displayCurrency(
                    calculation.net * (1 / parseFloat(exchange)),
                    currency.code
                  )
                : undefined
            }
          />
        </div>
      </div>
      <span style={{ display: "flex", flex: 1 }} />
      <span style={{ fontStyle: "italic" }}>
        *{strings.getString("calculate_description")}
      </span>
    </div>
  );
};

interface FieldProps {
  label: string;
  value: string;
  sub_value?: string;
  large?: boolean;
}
const Field: FC<FieldProps> = ({ label, value, sub_value, large = false }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignSelf: "center",
        alignItems: "center",
      }}
    >
      <span
        className={`calculate-avg-title${
          large ? " calculate-avg-title-large" : ""
        }`}
        style={{ textAlign: "center" }}
      >
        {label}
      </span>
      <span
        className={`calculate-avg-value${
          large ? " calculate-avg-value-large" : ""
        }`}
      >
        {value}
      </span>
      {sub_value && (
        <span
          className={`calculate-avg-sub-value${
            large ? " calculate-avg-sub-value-large" : ""
          }`}
        >
          {sub_value}
        </span>
      )}
    </div>
  );
};

export default Avg;
