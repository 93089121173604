/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import * as W from "./watchers";

export default [
  W.systemThemeEventWatcher(),
  W.configureWatcher(),
  W.getYearsWatcher(),
];
