/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { createSelector } from "reselect";
import { RootState } from "../../types";
import { SUB_PATH } from "../../utils";

/**
 * APPLICATION
 */
const getAppState = (state: RootState) => state.app;
export const getLoadingStateSelector = createSelector(
  [getAppState],
  (app) => app.loading
);
const getScreenState = (state: RootState) => state.app.screen;
export const getActiveScreenSelector = createSelector(
  [getScreenState],
  (screen) => screen
);
const geMenuState = (state: RootState) => state.app.menu;
export const getMenuSelector = createSelector([geMenuState], (menu) => menu);
const getSystemThemeState = (state: RootState) => state.app.system_theme;
export const getSystemThemeSelector = createSelector(
  [getSystemThemeState],
  (theme) => theme
);
const getSettingsThemeState = (state: RootState) => state.user?.data?.theme;
export const getThemeSelector = createSelector(
  [getSystemThemeState, getSettingsThemeState],
  (system_theme, settings_theme) =>
    settings_theme === "default" ? system_theme : settings_theme ?? "light"
);
const getGibStatusState = (state: RootState) => state.user?.gib_status;
export const getGibStatusSelector = createSelector(
  [getGibStatusState],
  (status) => (process.env.NODE_ENV === "development" ? true : status ?? false)
);
const geActiveRouteState = (state: RootState) => state.app.activeRouteKey;
export const getActiveRouteKeySelector = createSelector(
  [geActiveRouteState],
  (route_key) => SUB_PATH + route_key
);
const getSideNavState = (state: RootState) => state.app.side_nav_expanded;
export const getSideNavExpandedSelector = createSelector(
  [getSideNavState],
  (side_nav_expanded) => side_nav_expanded
);
export const getActiveRouteSelector = createSelector(
  [geActiveRouteState, geMenuState],
  (route_key, menu) => menu.find((m) => m.id == route_key)
);
const getYearsState = (state: RootState) => state.app.years;
export const getYearsSelector = createSelector(
  [getYearsState],
  (data) => data || []
);
const getYearState = (state: RootState) => state.app.year;
export const getSelectedYearSelector = createSelector(
  [getYearsState, getYearState],
  (years, year) => year || years?.[0]
);
