import ShieldIcon from "@rsuite/icons/Shield";
import { Formik, FormikProps } from "formik";
import { useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { Panel, toaster } from "rsuite";
import * as yup from "yup";
import { Button, Divider, Form, Notify } from "../../../components";
import {
  get2FASecret,
  updateUser,
  verify2FACode,
} from "../../../redux/actions";
import { getStringSelector } from "../../../redux/locale";
import { getUserSelector } from "../../../redux/user";

const initial2FAValues = {
  code: "",
};
const initialPasswordValues = {
  password: "",
  new_password: "",
  new_password_confirm: "",
};
function Security() {
  const strings = useSelector(getStringSelector);
  const user = useSelector(getUserSelector);
  const dispatch = useDispatch();
  const [secret, setSecret] = useState<string>();
  const formik2FARef = useRef<FormikProps<typeof initial2FAValues>>(null);
  const formikPasswordRef =
    useRef<FormikProps<typeof initialPasswordValues>>(null);
  const SCHEMA_2FA = yup.object().shape({
    code: yup.string().required(strings.getString("hint_required")),
  });
  const SCHEMA_PASSWORD = yup.object().shape({
    password: yup.string().required(strings.getString("hint_required")),
    new_password: yup.string().required(strings.getString("hint_required")),
    new_password_confirm: yup
      .string()
      .test(
        "passwords-match",
        strings.getString("hint_password_match"),
        function (value) {
          return this.parent.new_password === value;
        }
      )
      .required(strings.getString("hint_required")),
  });
  const onPasswordSubmit = (v: typeof initialPasswordValues) => {
    dispatch(
      updateUser({
        params: { password: v.password, new_password: v.new_password },
        onSuccess: (message) => {
          message && toaster.push(<Notify header={message} type={"success"} />);
          formikPasswordRef.current?.setValues(initialPasswordValues);
          formikPasswordRef.current?.resetForm();
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const onSecretPress = () => {
    dispatch(
      get2FASecret({
        onSuccess(payload) {
          setSecret(payload);
        },
        onError(message, payload) {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const on2FASubmit = (v: typeof initial2FAValues) => {
    dispatch(
      verify2FACode({
        params: v,
        onSuccess: (message) => {
          message && toaster.push(<Notify header={message} type={"success"} />);
          setSecret(undefined);
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  return (
    <>
      <Panel
        bordered
        header={
          <span className="panel-header-title">
            {strings.getString("password_title")}
          </span>
        }
        className="settings-panel"
      >
        <Divider />
        <Formik
          key="pass"
          innerRef={formikPasswordRef}
          validationSchema={SCHEMA_PASSWORD}
          initialValues={initialPasswordValues}
          onSubmit={(values, actions) => {
            onPasswordSubmit(values);
          }}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            submitForm,
          }) => (
            <Form fluid onSubmit={(s, e) => handleSubmit(e)}>
              <input type="email" hidden value={user?.username ?? ""} />
              <Form.Group>
                <Form.ControlLabel>
                  {strings.getString("current_password")}
                </Form.ControlLabel>
                <Form.Control
                  name="password"
                  type="password"
                  onChange={handleChange("password")}
                  onBlur={handleBlur("password")}
                  value={values.password}
                  errorMessage={touched.password && errors.password}
                />
              </Form.Group>
              <Form.Group style={{ marginTop: 20 }}>
                <Form.ControlLabel>
                  {strings.getString("new_password")}
                </Form.ControlLabel>
                <Form.Control
                  name="new_password"
                  type="password"
                  onChange={handleChange("new_password")}
                  onBlur={handleBlur("new_password")}
                  value={values.new_password}
                  errorMessage={touched.new_password && errors.new_password}
                />
              </Form.Group>
              <Form.Group style={{ marginTop: 20 }}>
                <Form.ControlLabel>
                  {strings.getString("new_password")}
                </Form.ControlLabel>
                <Form.Control
                  name="new_password_confirm"
                  type="password"
                  onChange={handleChange("new_password_confirm")}
                  onBlur={handleBlur("new_password_confirm")}
                  value={values.new_password_confirm}
                  errorMessage={
                    touched.new_password_confirm && errors.new_password_confirm
                  }
                />
              </Form.Group>
              <Button onClick={submitForm} appearance="primary">
                {strings.getString("save_changes")}
              </Button>
            </Form>
          )}
        </Formik>
      </Panel>
      <Panel
        header={
          <span className="panel-header-title">
            {strings.getString("two_factor_title")}
          </span>
        }
        bordered
        className="settings-panel"
      >
        {secret ? (
          <Formik
            key="2fa"
            innerRef={formik2FARef}
            validationSchema={SCHEMA_2FA}
            initialValues={initial2FAValues}
            onSubmit={(values, actions) => {
              on2FASubmit(values);
            }}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              submitForm,
            }) => (
              <Form fluid onSubmit={(s, e) => handleSubmit(e)}>
                <input type="email" hidden value={user?.username ?? ""} />
                <Form.Group>
                  <div
                    style={{
                      height: "auto",
                      margin: "0 auto",
                      maxWidth: 128,
                      width: "100%",
                      backgroundColor: "white",
                      padding: 10,
                      borderRadius: 10,
                    }}
                  >
                    <QRCode
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={secret}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                  <Form.ControlLabel>
                    {strings.getString("two_factor_secret")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="secret"
                    type="text"
                    value={secret}
                    disabled
                  />
                  <Form.ControlLabel style={{ marginTop: 20 }}>
                    {strings.getString("two_factor_code")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="code"
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    autoComplete="one-time-code"
                    onChange={handleChange("code")}
                    onBlur={handleBlur("code")}
                    value={values.code}
                    errorMessage={touched.code && errors.code}
                  />
                </Form.Group>
                <Button onClick={submitForm} appearance="primary">
                  {strings.getString("two_factor_verify")}
                </Button>
              </Form>
            )}
          </Formik>
        ) : user?.is_two_factor_enabled ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ShieldIcon color="green" width={128} height={128} />
            <span style={{ marginTop: 20 }}>
              {strings.getString("two_factor_enabled")}
            </span>
          </div>
        ) : (
          <Button onClick={onSecretPress} appearance="primary">
            {strings.getString("two_factor_use")}
          </Button>
        )}
      </Panel>
    </>
  );
}
export default Security;
