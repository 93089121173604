import { Formik, FormikProps } from "formik";
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  ButtonToolbar,
  Col,
  Container,
  Content,
  Grid,
  Header,
  Modal,
  Panel,
  Row,
  toaster,
} from "rsuite";
import * as yup from "yup";
import { logoRound } from "../assets";
import { Button, Form, Notify } from "../components";
import { getStringSelector } from "../redux/locale";
import { forgotPassword, login, resetPassword } from "../redux/user";
import { DISPLAY_NAME } from "../utils";

const initialValues = {
  username: process.env.NODE_ENV === "development" ? "admin" : "",
  password: process.env.NODE_ENV === "development" ? "123456" : "",
  code_required: false,
  code: "",
};
const initialResetValues = {
  hash: "",
  password: process.env.NODE_ENV === "development" ? "123456" : "",
};
const initialForgotValues = {
  email: "",
};

function Auth() {
  const [searchParams, setSearchParams] = useSearchParams();
  const strings = useSelector(getStringSelector);
  const dispatch = useDispatch();
  const hash = useMemo(() => searchParams.get("hash"), [searchParams]);
  const LoginSchema = yup.object().shape({
    username: yup.string().required(strings.getString("hint_required")),
    password: yup
      .string()
      .min(6, strings.getString("hint_min_password"))
      .required(strings.getString("hint_required")),
    code_required: yup.boolean().default(false),
    code: yup.string().when("code_required", {
      is: true,
      then: (schema) => schema.required(strings.getString("hint_required")),
      otherwise: (schema) => schema,
    }),
  });
  const ResetSchema = yup.object().shape({
    password: yup
      .string()
      .min(6, strings.getString("hint_min_password"))
      .required(strings.getString("hint_required")),
  });
  const ForgotSchema = yup.object().shape({
    email: yup.string().required(strings.getString("hint_required")),
  });
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const formikForgotRef = useRef<FormikProps<typeof initialForgotValues>>(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onSubmitPress = (payload: typeof initialValues) => {
    dispatch(
      login({
        params: payload,
        onError: (message, data) => {
          if (data === "2FA") {
            formikRef.current?.setFieldValue("code_required", true);
            if (payload.code_required)
              toaster.push(<Notify header={message} type={"error"} />);
          } else toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };

  const onForgotPress = (payload: typeof initialForgotValues) => {
    dispatch(
      forgotPassword({
        params: payload,
        onSuccess(payload) {
          handleClose();
          toaster.push(<Notify header={payload} type={"success"} />);
        },
        onError: (message, data) => {
          toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };

  const onResetPress = (payload: typeof initialResetValues) => {
    dispatch(
      resetPassword({
        params: { ...payload, hash: hash ?? "" },
        onSuccess(payload) {
          setSearchParams((sp) => {
            sp.delete("hash");
            return sp;
          });
          toaster.push(<Notify header={payload} type={"success"} />);
        },
        onError: (message, data) => {
          toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  return (
    <Container>
      <Content>
        <Grid fluid>
          <Row
            style={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Col xs={20} sm={14} md={12} lg={8}>
              <Panel
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1vh",
                  alignSelf: "center",
                }}
                bordered
              >
                <Header
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "stretch",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={logoRound}
                    alt=""
                    style={{
                      width: 150,
                      height: 150,
                      marginBottom: 20,
                    }}
                  />
                  <span style={{ fontSize: 32, marginBottom: 20 }}>
                    {DISPLAY_NAME}
                  </span>
                </Header>
                {hash ? (
                  <Formik
                    key={"reset"}
                    validationSchema={ResetSchema}
                    initialValues={initialResetValues}
                    onSubmit={(values, actions) => {
                      onResetPress(values);
                    }}
                  >
                    {({
                      errors,
                      values,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form fluid onSubmit={(s, e) => handleSubmit(e)}>
                        <Form.Group>
                          <Form.ControlLabel>
                            {strings.getString("password")}
                          </Form.ControlLabel>
                          <Form.Control
                            name="password"
                            type="password"
                            autoComplete="off"
                            onChange={handleChange("password")}
                            onBlur={handleBlur("password")}
                            value={values.password}
                            errorMessage={errors.password}
                          />
                        </Form.Group>
                        <Form.Group>
                          <ButtonToolbar>
                            <Button appearance="primary" type="submit" block>
                              {strings.getString("reset_password")}
                            </Button>
                          </ButtonToolbar>
                        </Form.Group>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <Formik
                    key={"login"}
                    innerRef={formikRef}
                    validationSchema={LoginSchema}
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                      onSubmitPress(values);
                    }}
                  >
                    {({
                      errors,
                      values,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form fluid onSubmit={(s, e) => handleSubmit(e)}>
                        <Form.Group>
                          <Form.ControlLabel>
                            {strings.getString("username")}
                          </Form.ControlLabel>
                          <Form.Control
                            name="username"
                            type="username"
                            onChange={handleChange("username")}
                            onBlur={handleBlur("username")}
                            value={values.username}
                            errorMessage={errors.username}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.ControlLabel>
                            {strings.getString("password")}
                          </Form.ControlLabel>
                          <Form.Control
                            name="password"
                            type="password"
                            autoComplete="off"
                            onChange={handleChange("password")}
                            onBlur={handleBlur("password")}
                            value={values.password}
                            errorMessage={errors.password}
                          />
                        </Form.Group>
                        {values.code_required && (
                          <Form.Group>
                            <Form.ControlLabel style={{ marginTop: 20 }}>
                              {strings.getString("two_factor_code")}
                            </Form.ControlLabel>
                            <Form.Control
                              name="code"
                              type="text"
                              inputmode="numeric"
                              pattern="[0-9]*"
                              autoFocus
                              autoComplete="one-time-code"
                              maxLength={6}
                              onChange={(t) => {
                                handleChange("code")(String(t));
                                if (String(t).length === 6) handleSubmit();
                              }}
                              onBlur={handleBlur("code")}
                              value={values.code}
                              errorMessage={touched.code && errors.code}
                            />
                          </Form.Group>
                        )}
                        <Form.Group>
                          <ButtonToolbar>
                            <Button appearance="primary" type="submit" block>
                              {strings.getString("login")}
                            </Button>
                            <Button appearance="link" onClick={handleOpen}>
                              {strings.getString("forgot_password")}
                            </Button>
                          </ButtonToolbar>
                        </Form.Group>
                      </Form>
                    )}
                  </Formik>
                )}
              </Panel>
            </Col>
          </Row>
        </Grid>
      </Content>
      <Modal size="xs" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title style={{ fontWeight: "bolder" }}>
            {strings.getString("forgot_password")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            key={"forgot"}
            innerRef={formikForgotRef}
            validationSchema={ForgotSchema}
            initialValues={initialForgotValues}
            onSubmit={(values, actions) => {
              onForgotPress(values);
            }}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form
                fluid
                onSubmit={(s, e) => handleSubmit(e)}
                style={{ marginBottom: 20, marginTop: 20 }}
              >
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("email")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="email"
                    type="email"
                    autoComplete="off"
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values.email}
                    errorMessage={errors.email}
                  />
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => formikForgotRef.current?.handleSubmit()}
            appearance="primary"
          >
            {strings.getString("send_reset_password")}
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            {strings.getString("cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
export default Auth;
