/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { useSelector } from "react-redux";
import { Nav as NavComponent, NavProps } from "rsuite";
import { getLoadingStateSelector } from "../redux/app";
const Nav = ({ ...props }: NavProps) => {
  const loading = useSelector(getLoadingStateSelector);
  return <NavComponent {...props} disabled={loading} />;
};

Nav.Item = NavComponent.Item;
export default Nav;
