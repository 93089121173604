import { InfoOutline } from "@rsuite/icons";
import { Formik, FormikProps } from "formik";
import { FC, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DatePicker,
  InputGroup,
  Modal,
  Panel,
  Popover,
  Whisper,
  toaster,
} from "rsuite";
import * as yup from "yup";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Notify,
  Toggle,
  View,
} from "../../../components";
import { getStringSelector } from "../../../redux/locale";
import { deleteIncome, setIncome } from "../../../redux/transactions";
import { SetIncomeP } from "../../../redux/types";
import { getSettingsSelector } from "../../../redux/user";
import { VAT_PERCENTAGES } from "../../../utils";

interface Props {
  data?: {
    id?: number;
    company_name?: string;
    amount?: string;
    vat_percent?: string;
    income_tax_discount?: string;
    billed_at?: string;
    external?: boolean;
    notes?: string;
    confirmed?: boolean;
  };
  visible: boolean;
  close: (isCancelled: boolean) => void;
}
const initialValues = {
  company_name: "",
  amount: "",
  vat_percent: "0",
  income_tax_discount: "0",
  billed_at: new Date().toISOString().split("T")[0],
  external: false,
  notes: "",
};
const Add: FC<Props> = ({ data, visible, close }) => {
  const strings = useSelector(getStringSelector);
  const settings = useSelector(getSettingsSelector);
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const SCHEMA = yup.object().shape({
    company_name: yup.string().required(strings.getString("hint_required")),
    amount: yup.string().required(strings.getString("hint_required")),
    vat_percent: yup.string().required(strings.getString("hint_required")),
    income_tax_discount: yup
      .string()
      .default("0")
      .required(strings.getString("hint_required")),
    billed_at: yup.string().required(strings.getString("hint_required")),
    external: yup
      .boolean()
      .default(false)
      .required(strings.getString("hint_required")),
    notes: yup.string(),
  });
  const is_edit = useMemo(() => Boolean(data?.id), [data]);
  const is_read_only = useMemo(
    () => is_edit && !!data?.confirmed,
    [is_edit, data?.confirmed]
  );
  const onOpen = () => {
    formikRef.current?.setValues({
      company_name: data?.company_name ?? initialValues.company_name,
      amount: data?.amount ?? initialValues.amount,
      vat_percent: data?.vat_percent ?? initialValues.vat_percent,
      income_tax_discount:
        data?.income_tax_discount ?? initialValues.income_tax_discount,
      billed_at: data?.billed_at ?? initialValues.billed_at,
      external: data?.external ?? initialValues.external,
      notes: data?.notes ?? initialValues.notes,
    });
  };
  const onCancel = () => {
    close(true);
  };
  const onClose = (isCancelled?: boolean) => {
    close(isCancelled ?? false);
    formikRef.current?.setValues(initialValues);
    formikRef.current?.resetForm();
  };
  const onSubmitClick = () => formikRef.current?.handleSubmit();
  const onSubmit = (v: typeof initialValues) => {
    let params: SetIncomeP = {
      company_name: v.company_name,
      amount: v.amount,
      vat_percent: v.vat_percent,
      income_tax_discount: v.income_tax_discount,
      billed_at: v.billed_at,
      external: v.external,
      notes: v.notes,
    };
    if (data?.id) params.id = data.id;
    dispatch(
      setIncome({
        params,
        onSuccess: (message) => {
          onClose();
          message && toaster.push(<Notify header={message} type={"success"} />);
        },
        onError: (message) => {
          message && toaster.push(<Notify header={message} type={"error"} />);
        },
      })
    );
  };
  const onDeleteClick = () => {
    if (data?.id) {
      dispatch(
        deleteIncome({
          params: { id: data.id },
          onSuccess: (message) => {
            onClose();
            message &&
              toaster.push(<Notify header={message} type={"success"} />);
          },
          onError: (message) => {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
    }
  };
  return (
    <Modal open={visible} onClose={onCancel} onOpen={onOpen}>
      <Modal.Header>
        <Modal.Title style={{ fontWeight: "bolder" }}>
          {strings.getString("income_set")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Panel bordered>
          <Formik
            innerRef={formikRef}
            validationSchema={SCHEMA}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              onSubmit(values);
            }}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form fluid onSubmit={(s, e) => handleSubmit(e)}>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("income_company_name")}
                  </Form.ControlLabel>
                  <Form.Control
                    name="company_name"
                    readOnly={is_read_only}
                    onChange={handleChange("company_name")}
                    onBlur={handleBlur("company_name")}
                    value={values.company_name}
                    errorMessage={touched.company_name && errors.company_name}
                  />
                </Form.Group>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Form.Group style={{ marginRight: "1rem" }}>
                    <Form.ControlLabel>
                      {strings.getString("income_amount")}
                      <Whisper
                        speaker={
                          <Popover>
                            {strings.getString("income_vat_included")}
                          </Popover>
                        }
                      >
                        <InfoOutline style={{ marginLeft: 10 }} />
                      </Whisper>
                    </Form.ControlLabel>
                    <InputNumber
                      name="amount"
                      readOnly={is_read_only}
                      onChange={(t) => handleChange("amount")(String(t))}
                      onBlur={handleBlur("amount")}
                      value={values.amount}
                      postfix={"₺"}
                      min={1}
                    />
                  </Form.Group>
                  <Form.Group style={{ marginRight: "1rem" }}>
                    <Form.ControlLabel>
                      {strings.getString("income_vat_percent")}
                    </Form.ControlLabel>
                    <InputGroup disabled={values.external}>
                      {VAT_PERCENTAGES.map((v) => (
                        <InputGroup.Button
                          key={v}
                          disabled={is_read_only}
                          onClick={() => handleChange("vat_percent")(String(v))}
                        >
                          {v}
                        </InputGroup.Button>
                      ))}
                      <InputNumber
                        name="vat_percent"
                        readOnly={is_read_only}
                        onChange={(t) => handleChange("vat_percent")(String(t))}
                        onBlur={handleBlur("vat_percent")}
                        value={values.vat_percent}
                        postfix={"%"}
                        min={0}
                        max={100}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.ControlLabel>
                      {strings.getString("income_billing_date")}
                    </Form.ControlLabel>
                    <DatePicker
                      readOnly={is_read_only}
                      size="md"
                      placeholder="Large"
                      onChange={(date) => {
                        setFieldValue(
                          "billed_at",
                          (date ?? new Date()).toISOString().split("T")[0]
                        );
                      }}
                      value={new Date(values.billed_at)}
                    />
                  </Form.Group>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {settings.tax_discount && (
                    <Form.Group style={{ marginRight: "1rem" }}>
                      <Form.ControlLabel>
                        {strings.getString("income_tax_discount")}
                      </Form.ControlLabel>
                      <InputNumber
                        disabled={values.external}
                        name="income_tax_discount"
                        readOnly={is_read_only}
                        onChange={(t) =>
                          handleChange("income_tax_discount")(String(t))
                        }
                        onBlur={handleBlur("income_tax_discount")}
                        value={values.income_tax_discount}
                        postfix={"%"}
                        defaultValue={0}
                        min={0}
                        max={100}
                      />
                    </Form.Group>
                  )}
                  <Form.Group style={{ marginBottom: "1rem" }}>
                    <Form.ControlLabel>
                      {strings.getString("income_external")}
                    </Form.ControlLabel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Toggle
                        readOnly={is_read_only}
                        style={{ display: "flex", alignSelf: "center" }}
                        checked={values.external}
                        onChange={(c) => {
                          setFieldValue("external", c);
                          if (c) {
                            setFieldValue("income_tax_discount", "0");
                            setFieldValue("vat_percent", "0");
                          }
                        }}
                      />
                      <Whisper
                        placement="bottom"
                        speaker={
                          <Popover>
                            {strings.getString("income_external_description")}
                          </Popover>
                        }
                      >
                        <InfoOutline style={{ marginLeft: 10 }} />
                      </Whisper>
                    </div>
                  </Form.Group>
                </div>
                <Form.Group>
                  <Form.ControlLabel>
                    {strings.getString("income_notes")}
                  </Form.ControlLabel>
                  <Input
                    name="notes"
                    readOnly={is_read_only}
                    onChange={handleChange("notes")}
                    onBlur={handleBlur("notes")}
                    value={values.notes}
                    as={"textarea"}
                    style={{ minHeight: "5rem" }}
                  />
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Panel>
      </Modal.Body>
      <Modal.Footer
        as={View}
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        {!is_read_only && is_edit && (
          <Button onClick={onDeleteClick} appearance="ghost">
            {strings.getString("income_delete")}
          </Button>
        )}
        <View
          style={{
            flexDirection: "row",
            alignSelf: "flex-end",
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {!is_read_only && (
            <Button onClick={onSubmitClick} appearance="primary">
              {strings.getString("income_save")}
            </Button>
          )}
          <Button onClick={onCancel} appearance="subtle">
            {strings.getString("cancel")}
          </Button>
        </View>
      </Modal.Footer>
    </Modal>
  );
};

export default Add;
