/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { useSelector } from "react-redux";
import { Checkbox as CheckboxComponent, CheckboxProps } from "rsuite";
import { getLoadingStateSelector } from "../redux/app";
const Checkbox = ({ disabled, ...props }: CheckboxProps) => {
  const loading = useSelector(getLoadingStateSelector);
  return <CheckboxComponent {...props} disabled={loading || disabled} />;
};

export default Checkbox;
