/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { useSelector } from "react-redux";
import { IconButton as IconButtonComponent, IconButtonProps } from "rsuite";
import { getLoadingStateSelector } from "../redux/app";
const IconButton = ({ disabled, children, ...props }: IconButtonProps) => {
  const loading = useSelector(getLoadingStateSelector);
  return (
    <IconButtonComponent
      appearance="primary"
      {...props}
      loading={loading || disabled}
    >
      {children}
    </IconButtonComponent>
  );
};

export default IconButton;
