import { PieChart, RadarChart, RadarLine } from "@rsuite/charts";
import dayjs from "dayjs";
import _ from "lodash";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { getThemeSelector } from "../../../redux/app";
import { getStringSelector } from "../../../redux/locale";
import { MonthType } from "../../../types";
import { displayCurrency } from "../../../utils";
import CardField from "./Card/Field";

interface Props {
  data: MonthType[];
  year: number;
}
const Month: FC<Props> = ({ data, year }) => {
  const strings = useSelector(getStringSelector);
  const theme = useSelector(getThemeSelector);

  const month_data: MonthType | undefined = useMemo(
    () => data[dayjs().get("month")],
    [data]
  );
  const month_total = useMemo(() => {
    let net = 0,
      vat = 0;
    if (month_data) {
      net = month_data.income - (month_data.tax + month_data.expense_like);
      vat =
        month_data.expense -
        month_data.expense_wo_vat -
        (month_data.income - month_data.income_wo_vat);
      net += vat;
    }
    return {
      income: Math.round(month_data?.income ?? 0),
      expense: Math.round(month_data?.expense_like ?? 0),
      tax: Math.round(month_data?.tax ?? 0),
      vat: Math.round(vat),
      net: Math.round(net),
    };
  }, [month_data]);
  const pie_chart: any = useMemo(() => {
    if (month_data && month_data.income !== 0 && month_data.expense !== 0) {
      let net = month_data.income - (month_data.tax + month_data.expense_like);
      net +=
        month_data.expense -
        month_data.expense_wo_vat -
        (month_data.income - month_data.income_wo_vat);
      const ratio = 100 / month_data.income;
      const t = parseFloat((month_data.tax * ratio).toFixed(1));
      const n = parseFloat((net * ratio).toFixed(1));
      const e = (100 - (n + t)).toFixed(1);
      return [
        [strings.getString("month_earning"), n],
        [strings.getString("month_expense"), e],
        [strings.getString("month_tax"), t],
      ];
    }
    return [
      [strings.getString("total_earning"), 0],
      [strings.getString("total_expense"), 0],
      [strings.getString("total_tax"), 0],
    ];
  }, [month_data, year, strings]);

  const avg = useMemo(() => {
    let income = 0,
      income_vat = 0,
      expense = 0,
      expense_vat = 0,
      tax = 0,
      vat = 0,
      net = 0,
      total_month = 0;
    data.forEach((d) => {
      if (d.income > 0 || d.expense > 0 || d.tax > 0) {
        total_month++;
        income += d.income;
        expense += d.expense_like;
        income_vat += d.income - d.income_wo_vat;
        expense_vat += d.expense - d.expense_wo_vat;
        tax += d.tax;
      }
    });
    net = income - (tax + expense);
    vat = expense_vat - income_vat;
    net += vat;
    income /= total_month;
    expense /= total_month;
    tax /= total_month;
    vat /= total_month;
    net /= total_month;
    return {
      income: Math.round(income),
      expense: Math.round(expense),
      tax: Math.round(tax),
      vat: Math.round(vat),
      net: Math.round(net),
    };
  }, [data]);

  const radar_chart: any = useMemo(() => {
    if (month_data) {
      const def_value = 100;
      const muliplier = 1.2;
      return [
        [
          strings.getString("month_income"),
          (_.max([month_data.income, avg.income]) ?? def_value) * muliplier,
          month_data.income,
          avg.income,
        ],
        [
          strings.getString("month_expense"),
          (_.max([month_data.expense, avg.expense]) ?? def_value) * muliplier,
          month_data.expense,
          avg.expense,
        ],
        [
          strings.getString("month_tax"),
          (_.max([month_data.tax, avg.tax]) ?? def_value) * muliplier,
          month_data.tax,
          avg.tax,
        ],
        [
          strings.getString("month_earning"),
          (_.max([month_total.net, avg.net]) ?? def_value) * muliplier,
          month_total.net,
          avg.net,
        ],
      ];
    }
    return undefined;
  }, [month_data, avg, strings]);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowY: "scroll",
        maxHeight: "88vh",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        justifyContent: "flex-start",
        alignSelf: "stretch",
      }}
    >
      <span style={{ fontSize: "2rem", fontWeight: "bold" }}>
        {dayjs().set("year", year).format("MMMM YYYY")}
      </span>
      <div
        style={{
          marginTop: "2rem",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <div style={{ display: "flex", flex: 1 }}>
          <PieChart
            data={pie_chart}
            label={{
              color: theme === "dark" ? "white" : "black",
              fontWeight: "bold",
            }}
            emphasis={{ scaleSize: 12 }}
            style={{ minWidth: 200, width: "100%" }}
            legend={false}
          />
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CardField
            title={strings.getString("month_income")}
            value={displayCurrency(month_total.income)}
          />
          <CardField
            title={strings.getString("month_expense")}
            value={displayCurrency(month_total.expense)}
          />
          <CardField
            title={strings.getString("month_tax")}
            value={displayCurrency(month_total.tax)}
          />
          <CardField
            title={strings.getString("month_vat")}
            direction={month_total.vat < 0 ? "down" : "up"}
            value={displayCurrency(Math.abs(month_total.vat))}
          />
          <CardField
            title={strings.getString("month_earning")}
            value={displayCurrency(month_total.net)}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: "2rem",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardField
            title={strings.getString("month_avg_income")}
            value={displayCurrency(avg?.income)}
          />
          <CardField
            title={strings.getString("month_avg_expense")}
            value={displayCurrency(avg?.expense)}
          />
          <CardField
            title={strings.getString("month_avg_tax")}
            value={displayCurrency(avg?.tax ?? 0)}
          />
          <CardField
            title={strings.getString("month_avg_vat")}
            direction={(avg?.vat ?? 0) < 0 ? "down" : "up"}
            value={displayCurrency(Math.abs(avg?.vat ?? 0))}
          />
          <CardField
            title={strings.getString("month_avg_earning")}
            value={displayCurrency(avg?.net ?? 0)}
          />
        </div>
        <div style={{ display: "flex", flex: 1, alignItems: "flex-start" }}>
          {radar_chart !== undefined && (
            <RadarChart
              data={radar_chart}
              style={{ minWidth: 200, width: "100%" }}
            >
              <RadarLine name={strings.getString("month_this")} />
              <RadarLine name={strings.getString("month_avg")} />
            </RadarChart>
          )}
        </div>
      </div>
    </div>
  );
};

export default Month;
