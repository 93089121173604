import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Panel, toaster } from "rsuite";
import { Button, Input, Notify } from "../../../components";
import { sendGibSms, signGibInvoice } from "../../../redux/actions";
import { getStringSelector } from "../../../redux/locale";

interface Props {
  uuid?: string;
  close: () => void;
}
function Sign({ uuid, close }: Props) {
  const strings = useSelector(getStringSelector);
  const dispatch = useDispatch();
  const [id, setId] = useState<string>("");
  const [code, setCode] = useState<string>("");

  const onClose = () => {
    setId("");
    setCode("");
    close();
  };
  const sendSms = () => {
    if (uuid) {
      dispatch(
        sendGibSms({
          onSuccess(payload) {
            if (payload) {
              toaster.push(
                <Notify header={payload.message} type={"success"} />
              );
              setId(payload.id);
            }
          },
          onError(message, payload) {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
    }
  };
  const confirmInvoice = () => {
    if (uuid !== undefined && id !== "" && code !== "") {
      dispatch(
        signGibInvoice({
          params: {
            uuid,
            code,
            id,
          },
          onSuccess(message) {
            message &&
              toaster.push(<Notify header={message} type={"success"} />);
            onClose();
          },
          onError(message, payload) {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
    }
  };
  return (
    <Modal open={uuid !== undefined} onClose={onClose} size="sm">
      <Modal.Header>
        <Modal.Title style={{ fontWeight: "bolder" }}>
          {strings.getString("gib_invoice_sign")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {id === "" ? (
          <Panel bordered style={{ paddingBottom: 10, width: "100%" }}>
            <span>{strings.getString("gib_invoice_sign_description")}</span>
          </Panel>
        ) : (
          <Panel bordered style={{ paddingBottom: 10, width: "100%" }}>
            <span>{strings.getString("gib_invoice_code")}</span>
            <Input
              name="code"
              autoComplete="one-time-code"
              onChange={(t) => setCode(String(t))}
              value={code}
            />
          </Panel>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: "flex",
            alignSelf: "stretch",
            justifyContent: "space-between",
          }}
        >
          {id === "" ? (
            <Button onClick={sendSms} appearance="primary">
              {strings.getString("gib_invoice_send_sms")}
            </Button>
          ) : (
            <Button
              color={id ? "green" : "red"}
              onClick={confirmInvoice}
              appearance="primary"
            >
              {strings.getString("gib_invoice_sign_confirm")}
            </Button>
          )}
          <Button onClick={onClose} appearance="subtle">
            {strings.getString("cancel")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default Sign;
