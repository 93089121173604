import Lottie from "lottie-react";
import { useEffect } from "react";
import { isMobileOnly } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import rotate_light from "../../assets/animations/rotate-mobile-light.json";
import rotate from "../../assets/animations/rotate-mobile.json";
import { PanelHeader, PanelSideNav } from "../../components";
import {
  getMenuSelector,
  getThemeSelector,
  setActiveRouteKey,
} from "../../redux/app";
import { getStringSelector } from "../../redux/locale";
import { isAdminSelector } from "../../redux/user";
import { useDimensions } from "../../utils";
import {
  Brackets,
  Calculate,
  Expenses,
  Gib,
  Home,
  Incomes,
  Ledger,
  LedgerExpenses,
  LedgerIncomes,
  Settings,
} from "./Screens";

function Panel() {
  let location = useLocation();
  const navigate = useNavigate();
  const { isLandscape } = useDimensions();
  const strings = useSelector(getStringSelector);
  const is_admin = useSelector(isAdminSelector);
  const theme = useSelector(getThemeSelector);
  const menu = useSelector(getMenuSelector);
  const dispatch = useDispatch();
  function getScreen(id: string) {
    switch (id) {
      case "1":
        return <Home />;
      case "2":
        return <Calculate />;
      case "3":
        return <Incomes />;
      case "4":
        return <Expenses />;
      case "5":
        return <Ledger />;
      case "6":
        return <LedgerIncomes />;
      case "7":
        return <LedgerExpenses />;
      case "8":
        return <Brackets />;
      case "9":
        return <Gib />;
      case "10":
        return <Settings />;

      default:
        return null;
    }
  }
  useEffect(() => {
    menu.some((m) => {
      if (m.url == location.pathname) {
        dispatch(setActiveRouteKey(m.id));
        return true;
      }
    });
  }, [location, menu]);

  useEffect(() => {
    if (!is_admin && location.pathname === "/" && menu.length > 0) {
      navigate(menu[0].url);
    }
  }, [location, menu, is_admin]);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignSelf: "stretch",
        height: "100vh",
      }}
    >
      <PanelSideNav />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <PanelHeader />
        {menu.length > 0 && (
          <Routes location={location}>
            {menu.map((m) => (
              <Route key={m.id} path={m.url} element={getScreen(m.id)} />
            ))}
          </Routes>
        )}
      </div>
      {isMobileOnly && !isLandscape && (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme === "dark" ? "#1a1d24" : "#FFFFFF",
            zIndex: 999,
          }}
        >
          <Lottie animationData={theme === "dark" ? rotate : rotate_light} />
          <span
            style={{
              fontSize: "2rem",
              fontStyle: "italic",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {strings.getString("landscape_only")}
          </span>
        </div>
      )}
    </div>
  );
}
export default Panel;
