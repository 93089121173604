import { BarLineChart, Grid, InfoOutline } from "@rsuite/icons";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Whisper, toaster } from "rsuite";
import { Checkbox, Nav, Notify, ScreenWrapper } from "../../../components";
import { getLedger } from "../../../redux/actions";
import { getSelectedYearSelector } from "../../../redux/app";
import { getStringSelector } from "../../../redux/locale";
import { IncomeType, MonthType } from "../../../types";
import { getTaxCalculationResult } from "../../../utils";
import Period from "./Period";
import Total from "./Total";

export const initialMonth: MonthType = {
  income: 0,
  income_wo_vat: 0,
  income_count: 0,
  expense: 0,
  expense_like: 0,
  expense_wo_vat: 0,
  tax_basis: 0,
  tax: 0,
  tax_brackets: [],
};
type TabType = "total" | "period";
function Home() {
  const strings = useSelector(getStringSelector);
  const dispatch = useDispatch();
  const selected_year = useSelector(getSelectedYearSelector);
  const [data, setData] = useState<MonthType[]>([]);
  const [tab, setTab] = useState<TabType>("total");
  const [annual, setAnnual] = useState<boolean>(true);

  useEffect(() => {
    if (selected_year) {
      dispatch(
        getLedger({
          params: { year: selected_year, annual },
          onSuccess(payload) {
            let d: MonthType[] = Array.from({ length: 12 }, () =>
              JSON.parse(JSON.stringify(initialMonth))
            );
            if (payload) {
              let table = payload?.brackets ?? [];
              payload.expenses.forEach((e) => {
                const month_index = dayjs(e.due_date, "YYYY-MM-DD").get(
                  "month"
                );

                let expense =
                  (d[month_index].expense ?? 0) + parseFloat(e.amount);
                let expense_wo_vat =
                  (d[month_index].expense_wo_vat ?? 0) +
                  ((parseFloat(e.amount) - parseFloat(e.amount_over_vat)) /
                    (1 + e.vat_percent / 100) +
                    parseFloat(e.amount_over_vat));
                d[month_index].expense = expense;
                d[month_index].expense_wo_vat = expense_wo_vat;
              });

              let income_tax = payload.incomes.map(
                (pi) =>
                  ({
                    amount: parseFloat(pi.amount),
                    amount_wo_vat:
                      parseFloat(pi.amount) / (1 + pi.vat_percent / 100),
                    percent: 1 - parseInt(pi.income_tax_discount) / 100,
                    month: dayjs(pi.billed_at, "YYYY-MM-DD").get("month"),
                    tax_brackets: [],
                    income_count: 0,
                    tax: 0,
                    tax_basis: 0,
                    tax_ignore: pi.external,
                  } as IncomeType)
              );
              income_tax.forEach((it) => {
                d[it.month].income_count += 1;
              });
              let toplam_komulatif_gelir_matrahi = 0;
              let islenmeyen_gider_toplami: number = 0;
              let islenmeyen_gider_aylari: number[] = [];
              d.forEach((dit, i) => {
                if (
                  dit.expense > 0 &&
                  income_tax.filter((it) => it.month === i).length === 0
                ) {
                  islenmeyen_gider_aylari.push(i);
                  islenmeyen_gider_toplami += dit.expense_wo_vat;
                }
              });
              income_tax.forEach((it, iti) => {
                let gider =
                  d[it.month].expense_wo_vat / d[it.month].income_count;
                if (islenmeyen_gider_aylari.length > 0) {
                  if (iti === income_tax.length - 1) {
                    //Son gelir
                    gider += islenmeyen_gider_toplami;
                  } else {
                    //Bir önce gelen aydan gideri eklemeye başla
                    if (islenmeyen_gider_aylari.includes(it.month - 1)) {
                      gider += d[it.month - 1].expense_wo_vat;
                      islenmeyen_gider_aylari = islenmeyen_gider_aylari.filter(
                        (iga) => iga !== it.month - 1
                      );
                      islenmeyen_gider_toplami -=
                        d[it.month - 1].expense_wo_vat;
                    }
                  }
                }
                const {
                  dahil_olunan_vergi_dilimleri,
                  komulatif_gelir_matrahi,
                  vergi,
                } = getTaxCalculationResult(
                  toplam_komulatif_gelir_matrahi,
                  table,
                  it.amount_wo_vat,
                  gider,
                  it.percent
                );
                income_tax[iti].tax_basis =
                  komulatif_gelir_matrahi - toplam_komulatif_gelir_matrahi;
                income_tax[iti].tax_brackets = dahil_olunan_vergi_dilimleri;
                income_tax[iti].tax = vergi;
                toplam_komulatif_gelir_matrahi = komulatif_gelir_matrahi;
              });
              income_tax.forEach((it) => {
                d[it.month].income += it.amount;
                d[it.month].income_wo_vat += it.amount_wo_vat;
                d[it.month].tax += it.tax;
                d[it.month].tax_basis += it.tax_basis;
                d[it.month].tax_brackets = _.uniq([
                  ...d[it.month].tax_brackets,
                  ...it.tax_brackets,
                ]);
              });
            }
            setData(d);
          },
          onError(message, payload) {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
    }
  }, [selected_year, annual]);

  return (
    <ScreenWrapper style={{ justifyContent: "flex-start" }}>
      <Nav
        activeKey={tab}
        appearance="tabs"
        onSelect={setTab}
        style={{ display: "flex", alignSelf: "stretch" }}
      >
        <Nav.Item eventKey="total" icon={<BarLineChart />}>
          {strings.getString("ledger_tab_total")}
        </Nav.Item>
        <Nav.Item eventKey="period" icon={<Grid />}>
          {strings.getString("ledger_tab_periods")}
        </Nav.Item>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Checkbox checked={annual} onChange={(v, c) => setAnnual(c)}>
            {strings.getString(
              annual
                ? "ledger_tab_end_of_the_year_expenses_included"
                : "ledger_tab_end_of_the_year_expenses_excluded"
            )}
          </Checkbox>
          <Whisper
            placement="bottomEnd"
            speaker={
              <Popover>
                {strings.getString(
                  "ledger_tab_end_of_the_year_expenses_description"
                )}
              </Popover>
            }
          >
            <InfoOutline style={{ marginLeft: 10, marginRight: 10 }} />
          </Whisper>
        </div>
      </Nav>
      {tab === "total" && <Total data={data} />}
      {tab === "period" && <Period data={data} />}
    </ScreenWrapper>
  );
}

export default Home;
