import CheckIcon from "@rsuite/icons/Check";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import CloseIcon from "@rsuite/icons/Close";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import FolderFillIcon from "@rsuite/icons/FolderFill";
import SearchIcon from "@rsuite/icons/Search";
import TimeRoundIcon from "@rsuite/icons/TimeRound";
import dayjs from "dayjs";
import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CellProps,
  Divider,
  InputGroup,
  Pagination,
  Popover,
  Whisper,
  toaster,
} from "rsuite";
import {
  IconButton,
  Input,
  Notify,
  ScreenWrapper,
  SelectPicker,
  Table,
  View,
} from "../../../components";
import {
  getSelectedYearSelector,
  getSideNavExpandedSelector,
} from "../../../redux/app";
import { getStringSelector } from "../../../redux/locale";
import {
  getLedgerIncomes,
  setLedgerIncomeConfirmation,
} from "../../../redux/transactions";
import { IncomeObject } from "../../../redux/transactions/types/objects";
import { GetLedgerIncomesP } from "../../../redux/types";
import { getSettingsSelector } from "../../../redux/user";
import { PaginationResponse } from "../../../types";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_WIDTH_EXPANDED,
  TABLE_LIMIT_OPTIONS,
  TABLE_LIMIT_OPTIONS_DEFAULT_INDEX,
  displayCurrency,
  getLedgerIncomeTypeList,
  useDimensions,
} from "../../../utils";
import Documents from "../Incomes/Documents";
const { Column, Cell, HeaderCell } = Table;

function intOrUndefined(page?: string): number | undefined {
  if (!page) return undefined;
  return parseInt(page);
}
function LedgerIncomes() {
  const strings = useSelector(getStringSelector);
  const dispatch = useDispatch();
  const settings = useSelector(getSettingsSelector);
  const selected_year = useSelector(getSelectedYearSelector);
  const is_expanded = useSelector(getSideNavExpandedSelector);
  const { isDesktop, width } = useDimensions();
  const expanded = useMemo(
    () => is_expanded && isDesktop,
    [is_expanded, isDesktop]
  );
  const tableWidth = useMemo(
    () => (expanded ? width - SIDE_NAV_WIDTH_EXPANDED : width - SIDE_NAV_WIDTH),
    [width, expanded]
  );
  const [search, setSearch] = useState<string>("");
  const [modalDoc, setModalDoc] = useState<number | undefined>(undefined);
  const [list_data, setListData] = useState<PaginationResponse<IncomeObject>>();
  const [selected_month, setSelectedMonth] = useState<number>(
    dayjs().get("month") + 1
  );
  const [selected_confirmation, setSelectedConfirmation] = useState<
    "confirmed" | "pending-confirmation" | undefined
  >();
  const months = useMemo(
    () => dayjs.months().map((m, i) => ({ label: m, id: i + 1 })),
    []
  );

  const confirmation_list = useMemo(
    () => getLedgerIncomeTypeList(strings),
    [strings]
  );
  const handleChangeLimit = (new_limit: number) => {
    getTableData(1, new_limit);
  };
  const handleChangePage = (new_page: number) => {
    getTableData(new_page, intOrUndefined(list_data?.per_page));
  };
  const onInputChange = useCallback((e: string) => setSearch(e), []);
  const onSearchClick = () => {
    getTableData(1, intOrUndefined(list_data?.per_page), search);
  };
  const onSearchClearClick = () => {
    setSearch("");
    getTableData(1, intOrUndefined(list_data?.per_page));
  };
  const onInputKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e?.nativeEvent.key === "Enter") {
        onSearchClick();
      }
    },
    [search]
  );
  const getTableData = (page?: number, limit?: number, search?: string) => {
    if (selected_year) {
      let params: GetLedgerIncomesP = {
        year: selected_year,
        page: page || list_data?.current_page || 1,
        per_page: limit,
      };
      if (!params.per_page)
        params.per_page = list_data?.per_page
          ? parseInt(list_data!.per_page)
          : TABLE_LIMIT_OPTIONS[TABLE_LIMIT_OPTIONS_DEFAULT_INDEX];
      if (selected_month) params.month = selected_month;
      if (search) params.search = search;
      if (selected_confirmation)
        params.confirmed = selected_confirmation === "confirmed";
      dispatch(
        getLedgerIncomes({
          params,
          onSuccess: (data) => {
            setListData(data);
          },
          onError: (message) => {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
    }
  };
  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_year, selected_month, selected_confirmation]);

  const DocumentCell = ({ rowData, dataKey, ...props }: CellProps) => {
    const onViewClick = (id: number) => {
      setModalDoc(id);
    };
    if (!dataKey) return null;
    return (
      <Cell {...props} className="link-group">
        <IconButton
          icon={<FolderFillIcon />}
          onClick={() => {
            if (rowData["documents_count"] > 0) onViewClick(rowData[dataKey]);
          }}
        >
          <span style={{ fontSize: "1.2rem" }}>
            {rowData["documents_count"]}
          </span>
        </IconButton>
      </Cell>
    );
  };

  const EditCell = ({ rowData, dataKey, ...props }: CellProps) => {
    const onConfirmationClick = (id: number) => {
      dispatch(
        setLedgerIncomeConfirmation({
          params: { id, confirm: !rowData["confirmed"] },
          onSuccess: (message) => {
            getTableData();
            message &&
              toaster.push(<Notify header={message} type={"success"} />);
          },
          onError: (message) => {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
    };
    if (!dataKey) return null;
    return (
      <Cell {...props} className="link-group">
        <IconButton
          color={rowData["confirmed"] ? "yellow" : undefined}
          icon={rowData["confirmed"] ? <CloseIcon /> : <CheckIcon />}
          onClick={() => onConfirmationClick(rowData[dataKey])}
        >
          <span>
            {strings.getString(
              rowData["confirmed"]
                ? "ledger_income_action_un_confirm"
                : "ledger_income_action_confirm"
            )}
          </span>
        </IconButton>
      </Cell>
    );
  };

  const onDocumentsClose = (refresh: boolean) => {
    setModalDoc(undefined);
  };

  return (
    <ScreenWrapper style={{ justifyContent: "flex-start" }}>
      <View
        row
        style={{
          justifyContent: "space-between",
          alignSelf: "stretch",
          marginRight: 10,
          marginLeft: 10,
          marginBottom: 10,
        }}
      >
        <SelectPicker
          size="lg"
          placement="bottomStart"
          cleanable={true}
          value={selected_confirmation}
          onChange={setSelectedConfirmation}
          data={confirmation_list}
        />
        <InputGroup
          size="lg"
          inside
          style={{ width: width * 0.2, minWidth: 250 }}
        >
          <InputGroup.Button onClick={onSearchClick}>
            <SearchIcon />
          </InputGroup.Button>
          <Input
            value={search}
            style={{ paddingLeft: 50 }}
            onChange={onInputChange}
            placeholder={strings.getString("expense_search_placeholder")}
            onKeyDown={onInputKeyDown}
          />
          {search.length > 0 && (
            <InputGroup.Button onClick={onSearchClearClick}>
              <CloseOutlineIcon />
            </InputGroup.Button>
          )}
        </InputGroup>
        <SelectPicker
          size="lg"
          placement="bottomEnd"
          cleanable={true}
          value={selected_month}
          labelKey="label"
          valueKey="id"
          onChange={setSelectedMonth}
          data={months}
        />
      </View>
      <div className="table-wrapper">
        <Table
          fillHeight
          data={list_data?.data || []}
          width={tableWidth}
          rowHeight={54}
        >
          <Column width={50} align="center" verticalAlign="middle" fixed>
            <HeaderCell>#</HeaderCell>
            <Cell dataKey="id" style={{ fontSize: 18 }}>
              {(d, i) => `#${i != undefined ? i + (list_data?.from ?? 1) : ""}`}
            </Cell>
          </Column>
          <Column width={50} align="center" verticalAlign="middle" fixed>
            <HeaderCell> </HeaderCell>
            <Cell dataKey="confirmed" style={{ fontSize: 18 }}>
              {(d) => (
                <Whisper
                  placement="autoVerticalStart"
                  speaker={
                    <Popover>
                      <p>
                        {strings.getString(
                          d["confirmed"]
                            ? "ledger_income_confirmed_description"
                            : "ledger_income_pending_description"
                        )}
                      </p>
                    </Popover>
                  }
                >
                  <span>
                    {d["confirmed"] ? (
                      <CheckRoundIcon color="green" />
                    ) : (
                      <TimeRoundIcon />
                    )}
                  </span>
                </Whisper>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} verticalAlign={"middle"}>
            <HeaderCell>{strings.getString("income_company_name")}</HeaderCell>
            <Cell dataKey="company_name" />
          </Column>
          <Column width={100} verticalAlign={"middle"}>
            <HeaderCell>{strings.getString("income_amount")}</HeaderCell>
            <Cell dataKey="amount">
              {(d) => (
                <Whisper
                  placement="autoVertical"
                  disabled={d["vat_percent"] === 0}
                  speaker={
                    <Popover title={strings.getString("income_without_vat")}>
                      <p>
                        {displayCurrency(
                          d["amount"] / (1 + d["vat_percent"] / 100)
                        )}
                      </p>
                    </Popover>
                  }
                >
                  <span>{`${displayCurrency(d["amount"])}`}</span>
                </Whisper>
              )}
            </Cell>
          </Column>
          <Column width={80} verticalAlign={"middle"} align="center">
            <HeaderCell>{strings.getString("income_vat_percent")}</HeaderCell>
            <Cell dataKey="vat_percent">
              {(d) => (
                <Whisper
                  placement="autoVertical"
                  disabled={d["vat_percent"] === 0}
                  speaker={
                    <Popover title={strings.getString("income_vat_amount")}>
                      <p>
                        {displayCurrency(
                          d["amount"] -
                            d["amount"] / (1 + d["vat_percent"] / 100)
                        )}
                      </p>
                    </Popover>
                  }
                >
                  <span>{`${d["vat_percent"]}%`}</span>
                </Whisper>
              )}
            </Cell>
          </Column>
          {settings.tax_discount && (
            <Column width={120} verticalAlign={"middle"} align="center">
              <HeaderCell>
                {strings.getString("income_tax_discount")}
              </HeaderCell>
              <Cell dataKey="income_tax_discount">
                {(d) => `${d["income_tax_discount"]}%`}
              </Cell>
            </Column>
          )}
          <Column width={100} verticalAlign={"middle"} align="center">
            <HeaderCell>{strings.getString("income_billing_date")}</HeaderCell>
            <Cell dataKey="billed_at">
              {(d) => dayjs(d["billed_at"]).format("L")}
            </Cell>
          </Column>
          <Column width={150} verticalAlign={"middle"}>
            <HeaderCell> </HeaderCell>
            <EditCell dataKey="id" />
          </Column>
          <Column width={100} verticalAlign={"middle"}>
            <HeaderCell> </HeaderCell>
            <DocumentCell dataKey="id" />
          </Column>
        </Table>
        <div>
          <Divider className="table-pagination-divider" />
          <Pagination
            className="table-pagination"
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="sm"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={list_data?.total || 0}
            limitOptions={TABLE_LIMIT_OPTIONS}
            limit={
              intOrUndefined(list_data?.per_page) ??
              TABLE_LIMIT_OPTIONS[TABLE_LIMIT_OPTIONS_DEFAULT_INDEX]
            }
            activePage={list_data?.current_page || 0}
            onChangePage={handleChangePage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
      <Documents
        visible={modalDoc !== undefined}
        id={modalDoc}
        readOnly={true}
        type="income"
        close={onDocumentsClose}
      />
    </ScreenWrapper>
  );
}
export default LedgerIncomes;
