import { Growth, Table } from "@rsuite/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toaster } from "rsuite";
import { Nav, Notify, ScreenWrapper } from "../../../components";
import { getTaxBrackets } from "../../../redux/actions";
import { getSelectedYearSelector } from "../../../redux/app";
import { getStringSelector } from "../../../redux/locale";
import { TaxBracketObject } from "../../../redux/types";
import Avg from "./Avg";
import MonthByMonth from "./MonthByMonth";

type TabType = "avg" | "month";
function Home() {
  const strings = useSelector(getStringSelector);
  const dispatch = useDispatch();
  const selected_year = useSelector(getSelectedYearSelector);
  const [brackets, setBrackets] = useState<TaxBracketObject[]>([]);
  const [tab, setTab] = useState<TabType>("avg");

  useEffect(() => {
    if (selected_year) {
      dispatch(
        getTaxBrackets({
          params: { year: selected_year },
          onSuccess(payload) {
            setBrackets(payload?.data || []);
          },
          onError(message, payload) {
            message && toaster.push(<Notify header={message} type={"error"} />);
          },
        })
      );
    }
  }, [selected_year]);

  return (
    <ScreenWrapper style={{ justifyContent: "flex-start" }}>
      <Nav
        activeKey={tab}
        appearance="tabs"
        onSelect={setTab}
        style={{ display: "flex", alignSelf: "stretch" }}
      >
        <Nav.Item eventKey="avg" icon={<Growth />}>
          {strings.getString("calculate_tab_avg")}
        </Nav.Item>
        <Nav.Item eventKey="month" icon={<Table />}>
          {strings.getString("calculate_tab_calculation")}
        </Nav.Item>
      </Nav>
      {tab === "avg" && <Avg brackets={brackets} />}
      {tab === "month" && <MonthByMonth brackets={brackets} />}
    </ScreenWrapper>
  );
}

export default Home;
