import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppContent from "./AppContent";
import reduxStore from "./redux";
import { DropzoneProvider } from "./utils/useDropzone";

const { store, persistor } = reduxStore();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DropzoneProvider>
          <AppContent />
        </DropzoneProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
