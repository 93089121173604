import { LocalizedStrings } from "react-localization";
import { ExpenseType, TaxBracketObject } from "../redux/types";
import { GibInvoiceTypes } from "./constants";

export const displayCurrency = (amount: number, currency: string = "TRY") => {
  if (isNaN(amount)) {
    switch (currency) {
      case "EUR":
        return "€";
      case "USD":
        return "$";
      case "TRY":
      default:
        return "₺";
    }
  }
  if ((amount | 0) < amount) {
    return Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: currency,
    }).format(amount);
  }
  return new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 0,
  }).format(amount);
};

export const getCurrencySymbol = (c?: string) => {
  switch (c) {
    case "TRY":
      return "₺";
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";

    default:
      return "?";
  }
};

export const downloadFile = (data: Blob, name: string) => {
  const href = URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const getExpenseTypeList = (strings: LocalizedStrings<string>) => [
  {
    label: strings.getString("expense_type_general"),
    value: "general",
  },
  {
    label: strings.getString("expense_type_accounting"),
    value: "accounting",
  },
  {
    label: strings.getString("expense_type_tax"),
    value: "tax",
  },
  {
    label: strings.getString("expense_type_social_security_institution"),
    value: "social-security-institution",
  },
  {
    label: strings.getString("expense_type_income_tax"),
    value: "income-tax",
  },
  {
    label: strings.getString("expense_type_external"),
    value: "external",
  },
  {
    label: strings.getString("expense_type_non_allowable"),
    value: "non-allowable",
  },
];
export const getLedgerIncomeTypeList = (strings: LocalizedStrings<string>) => [
  {
    label: strings.getString("ledger_income_confirmed"),
    value: "confirmed",
  },
  {
    label: strings.getString("ledger_income_pending"),
    value: "pending",
  },
];
export const getLedgerExpenseTypeList = (strings: LocalizedStrings<string>) => [
  {
    label: strings.getString("ledger_expense_confirmed"),
    value: "confirmed",
  },
  {
    label: strings.getString("ledger_expense_pending"),
    value: "pending",
  },
  {
    label: strings.getString("ledger_expense_rejected"),
    value: "rejected",
  },
];
export const getGibInvoiceStatusList = (strings: LocalizedStrings<string>) => [
  {
    label: strings.getString("gib_signed"),
    value: "Onaylandı",
  },
  {
    label: strings.getString("gib_not_signed"),
    value: "Onaylanmadı",
  },
  {
    label: strings.getString("gib_deleted"),
    value: "Silinmiş",
  },
];
export const getGibInvoiceTypeLabel = (key: string) => {
  return GibInvoiceTypes.find((t) => t.key === key)?.label ?? key ?? "";
};
export const getExpenseVatVisibility = (t: ExpenseType): boolean => {
  switch (t) {
    case "tax":
    case "income-tax":
    case "non-allowable":
    case "social-security-institution":
      return false;
    case "accounting":
    case "external":
    default:
      return true;
  }
};
export const getTaxCalculationResult = (
  mevcut_gelir_matrahi: number,
  vergi_dilimleri: TaxBracketObject[],
  baslangic_geliri: number,
  gider: number,
  indirim_orani: number
) => {
  let komulatif_gelir_matrahi: number = mevcut_gelir_matrahi ?? 0;
  let dahil_olunan_vergi_dilimleri: number[] = [];
  let gelir = (baslangic_geliri - gider) * indirim_orani;
  let vergi = 0;

  vergi_dilimleri.some((vd) => {
    let vergi_dilimi = vd.tax_bracket ?? 0;
    if (vergi_dilimi === 0) {
      //SABIT VERGI ORANI
      dahil_olunan_vergi_dilimleri.push(vd.tax_percent);
      vergi += gelir * (vd.tax_percent / 100);
      komulatif_gelir_matrahi += gelir;
      return true;
    } else {
      //DEGISKEN VERGI ORANI
      if (komulatif_gelir_matrahi <= vergi_dilimi) {
        //KOMULATIF GELIR VERGI DILIMI DAHILINDE
        let vergi_diliminde_kalan_yer = vergi_dilimi - komulatif_gelir_matrahi;
        if (gelir <= vergi_diliminde_kalan_yer) {
          // AYLIK GELIR VERGI DILIMINI ASMIYOR
          dahil_olunan_vergi_dilimleri.push(vd.tax_percent);
          vergi += gelir * (vd.tax_percent / 100);
          komulatif_gelir_matrahi += gelir;
          return true;
        } else {
          // AYLIK GELIR SONRAKI VERGI DILIMINE TASIYOR
          gelir = gelir - vergi_diliminde_kalan_yer;
          vergi += vergi_diliminde_kalan_yer * (vd.tax_percent / 100);
          komulatif_gelir_matrahi += vergi_diliminde_kalan_yer;
          return false;
        }
      }
      return false;
    }
  });
  return { komulatif_gelir_matrahi, dahil_olunan_vergi_dilimleri, vergi };
};
