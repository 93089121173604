/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { takeLatest } from "redux-saga/effects";
import * as A from "../actions";
import * as S from "./sagas";

export function* loginWatcher() {
  yield takeLatest(A.login.toString(), S.loginSaga);
}
export function* forgotPasswordWatcher() {
  yield takeLatest(A.forgotPassword.toString(), S.forgotPasswordSaga);
}
export function* resetPasswordWatcher() {
  yield takeLatest(A.resetPassword.toString(), S.resetPasswordSaga);
}
export function* updateUserWatcher() {
  yield takeLatest(A.updateUser.toString(), S.updateUserSaga);
}
export function* updateUserSettingsWatcher() {
  yield takeLatest(A.updateUserSettings.toString(), S.updateUserSettingsSaga);
}
export function* getSettingsWatcher() {
  yield takeLatest(A.getSettings.toString(), S.getSettingsSaga);
}
export function* updateSettingsWatcher() {
  yield takeLatest(A.updateSettings.toString(), S.updateSettingsSaga);
}
export function* getExchangeRatesWatcher() {
  yield takeLatest(A.getExchangeRates.toString(), S.getExchangeRatesSaga);
}
export function* getCurrenciesWatcher() {
  yield takeLatest(A.getCurrencies.toString(), S.getCurrenciesSaga);
}
export function* updateCurrenciesWatcher() {
  yield takeLatest(A.updateCurrencies.toString(), S.updateCurrenciesSaga);
}
export function* get2FASecretWatcher() {
  yield takeLatest(A.get2FASecret.toString(), S.get2FASecretSaga);
}
export function* verify2FACodeWatcher() {
  yield takeLatest(A.verify2FACode.toString(), S.verify2FACodeSaga);
}
export function* getUsersWatcher() {
  yield takeLatest(A.getUsers.toString(), S.getUsersSaga);
}
export function* addUserWatcher() {
  yield takeLatest(A.addUser.toString(), S.addUserSaga);
}
export function* deleteUserWatcher() {
  yield takeLatest(A.deleteUser.toString(), S.deleteUserSaga);
}
export function* getGibWatcher() {
  yield takeLatest(A.getGib.toString(), S.getGibSaga);
}
export function* setGibWatcher() {
  yield takeLatest(A.setGib.toString(), S.setGibSaga);
}
export function* deleteGibWatcher() {
  yield takeLatest(A.deleteGib.toString(), S.deleteGibSaga);
}
export function* getGibInvoicesWatcher() {
  yield takeLatest(A.getGibInvoices.toString(), S.getGibInvoicesSaga);
}
export function* getGibInvoiceWatcher() {
  yield takeLatest(A.getGibInvoice.toString(), S.getGibInvoiceSaga);
}
export function* getGibInvoiceForIncomeWatcher() {
  yield takeLatest(
    A.getGibInvoiceForIncome.toString(),
    S.getGibInvoiceForIncomeSaga
  );
}
export function* getGibInvoiceExchangeRateWatcher() {
  yield takeLatest(
    A.getGibInvoiceExchangeRate.toString(),
    S.getGibInvoiceExchangeRateSaga
  );
}
export function* setGibInvoiceWatcher() {
  yield takeLatest(A.setGibInvoice.toString(), S.setGibInvoiceSaga);
}
export function* downloadGibInvoiceWatcher() {
  yield takeLatest(A.downloadGibInvoice.toString(), S.downloadGibInvoiceSaga);
}
export function* deleteGibInvoiceWatcher() {
  yield takeLatest(A.deleteGibInvoice.toString(), S.deleteGibInvoiceSaga);
}
export function* sendGibSmsWatcher() {
  yield takeLatest(A.sendGibSms.toString(), S.sendGibSmsSaga);
}
export function* signGibInvoiceWatcher() {
  yield takeLatest(A.signGibInvoice.toString(), S.signGibInvoiceSaga);
}
export function* getTemplatesWatcher() {
  yield takeLatest(A.getTemplates.toString(), S.getTemplatesSaga);
}
export function* setTemplateWatcher() {
  yield takeLatest(A.setTemplate.toString(), S.setTemplateSaga);
}
export function* createTemplateWatcher() {
  yield takeLatest(A.createTemplate.toString(), S.createTemplateSaga);
}
export function* getTemplateWatcher() {
  yield takeLatest(A.getTemplate.toString(), S.getTemplateSaga);
}
export function* deleteTemplateWatcher() {
  yield takeLatest(A.deleteTemplate.toString(), S.deleteTemplateSaga);
}
export function* logoutWatcher() {
  yield takeLatest(A.logout.toString(), S.logoutSaga);
}
