/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */

import { createAction } from "redux-smart-actions";
import { MenuItemObject, SystemThemeType } from "../../types";

/**
 * STATE
 */
export const setLoading = createAction("SET_LOADING");
export const setLoaded = createAction("SET_LOADED");
export const setScreen = createAction("SET_SCREEN", (payload: string) => ({
  payload,
}));
export const setSystemTheme = createAction(
  "SET_SYSTEM_THEME",
  (payload: SystemThemeType) => ({
    payload,
  })
);
export const setActiveRouteKey = createAction(
  "SET_ACTIVE_ROUTE_KEY",
  (payload: string) => ({
    payload,
  })
);
export const toggleSideNav = createAction("TOGGLE_SIDE_NAV");
export const configure = createAction("CONFIGURE");
export const setConfigured = createAction("SET_CONFIGURED");
export const setMenu = createAction(
  "SET_MENU",
  (payload: MenuItemObject[]) => ({
    payload,
  })
);
export const setYears = createAction("SET_YEARS", (payload: number[]) => ({
  payload,
}));
export const getYears = createAction("GET_YEARS");
export const setYear = createAction("SET_YEAR", (payload: number) => ({
  payload,
}));
