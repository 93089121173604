/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { List as ListComponent, ListProps } from "rsuite";

interface Props extends ListProps {}
const List = ({ ...props }: Props) => {
  //@ts-expect-error
  return <ListComponent {...props} />;
};
List.Item = ListComponent.Item;
export default List;
