/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { createSelector } from "reselect";
import { RootState } from "../../types";
import { getStringSelector } from "../locale";

const getUserTokenState = (state: RootState) => state.user.token;
export const getApiParamsSelector = createSelector(
  [getUserTokenState, getStringSelector],
  (token, strings) => ({ token, lang: strings.getLanguage() })
);

const getUserState = (state: RootState) => state.user.data;
export const getAuthStatusSelector = createSelector(
  [getUserState],
  (user) => user !== undefined
);

export const getUserSelector = createSelector([getUserState], (data) => data);
export const getUserThemeSelector = createSelector(
  [getUserState],
  (data) => data?.theme ?? "default"
);
export const getUsernameSelector = createSelector(
  [getUserState],
  (data) => data?.username ?? ""
);
export const isAdminSelector = createSelector(
  [getUserState],
  (data) => data?.is_admin === true
);

const getSettingsState = (state: RootState) => state.user.settings;
export const getSettingsSelector = createSelector(
  [getSettingsState],
  (settings) => settings
);
export const getSettingsThemeSelector = createSelector(
  [getUserState],
  (user) => user?.theme ?? "default"
);

const getCurrencyState = (state: RootState) => state.user.currencies;
export const getCurrenciesSelector = createSelector(
  [getCurrencyState],
  (currencies) => currencies || []
);
