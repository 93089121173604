import { isMobileOnly, isTablet } from "react-device-detect";
import packageJSON from "../../package.json";

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5500/"
    : packageJSON.api_url;

export const DISPLAY_NAME = packageJSON.displayName;
export const SUB_PATH = "";
export const SIDE_NAV_WIDTH = 56;
export const SIDE_NAV_WIDTH_EXPANDED = 200;
export const EMPTY_STRING = "-";
export const DATE_FORMAT_CHART_LABEL = "DD.M";
export const TIMEZONE_SERVER = "Europe/Istanbul";
export const DATE_FORMAT_SERVER = "YYYY-MM-DD";
export const TABLE_LIMIT_OPTIONS = [6, 10, 12, 18, 20, 24, 48, 92];
export const TABLE_LIMIT_OPTIONS_DEFAULT_INDEX = isMobileOnly
  ? 0
  : isTablet
  ? 1
  : 2;
export const VAT_PERCENTAGES = [10, 20];

export const colors = {
  default: { backgroundColor: "#1a1d24", borderColor: "#3c3f43" },
};

export const GibInvoiceTypes = [
  { key: "SATIS", label: "Satış" },
  { key: "IADE", label: "İade" },
  { key: "TEVKIFAT", label: "Tevkifat" },
  { key: "ISTISNA", label: "İstisna" },
  { key: "OZELMATRAH", label: "Özel Matrah" },
  { key: "IHRACKAYITLI", label: "İhraç Kayıtlı" },
  { key: "KONAKLAMAVERGISI", label: "Konaklama Vergisi" },
];

export const GibCurrencies = [
  { key: "AFN", label: "Afghani" },
  { key: "DZD", label: "Algerian Dinar" },
  { key: "ARS", label: "Argentine Peso" },
  { key: "AWG", label: "Aruban Guilder" },
  { key: "AUD", label: "Australian Dollar" },
  { key: "AZM", label: "Azerbaijanian Manat" },
  { key: "BSD", label: "Bahamian Dollar" },
  { key: "BHD", label: "Bahraini Dinar" },
  { key: "THB", label: "Baht" },
  { key: "PAB", label: "Balboa" },
  { key: "BBD", label: "Barbados Dollar" },
  { key: "BYR", label: "Belarussian Ruble" },
  { key: "BZD", label: "Belize Dollar" },
  { key: "BMD", label: "Bermudian Dollar" },
  { key: "VEB", label: "Bolivar" },
  { key: "BOB", label: "Boliviano" },
  { key: "BGN", label: "Bulgarian Lev" },
  { key: "BRL", label: "Brazilian Real" },
  { key: "BND", label: "Brunei Dollar" },
  { key: "BIF", label: "Burundi Franc" },
  { key: "CAD", label: "Canadian Dollar" },
  { key: "CVE", label: "Cape Verde Escudo" },
  { key: "KYD", label: "Cayman Islands Dollar" },
  { key: "GHC", label: "Cedi" },
  { key: "XAF", label: "CFA Franc" },
  { key: "XPF", label: "CFP Franc" },
  { key: "CLP", label: "Chilean Peso" },
  { key: "COP", label: "Colombian Peso" },
  { key: "KMF", label: "Comoro Franc" },
  { key: "NIO", label: "Cordoba Oro" },
  { key: "CRC", label: "Costa Rican Colon" },
  { key: "CUP", label: "Cuban Peso" },
  { key: "CYP", label: "Cyprus Pound" },
  { key: "CZK", label: "Czech Koruna" },
  { key: "DKK", label: "Danish Krone" },
  { key: "GMD", label: "Dalasi" },
  { key: "MKD", label: "Denar" },
  { key: "AED", label: "Dirham" },
  { key: "DJF", label: "Djibouti Franc" },
  { key: "STD", label: "Dobra" },
  { key: "DOP", label: "Dominican Peso" },
  { key: "VND", label: "Dong" },
  { key: "AMD", label: "Dram" },
  { key: "XCD", label: "East Carribean Dollar" },
  { key: "EGP", label: "Egyptian Pound" },
  { key: "SVC", label: "El Salvador Colon" },
  { key: "ETB", label: "Ethopian Birr" },
  { key: "EUR", label: "Euro" },
  { key: "FKP", label: "Falkland Islands Pound" },
  { key: "FJD", label: "Fiji Dollar" },
  { key: "HUF", label: "Forint" },
  { key: "CDF", label: "Franc Congolais" },
  { key: "GIP", label: "Gibraltar Pound" },
  { key: "XAU", label: "Gold" },
  { key: "HTG", label: "Gourde" },
  { key: "PYG", label: "Guarani" },
  { key: "GNF", label: "Guinea Franc" },
  { key: "GYD", label: "Guyana Dollar" },
  { key: "HKD", label: "HKD" },
  { key: "UAH", label: "Hryvnia" },
  { key: "ISK", label: "Iceland Krona" },
  { key: "INR", label: "Indian Rupee" },
  { key: "IQD", label: "Iraqi Dinar" },
  { key: "IRR", label: "Iranian Rial" },
  { key: "JMD", label: "Jamaican Dollar" },
  { key: "JOD", label: "Jordanian Dinar" },
  { key: "KES", label: "Kenyan Shilling" },
  { key: "PGK", label: "Kina" },
  { key: "LAK", label: "Kip" },
  { key: "EEK", label: "Kroon" },
  { key: "HRK", label: "Kuna" },
  { key: "KWD", label: "Kuwaiti Dinar" },
  { key: "MWK", label: "Kwacha" },
  { key: "AOA", label: "Kwanza" },
  { key: "MMK", label: "Kyat" },
  { key: "GEL", label: "Lari" },
  { key: "LVL", label: "Latvian Lats" },
  { key: "LBP", label: "Lebanese Pound" },
  { key: "ALL", label: "Lek" },
  { key: "HNL", label: "Lempira" },
  { key: "SLL", label: "Leone" },
  { key: "ROL", label: "Leu" },
  { key: "LRD", label: "Liberian Dollar" },
  { key: "LYD", label: "Libyan Dinar" },
  { key: "SZL", label: "Lilangeni" },
  { key: "LTL", label: "Lithuanian Litas" },
  { key: "LSL", label: "Loti" },
  { key: "MGF", label: "Malagasy Franc" },
  { key: "MYR", label: "Malaysian Ringgit" },
  { key: "MTL", label: "Maltese Lira" },
  { key: "TMM", label: "Manat" },
  { key: "MUR", label: "Mauritius Rupee" },
  { key: "MZM", label: "Metical" },
  { key: "MXN", label: "Mexican Peso" },
  { key: "MDL", label: "Moldovan Leu" },
  { key: "MAD", label: "Morrocan Dirham" },
  { key: "NGN", label: "Naira" },
  { key: "ERN", label: "Nakfa" },
  { key: "NAD", label: "Namibia Dollar" },
  { key: "NPR", label: "Nepalese Rupee" },
  { key: "ANG", label: "Netherlands Antillian Guilder" },
  { key: "YUM", label: "New Dinar" },
  { key: "ILS", label: "New Israeli Sheqel" },
  { key: "TWD", label: "New Taiwan Dollar" },
  { key: "NZD", label: "New Zealand Dollar" },
  { key: "KPW", label: "North Korean Won" },
  { key: "NOK", label: "Norwegian Krone" },
  { key: "BTN", label: "Ngultrum" },
  { key: "PEN", label: "Nuevo Sol" },
  { key: "MRO", label: "Ouguiya" },
  { key: "TOP", label: "Pa&apos;anga" },
  { key: "PKR", label: "Pakistan Rupee" },
  { key: "XPD", label: "Palladium" },
  { key: "MOP", label: "Pataca" },
  { key: "UYU", label: "Peso Uruguayo" },
  { key: "PHP", label: "Philippine Peso" },
  { key: "XPT", label: "Platinum" },
  { key: "GBP", label: "Pound Sterling" },
  { key: "BWP", label: "Pula" },
  { key: "QAR", label: "Qatari Rial" },
  { key: "GTQ", label: "Quetzal" },
  { key: "ZAR", label: "Rand" },
  { key: "OMR", label: "Rial Omani" },
  { key: "KHR", label: "Riel" },
  { key: "MVR", label: "Rufiyaa" },
  { key: "RON", label: "Rumen Leyi" },
  { key: "IDR", label: "Rupiah" },
  { key: "RUB", label: "Russian Ruble" },
  { key: "RWF", label: "Rwanda Franc" },
  { key: "SAR", label: "Saudi Riyal" },
  { key: "XDR", label: "SDR" },
  { key: "SCR", label: "Seychelles Rupee" },
  { key: "XAG", label: "Silver" },
  { key: "SGD", label: "Singapore Dollar" },
  { key: "SKK", label: "Slovak Koruna" },
  { key: "SBD", label: "Solomon Islands Dollar" },
  { key: "SOS", label: "Somali Shilling" },
  { key: "LKR", label: "Sri Lanka Rupee" },
  { key: "KGS", label: "Som" },
  { key: "TJS", label: "Somoni" },
  { key: "SHP", label: "St. Helena Pound" },
  { key: "SDD", label: "Sudanese Dinar" },
  { key: "SRG", label: "Suriname Guilder" },
  { key: "SEK", label: "Swedish Krona" },
  { key: "CHF", label: "Swiss Franc" },
  { key: "SYP", label: "Syrian Pound" },
  { key: "BDT", label: "Taka" },
  { key: "WST", label: "Tala" },
  { key: "TZS", label: "Tanzanian Shilling" },
  { key: "KZT", label: "Tenge" },
  { key: "SIT", label: "Tolar" },
  { key: "TTD", label: "Trinidad and Tobago Dollar" },
  { key: "MNT", label: "Tugrik" },
  { key: "TND", label: "Tunisian Dinar" },
  { key: "TRY", label: "Turkish Lira" },
  { key: "UGX", label: "Uganda Shilling" },
  { key: "USD", label: "American Dollar" },
  { key: "UZS", label: "Uzbekistan Sum" },
  { key: "VUV", label: "Vatu" },
  { key: "KRW", label: "Won" },
  { key: "YER", label: "Yemeni Rial" },
  { key: "JPY", label: "Yen" },
  { key: "CNY", label: "Yuan Renminbi" },
  { key: "ZWD", label: "Zimbabwe Dollar" },
  { key: "PLN", label: "Zloty" },
];

export const GibUnitTypes = [
  { key: "DAY", label: "Gün" },
  { key: "MON", label: "Ay" },
  { key: "ANN", label: "Yıl" },
  { key: "HUR", label: "Saat" },
  { key: "D61", label: "Dakika" },
  { key: "D62", label: "Saniye" },
  { key: "C62", label: "Adet" },
  { key: "PA", label: "Paket" },
  { key: "BX", label: "Kutu" },
  { key: "MGM", label: "Mg" },
  { key: "GRM", label: "Gram" },
  { key: "KGM", label: "Kg" },
  { key: "LTR", label: "Lt" },
  { key: "TNE", label: "Ton" },
  { key: "NT", label: "Net Ton" },
  { key: "GT", label: "Gross ton" },
  { key: "MMT", label: "Mm" },
  { key: "CMT", label: "Cm" },
  { key: "MTR", label: "M" },
  { key: "KTM", label: "Km" },
  { key: "MLT", label: "Ml" },
  { key: "MMQ", label: "Mm3" },
  { key: "CMK", label: "Cm2" },
  { key: "CMQ", label: "Cm3" },
  { key: "MTK", label: "M2" },
  { key: "MTQ", label: "M3" },
  { key: "KJO", label: "Kj" },
  { key: "CLT", label: "Cl" },
  { key: "CT", label: "Karat" },
  { key: "KWH", label: "Kwh" },
  { key: "MWH", label: "Mwh" },
  { key: "CCT", label: "Ton Başına Taşıma Kapasitesi" },
  { key: "D30", label: "Brüt Kalori" },
  { key: "D40", label: "1000 Lt" },
  { key: "LPA", label: "Saf Alkol Lt" },
  { key: "B32", label: "Kg M2" },
  { key: "NCL", label: "Hücre Adet" },
  { key: "PR", label: "Çift" },
  { key: "R9", label: "1000 M3" },
  { key: "SET", label: "Set" },
  { key: "T3", label: "1000 Adet" },
  { key: "Q37", label: "Scm" },
  { key: "Q39", label: "Ncm" },
  { key: "J39", label: "Mmbtu" },
  { key: "G52", label: "Cm³" },
  { key: "DZN", label: "Düzine" },
  { key: "DMK", label: "Dm2" },
  { key: "DMT", label: "Dm" },
  { key: "HAR", label: "Ha" },
  { key: "LM", label: "Metretül (LM)" },
];

export const opacityPercentage = {
  p100: "FF",
  p95: "F2",
  p90: "E6",
  p85: "D9",
  p80: "CC",
  p75: "BF",
  p70: "B3",
  p65: "A6",
  p60: "99",
  p55: "8C",
  p50: "80",
  p45: "73",
  p40: "66",
  p35: "59",
  p30: "4D",
  p25: "40",
  p20: "33",
  p15: "26",
  p10: "1A",
  p5: "0D",
  p0: "00",
};
