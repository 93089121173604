/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */

import { produce } from "immer";
import {
  ActionPayload,
  AppReducer,
  MenuItemObject,
  SystemThemeType,
} from "../../types";
import * as A from "../actions";

const initialState: AppReducer = {
  screen: null,
  loading: false,
  system_theme: "dark",
  activeRouteKey: "1",
  side_nav_expanded: true,
  menu: [],
  years: [],
  year: undefined,
};
type Payload = string | number | MenuItemObject[] | number[] | SystemThemeType;
const appReducer = produce(
  (draft: AppReducer, action: ActionPayload<Payload>) => {
    switch (action.type) {
      case A.setScreen.toString():
        draft.screen = action.payload as string;
        break;
      case A.setLoading.toString():
        draft.loading = true;
        break;
      case A.setLoaded.toString():
        draft.loading = false;
        break;
      case A.setSystemTheme.toString():
        draft.system_theme = action.payload as SystemThemeType;
        break;
      case A.setMenu.toString():
        draft.menu = action.payload as MenuItemObject[];
        break;
      case A.setActiveRouteKey.toString():
        draft.activeRouteKey = action.payload as string;
        break;
      case A.toggleSideNav.toString():
        draft.side_nav_expanded = !draft.side_nav_expanded;
        break;
      case A.setYears.toString():
        draft.years = action.payload as number[];
        break;
      case A.setYear.toString():
        draft.year = action.payload as number;
        break;
      case A.setLogout.toString():
        draft.menu = initialState.menu;
        draft.activeRouteKey = initialState.activeRouteKey;
        draft.side_nav_expanded = initialState.side_nav_expanded;
        draft.years = initialState.years;
        draft.year = initialState.year;
        break;
    }
  },
  initialState
);

export default appReducer;
