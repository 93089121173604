/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */

import { createAction } from "redux-smart-actions";
import * as P from "./types/params";
/**
 * STATE
 */

/**
 * SAGA
 */
export const getHome = createAction("GET_HOME", (payload: P.GetHomeA) => ({
  payload,
}));

export const getLedger = createAction(
  "GET_LEDGER",
  (payload: P.GetLedgerA) => ({
    payload,
  })
);
export const getLedgerIncomes = createAction(
  "GET_LEDGER_INCOMES",
  (payload: P.GetLedgerIncomesA) => ({
    payload,
  })
);
export const getLedgerExpenses = createAction(
  "GET_LEDGER_EXPENSES",
  (payload: P.GetLedgerExpensesA) => ({
    payload,
  })
);
export const setLedgerIncomeConfirmation = createAction(
  "SET_LEDGER_INCOME_CONFIRMATION",
  (payload: P.LedgerIncomeConfirmationA) => ({
    payload,
  })
);
export const setLedgerExpenseConfirmation = createAction(
  "SET_LEDGER_EXPENSE_CONFIRMATION",
  (payload: P.LedgerExpenseStatusA) => ({
    payload,
  })
);

export const setTaxBracket = createAction(
  "SET_TAX_BRACKET",
  (payload: P.SetTaxBracketA) => ({ payload })
);
export const deleteTaxBracket = createAction(
  "DELETE_TAX_BRACKET",
  (payload: P.DeleteTaxBracketA) => ({ payload })
);
export const getTaxBracketYears = createAction(
  "GET_TAX_BRACKET_YEARS",
  (payload: P.GetTaxBracketYearsA) => ({ payload })
);
export const getTaxBrackets = createAction(
  "GET_TAX_BRACKETS",
  (payload: P.GetTaxBracketsA) => ({ payload })
);

export const setIncome = createAction(
  "SET_INCOME",
  (payload: P.SetIncomeA) => ({ payload })
);
export const deleteIncome = createAction(
  "DELETE_INCOME",
  (payload: P.DeleteIncomeA) => ({ payload })
);
export const getIncomes = createAction(
  "GET_INCOMES",
  (payload: P.GetIncomesA) => ({ payload })
);

export const setExpense = createAction(
  "SET_EXPENSE",
  (payload: P.SetExpenseA) => ({ payload })
);
export const deleteExpense = createAction(
  "DELETE_EXPENSE",
  (payload: P.DeleteExpenseA) => ({ payload })
);
export const getExpenses = createAction(
  "GET_EXPENSES",
  (payload: P.GetExpensesA) => ({ payload })
);

export const addDocument = createAction(
  "ADD_DOCUMENT",
  (payload: P.AddDocumentA) => ({ payload })
);

export const getDocuments = createAction(
  "GET_DOCUMENTS",
  (payload: P.GetDocumentsA) => ({ payload })
);
export const downloadDocument = createAction(
  "DOWNLOAD_DOCUMENT",
  (payload: P.DownloadDocumentA) => ({ payload })
);
export const deleteDocument = createAction(
  "DELETE_DOCUMENT",
  (payload: P.DeleteDocumentA) => ({ payload })
);
