/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */

import { eventChannel } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as A from "../actions";
import * as S from "./sagas";

export function* configureWatcher() {
  yield takeLatest(A.configure.toString(), S.configureSaga);
}
export function* getYearsWatcher() {
  yield takeLatest(A.getYears.toString(), S.getYearsSaga);
}
export function* systemThemeEventWatcher() {
  const initialDarkModeMediaQuery = window.matchMedia(
    "(prefers-color-scheme: dark)"
  );
  yield put(
    A.setSystemTheme(initialDarkModeMediaQuery.matches ? "dark" : "light")
  );
  const themeChannel = eventChannel((emitter) => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );

    darkModeMediaQuery.addEventListener("change", emitter);
    return () => darkModeMediaQuery.removeEventListener("change", emitter);
  });
  yield takeLatest(themeChannel, S.setSystemThemeSaga);
}
