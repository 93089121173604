import dayjs from "dayjs";
import "dayjs/locale/tr";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CustomProvider } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "./App.css";
import Navigator from "./navigation";
import { getThemeSelector } from "./redux/app";
import { localeTR } from "./utils";

dayjs.locale("tr");

function AppContent() {
  const theme = useSelector(getThemeSelector);
  return (
    <CustomProvider theme={theme} locale={localeTR}>
      <BrowserRouter>
        <Navigator />
      </BrowserRouter>
    </CustomProvider>
  );
}

export default AppContent;
