/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { useSelector } from "react-redux";
import {
  SelectPicker as SelectPickerComponent,
  SelectPickerProps,
} from "rsuite";
import { getLoadingStateSelector } from "../redux/app";
const SelectPicker = ({ disabled, ...props }: SelectPickerProps<any>) => {
  const loading = useSelector(getLoadingStateSelector);
  return (
    <SelectPickerComponent
      appearance="default"
      {...props}
      disabled={loading || disabled}
    />
  );
};

export default SelectPicker;
