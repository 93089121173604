/**
 * ECOMM - Admin Panel
 * https://eminsozuer.com
 *
 * @author Emin SÖZÜER <info@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Uploader as UploaderComponent, UploaderProps } from "rsuite";
import { getLoadingStateSelector } from "../redux/app";
interface Props extends UploaderProps {
  children: ReactNode;
}
const Uploader = ({ disabled, children, ...props }: Props) => {
  const loading = useSelector(getLoadingStateSelector);
  return (
    <UploaderComponent {...props} disabled={loading || disabled}>
      {children}
    </UploaderComponent>
  );
};

export default Uploader;
