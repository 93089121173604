import { Check, Copy, Trash } from "@rsuite/icons";
import dayjs from "dayjs";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { Panel } from "rsuite";
import { Divider, IconButton } from "../../../../components";
import { getThemeSelector } from "../../../../redux/app";
import { getStringSelector } from "../../../../redux/locale";
import { MonthType } from "../../../../types";
import { displayCurrency } from "../../../../utils";
import CardField from "./Field";

interface Props {
  item: MonthType;
  index: number;
  withVat?: boolean;
  onIncomeChange?: (value: string) => void;
  onExpenseChange?: (value: string) => void;
  onCopyPress?: () => void;
  onPastePress?: () => void;
  onClearPress?: () => void;
}
const Card: FC<Props> = ({
  index,
  item,
  withVat,
  onIncomeChange,
  onExpenseChange,
  onCopyPress,
  onPastePress,
  onClearPress,
}) => {
  const strings = useSelector(getStringSelector);
  const theme = useSelector(getThemeSelector);
  const empty = useMemo(() => item.income === 0 && item.expense === 0, [item]);
  const vat = useMemo(
    () =>
      item.expense - item.expense_wo_vat - (item.income - item.income_wo_vat),
    [item]
  );
  const net = useMemo(() => {
    let n = item.income - (item.tax + item.expense_like);
    if (withVat) n += vat;
    return n;
  }, [withVat, vat]);
  return (
    <Panel
      bordered
      collapsible={empty && typeof onIncomeChange !== "function"}
      defaultExpanded={false}
      header={
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span
            className={`panel-header-title ${
              empty ? `card-empty${theme === "dark" ? "-dark" : ""}` : ""
            }`}
          >
            {dayjs().set("month", index).format("MMMM")}
          </span>
          <div>
            {typeof onCopyPress === "function" && (
              <IconButton
                icon={<Copy />}
                style={{ marginRight: 10 }}
                onClick={onCopyPress}
              />
            )}
            {typeof onPastePress === "function" && (
              <IconButton
                icon={<Check />}
                style={{ marginRight: 10 }}
                onClick={onPastePress}
              />
            )}
            {typeof onClearPress === "function" && (
              <IconButton icon={<Trash />} onClick={onClearPress} />
            )}
          </div>
        </div>
      }
      className={`panel-card-container ${
        empty ? `card-empty${theme === "dark" ? "-dark" : ""}` : ""
      }`}
    >
      <Divider />
      <CardField
        title={strings.getString("card_income")}
        value={item.income}
        formatter={displayCurrency}
        onChange={onIncomeChange}
      />
      <CardField
        title={strings.getString("card_expense")}
        value={item.expense_like || item.expense}
        formatter={displayCurrency}
        onChange={onExpenseChange}
      />
      <CardField
        title={strings.getString("card_tax")}
        value={displayCurrency(item.tax)}
      />
      {withVat && (
        <CardField
          title={strings.getString(
            vat > 0 ? "card_vat_receive" : "card_vat_pay"
          )}
          direction={vat === 0 ? undefined : vat > 0 ? "up" : "down"}
          value={displayCurrency(Math.abs(vat))}
        />
      )}
      <CardField
        title={strings.getString("card_tax_brackets")}
        value={item.tax_brackets.join("%, ") + "%"}
      />
      <CardField
        title={strings.getString("card_earning")}
        value={net}
        formatter={displayCurrency}
      />
    </Panel>
  );
};

export default Card;
