/**
 * ECOMM Admin Panel
 *
 * @author Emin SÖZÜER <mail@eminsozuer.com>
 * @format
 * @flow strict-local
 */
import { CANCEL } from "@redux-saga/core";
import axios, { AxiosResponse, Method, ResponseType } from "axios";
import { LocalizedStrings } from "react-localization";
import { call, select } from "redux-saga/effects";
import { BASE_URL } from "../utils";
import { getStringSelector } from "./locale";
import { getApiParamsSelector } from "./user";

const CancelToken = axios.CancelToken;
interface ApiParams {
  url: string;
  method?: Method;
  body?: object | FormData;
  responseType?: ResponseType;
}
interface ApiUnderTheHoodParams {
  token: string;
  lang: string;
}
export function* createRequestSaga({
  method = "GET",
  url,
  body,
  responseType,
}: ApiParams): Generator<any, AxiosResponse<any>, any> {
  const { token, lang } = yield select(getApiParamsSelector);
  return yield call(createInnerRequestSaga, {
    method,
    url,
    token,
    lang,
    body,
    responseType,
  });
}
export function createInnerRequestSaga({
  method,
  url,
  token,
  lang,
  body,
  responseType,
}: ApiParams & ApiUnderTheHoodParams): Promise<AxiosResponse<any>> {
  const source = CancelToken.source();
  const isFormData = body instanceof FormData;
  const request: Promise<AxiosResponse> = axios
    .request({
      // `url` is the server URL that will be used for the request
      url,
      // `method` is the request method to be used when making the request
      method, // get default
      // `baseURL` will be prepended to `url` unless `url` is absolute.
      // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
      // to methods of that instance.
      baseURL: BASE_URL,
      // `transformRequest` allows changes to the request data before it is sent to the server
      // This is only applicable for request methods 'PUT', 'POST', 'PATCH' and 'DELETE'
      // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
      // FormData or Stream
      // You may modify the headers object.
      transformRequest: [
        function (data, headers) {
          // Do whatever you want to transform the data
          //console.log('transformRequest HEADER', headers);
          //console.log('transformRequest DATA', data);
          return data;
        },
      ],
      // `transformResponse` allows changes to the response data to be made before
      // it is passed to then/catch
      transformResponse: [
        function (data) {
          // Do whatever you want to transform the data
          //console.log('transformResponse', data);
          return data;
        },
      ],
      // `headers` are custom headers to be sent
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": `${lang}`,
        Authorization: `Bearer ${token || ""}`,
        ...getRequestHeaders(isFormData),
      },
      // `params` are the URL parameters to be sent with the request
      // Must be a plain object or a URLSearchParams object
      params: method === "GET" && body ? body : undefined,
      // `data` is the data to be sent as the request body
      // Only applicable for request methods 'PUT', 'POST', 'DELETE , and 'PATCH'
      // When no `transformRequest` is set, must be of one of the following types:
      // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
      // - Browser only: FormData, File, Blob
      // - Node only: Stream, Buffer
      data:
        method !== "GET" && body
          ? isFormData
            ? body
            : JSON.stringify(body)
          : undefined,
      // `timeout` specifies the number of milliseconds before the request times out.
      // If the request takes longer than `timeout`, the request will be aborted.
      timeout: 30000, // default is `0` (no timeout)
      // `responseType` indicates the type of data that the server will respond with
      // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
      //   browser only: 'blob'
      responseType: responseType ?? "json", // default
      // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
      xsrfCookieName: "XSRF-TOKEN", // default
      // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
      xsrfHeaderName: "X-XSRF-TOKEN", // default
      // `cancelToken` specifies a cancel token that can be used to cancel the request
      // (see Cancellation section below for details)
      cancelToken: source.token,
    })
    .then((response) => {
      if (responseType === "blob") return response;
      if (typeof response.data === "string" && response.data.startsWith("{"))
        return JSON.parse(response.data);
      return {};
    });
  //@ts-ignore
  request[CANCEL] = () =>
    source.cancel(`API REQUEST CANCELLED BY SAGA: ${url}`);
  return request;
}

const getRequestHeaders = (isFormData?: boolean): object => {
  const headers = (type: string = "application/json") => ({
    "Content-Type": type,
    common: { "Content-Type": type, Accept: "application/json" },
    get: { "Content-Type": type, Accept: "application/json" },
    post: { "Content-Type": type, Accept: "application/json" },
    head: { "Content-Type": type, Accept: "application/json" },
    put: { "Content-Type": type, Accept: "application/json" },
    patch: { "Content-Type": type, Accept: "application/json" },
    delete: { "Content-Type": type, Accept: "application/json" },
  });
  if (isFormData === true) return headers("multipart/form-data");
  return headers();
};

export function* getErrorMessage(e: any) {
  const r = e?.response;
  console.log("ERROR DETAIL", r?.config?.url, r?.status, r?.data);
  if (e?.response?.status == 401) return undefined;
  if (
    e?.response?.data &&
    typeof e.response.data === "string" &&
    e.response.data.startsWith("{")
  ) {
    const err = JSON.parse(e.response.data);
    if ("message" in err) return err.message;
  }
  const strings: LocalizedStrings<string> = yield select(getStringSelector);
  if (e?.response?.status == 503)
    return strings.getString("service_unavailable");
  if (e?.response?.status == 402) return strings.getString("payment_required");
  return strings.getString("error_message");
}

export function objectToFormData(o: Object): FormData {
  let formData = new FormData();
  Object.keys(o).forEach((key) => {
    const val = (o as any)[key];
    if (Array.isArray(val)) {
      val.forEach((v, i) => {
        if (Array.isArray(v)) {
          v.forEach((v, j) => {
            formData.append(`${key}[${i}][${j}]`, v);
          });
        } else if (typeof v === "object") {
          Object.keys(v).forEach((sk) => {
            const sv = (v as any)[sk];
            if (Array.isArray(sv)) {
              sv.forEach((ssv, k) => {
                formData.append(`${key}[${i}][${sk}][${k}]`, ssv);
              });
            } else formData.append(`${key}[${i}][${sk}]`, sv);
          });
        } else formData.append(`${key}[${i}]`, v);
      });
    } else formData.append(key, val);
  });
  return formData;
}
